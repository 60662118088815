import { map, sortBy, fromPairs } from "lodash/fp";

/** @module sortObject */

/**
 * Returns an object of sorted normalized objects.
 *
 * @export
 * @param {object} object
 * @param {function} iteratees
 * @param {boolean} [useLodash=true]
 * @returns
 */
export default function sortObject(object = {}, iteratees, useLodash = true) {
  const keys = Object.keys(object);

  const sortedKeys = useLodash
    ? sortBy(Array.isArray(iteratees) ? iteratees : [iteratees], keys)
    : keys.sort(iteratees);

  return fromPairs(map(key => [key, object[key]], sortedKeys));
}
