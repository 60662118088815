import { connect } from "react-redux";
import { getMedicationCount } from "../../medicationCount/medicationCountSelector";
import {
  createPetMedication,
  createUpdateDeleteMedicationActionTypes,
} from "../../medication/actions/createUpdateDeleteMedicationActions";
import { getMedicationById } from "../../medication/medicationsSelector";
import isRequiredPetMedicationFieldMissing from "../../newMedication/utils/isRequiredPetMedicationFieldMissing";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { formatMedication } from "../../medication/medicationUtils";
import { shouldHideAddMedicationButton } from "../addMedicationUtils";
import { routePaths } from "../../../core/constants/routePaths";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import CommonButton from "../../common/commonButton";
import { setMedSaveButtonTouched } from "@/web/medication/actions/medicationsActions";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const medicationCount = getMedicationCount(state, { petId });
  const medication = getMedicationById(state, { medicationId, petId });
  const medicationList = selectMedicationsList(state);
  const isLoading = createLoadingSelector([
    createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION,
  ])(state);
  return {
    petId,
    medicationId,
    medicationCount,
    medication,
    medicationList,
    isHidden: shouldHideAddMedicationButton(routePaths.PET_PARENT_PROFILE, medicationId),
    componentId: "medicationDetails-addNewMedicationButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    hasErrors: isRequiredPetMedicationFieldMissing({ medication, medicationList }),
    disabled: isLoading,
  };
};

const mapDispatchToProps = (dispatch, { medicationId, petId }) => ({
  createPetMed: medication => dispatch(createPetMedication({ medication, petId })),
  setSaveTouched: () => dispatch(setMedSaveButtonTouched({ medicationId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { medication, hasErrors } = propsFromState;
    const medToSend = formatMedication(medication);
    if (!hasErrors) propsFromDispatch.createPetMed(medToSend);
    propsFromDispatch.setSaveTouched();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
