// BGM: Buy one Get Multiple 
const BGM_STATUS = {
  AVAILABLE: "Available",
  EXPIRED: "Expired",
  // Not sure if "Booked" is a pick list value - we may need to move this value later
  BOOKED: "Booked",
  COMPLETE: "Complete",
  RESERVED: "Reserved",
  REFUNDED: "Refunded",
};

export default BGM_STATUS;
