import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { PetCard, Icon } from "@prism/psm-ui-components";
import { Accordion, Button, Heading, TextPassage } from "@petsmart-ui/sparky";

// Selectors
import {
  selectDdcPackageAssociateById,
  selectDdcPackageExpirationDateById,
  selectDdcPackageNameById,
  selectDdcPackagePurchaseDateById,
  selectInActiveDdcPackageRedeemedUnitsById,
  selectDdcPackageServiceNameById,
  selectDdcPackageStatusById,
} from "../ddcPurchasedPackages/ddcPurchasedPackagesSelectors";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";

// Utils
import { color } from "@/web/common/styles/theme";

// Images
import Img from "@/web/common/Img";
import customerImage from "@/assets/images/customerImage.png";
import { formatPropsForList } from "../_utils/uiComponentUtils";

// HOTEL DDC PACKAGES HISTORY CARD CONTAINER ------------------------------------------------------------------------
export const DdcPackagesHistoryCard = compose(
  withRouteProps,
  connect(
    (state, ownProps) => {
      const { inActiveDdcPurchasedPackageId: packageId } = ownProps;

      const associateName = selectDdcPackageAssociateById(state, { packageId });
      const expirationDate = selectDdcPackageExpirationDateById(state, { packageId });
      const isSRCAgent = getIsSRCAgent(state);
      const packageName = selectDdcPackageNameById(state, { packageId });
      const packagePurchaseDate = selectDdcPackagePurchaseDateById(state, { packageId });
      const packageStatus = selectDdcPackageStatusById(state, { packageId });
      const redeemedUnits = selectInActiveDdcPackageRedeemedUnitsById(state, { packageId });
      const serviceName = selectDdcPackageServiceNameById(state, { packageId });

      return {
        associateName,
        expirationDate,
        isSRCAgent,
        packageName,
        packagePurchaseDate,
        packageStatus,
        redeemedUnits,
        serviceName,
      };
    },
    () => ({}),
    (stateProps, dispatchProps, ownProps) => {
      const {
        associateName,
        expirationDate,
        isSRCAgent,
        packageName,
        packagePurchaseDate,
        packageStatus,
        redeemedUnits,
        serviceName,
      } = stateProps;

      const {
        router: { navigate },
      } = ownProps;

      const packageRedeemedCount = `${redeemedUnits?.length} services redeemed`;
      const spacingStyle = { marginTop: "10px" };

      return {
        componentId: "DdcPackagesHistoryCard",
        petImage: <Img src={customerImage} />,
        topSectionRows: formatPropsForList([
          <>
            <Heading size="body-bold" tagName="h4">
              {packageName}
            </Heading>
            <Heading size="body-bold" tagName="h4">
              {serviceName}
            </Heading>
            <TextPassage size="lg">Purchased: {packagePurchaseDate}</TextPassage>
          </>,
        ]),
        bottomSectionRows: formatPropsForList([
          <TextPassage size="lg" style={spacingStyle}>
            Expiration Date: {expirationDate}
          </TextPassage>,
          <TextPassage size="lg" style={spacingStyle}>
            Sold by: {associateName}
          </TextPassage>,
          <TextPassage size="lg" style={spacingStyle}>
            Status: {packageStatus}
          </TextPassage>,
          <Accordion>
            <Accordion.Panel header={<TextPassage size="lg">{packageRedeemedCount}</TextPassage>}>
              {redeemedUnits?.map(({ id, link, formattedDate }) => (
                <LayoutBox key={id} padding="scale-0">
                  <Button variant="link" text={formattedDate} onClick={() => navigate(link)} />
                </LayoutBox>
              ))}
            </Accordion.Panel>
          </Accordion>,
          <Button
            style={spacingStyle}
            hidden={isSRCAgent}
            onClick={function noRefCheck() {}}
            text={<TextPassage size="lg">Repurchase</TextPassage>}
            variant="link"
          />,
        ]),
        serviceIcon: (
          <Icon
            variant="psm-icon-ph"
            useColorForFill
            color="psm-icon-color-white"
            size="psm-icon-size-48"
            style={{ backgroundColor: color.blue500 }}
          />
        ),
      };
    },
  ),
)(PetCard);
