import { connect } from "react-redux";
import { omit } from "lodash/fp";
import { routePaths } from "core/constants/routePaths";
import { shouldHideAddFoodButton } from "web/addFood/addFoodUtils";
import { getFoodCount } from "../../foodCount/foodCountSelector";
import CommonButton from "../../common/commonButton";
import { foodFields } from "../../newFood/newFoodConstants";
import {
  createPetFood,
  createUpdateDeleteFoodActionTypes,
} from "../../food/actions/createUpdateDeleteFoodActions";
import { getFoodById } from "../../food/foodsSelector";
import isRequiredPetFoodFieldMissing from "../../newFood/utils/isRequiredPetFoodFieldMissing";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { setFoodSaveButtonTouched } from "@/web/food/actions/foodsActions";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const foodCount = getFoodCount(state, { petId });
  const food = getFoodById(state, { foodId, petId });
  const foodList = selectFoodList(state);
  const isLoading = createLoadingSelector([createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD])(
    state,
  );
  return {
    foodId,
    petId,
    foodCount,
    food,
    foodList,
    isHidden: shouldHideAddFoodButton(routePaths.PET_PARENT_PROFILE, foodId),
    componentId: "foodDetails-addNewFoodButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    disabled: isLoading,
    hasError: isRequiredPetFoodFieldMissing({ food, foodList }),
  };
};

const mapDispatchToProps = dispatch => ({
  createPetFood: (food, petId) => dispatch(createPetFood({ food, petId })),
  setSaveTouched: ({ foodId, petId }) => dispatch(setFoodSaveButtonTouched({ foodId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { food, petId, hasError, foodId } = propsFromState;
    const foodToSend = { ...omit(foodFields.IS_FOOD_SHOWING, food) };
    if (!hasError) propsFromDispatch.createPetFood(foodToSend, petId);
    propsFromDispatch.setSaveTouched({ foodId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
