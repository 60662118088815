/** @module filterObjectByProperties */

/**
 * Attempts to filter and object by a list of properties. If the object as a property
 * within the list of properties it will filter that property out and place it
 * into a new object.
 *
 * @export filterObjectByProperties
 * @param {*} obj - Object to filter
 * @param {array} properties - List of properties to filter against
 * @returns {*} A new object of filtered properties.
 */
export default function filterObjectByProperties(obj, properties = []) {
  const newObj = {};

  properties.forEach(property => {
    if (obj.hasOwnProperty(property)) {
      newObj[property] = obj[property];
    }
  });

  return newObj;
}
