import React, { useRef } from "react";
import { LayoutBox } from "@/layout/box/Box";
import { CheckboxField } from "@petsmart-ui/sparky";
import { CheckboxFieldItem } from "@petsmart-ui/sparky/dist/components/CheckboxField/CheckboxFieldItem";
import { connect } from "react-redux";
import {
  getFoodTimeOfDayById,
  getFoodTimeOfDayTouched,
  getIsFoodTimeOfDayEmpty,
} from "@/web/foodTimeOfDay/foodTimeOfDaySelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "@/core/constants/validationErrors";
import { getFoodIsDiscontinuedById, selectIsFoodSaveTouchedById } from "@/web/food/foodsSelector";
import {
  setFoodTimeOfDay,
  setTimeOfDayTouched,
} from "@/web/foodTimeOfDay/actions/foodTimeOfDayActions";
import { convertTimeOfDayObjToArr, convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";
import {
  setMedicationTimeOfDay,
  setTimeOfDayTouched as setMedTimeOfDayTouched,
} from "@/web/medicationTimeOfDay/actions/medicationTimeOfDayActions";
import {
  getIsMedicationTimeOfDayEmpty,
  getMedicationTimeOfDayById,
  getMedicationTimeOfDayTouched,
} from "@/web/medicationTimeOfDay/medicationTimeOfDaySelectors";
import {
  getMedIsDiscontinuedById,
  selectIsMedSaveTouchedById,
} from "@/web/medication/medicationsSelector";
import { ClickOutside } from "@/core/utils/clickOutsideUtils/clickOutside";

export const TimeOfDayCheckboxes = props => {
  const {
    componentId,
    isHidden,
    label,
    error,
    morning,
    afternoon,
    evening,
    disabled,
    onChange,
    onBlur = () => {},
  } = props;

  // Sparky CheckboxField & CheckboxFieldItem ignore the onBlur prop, so handle blur event locally
  const focusRef = useRef();
  ClickOutside(focusRef, onBlur, isHidden);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <div ref={focusRef}>
        <CheckboxField label={label} fieldNote={error} isError={!!error} variant="inline" size="lg">
          <CheckboxFieldItem
            name="morning"
            text="Morning"
            checked={morning}
            onChange={checked => onChange({ morning: checked, afternoon, evening })}
            disabled={disabled}
            error={!!error}
            key={`morning-${morning}`}
          />
          <CheckboxFieldItem
            name="afternoon"
            text="Afternoon"
            checked={afternoon}
            onChange={checked => onChange({ morning, afternoon: checked, evening })}
            disabled={disabled}
            error={!!error}
            key={`afternoon-${afternoon}`}
          />
          <CheckboxFieldItem
            name="evening"
            text="Evening"
            checked={evening}
            onChange={checked => onChange({ morning, afternoon, evening: checked })}
            disabled={disabled}
            error={!!error}
            key={`evening-${evening}`}
          />
        </CheckboxField>
      </div>
    </LayoutBox>
  );
};

export const FoodTimeOfDay = connect(
  (state, { petId, foodId, foodIndex }) => {
    const timeOfDay = getFoodTimeOfDayById(state, { foodId, petId });
    const { morning, afternoon, evening } = convertTimeOfDayToObj(timeOfDay);
    const touched =
      selectIsFoodSaveTouchedById(state, { petId, foodId }) ||
      getFoodTimeOfDayTouched(state, { foodId, petId });

    return {
      componentId: `FoodTimeOfDay-${foodId}`,
      label: `Food ${foodIndex} time of day`,
      morning,
      afternoon,
      evening,
      error:
        touched &&
        getIsFoodTimeOfDayEmpty(state, { foodId, petId }) &&
        REQUIRED_FIELD_ERROR_MESSAGE,
      disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
    };
  },
  (dispatch, { foodId, petId }) => ({
    onChange: ({ morning, afternoon, evening }) => {
      const timeOfDay = convertTimeOfDayObjToArr({ morning, afternoon, evening });
      dispatch(setFoodTimeOfDay({ foodId, timeOfDay, petId }));
    },
    onBlur: () => dispatch(setTimeOfDayTouched({ foodId, petId, timeOfDayTouched: true })),
  }),
)(TimeOfDayCheckboxes);

export const MedTimeOfDay = connect(
  (state, { petId, medicationId, medicationIndex }) => {
    const timeOfDay = getMedicationTimeOfDayById(state, { medicationId, petId });
    const { morning, afternoon, evening } = convertTimeOfDayToObj(timeOfDay);
    const touched =
      selectIsMedSaveTouchedById(state, { petId, medicationId }) ||
      getMedicationTimeOfDayTouched(state, { medicationId, petId });
    return {
      componentId: `MedTimeOfDay-${medicationId}`,
      label: `Medication ${medicationIndex} time of day`,
      morning,
      afternoon,
      evening,
      error:
        touched &&
        getIsMedicationTimeOfDayEmpty(state, { medicationId, petId }) &&
        REQUIRED_FIELD_ERROR_MESSAGE,
      disabled: getMedIsDiscontinuedById(state, { petId, medicationId }),
    };
  },
  (dispatch, { medicationId, petId }) => ({
    onChange: ({ morning, afternoon, evening }) => {
      const timeOfDay = convertTimeOfDayObjToArr({ morning, afternoon, evening });
      dispatch(setMedicationTimeOfDay({ medicationId, timeOfDay, petId }));
    },
    onBlur: () => dispatch(setMedTimeOfDayTouched({ medicationId, timeOfDayTouched: true, petId })),
  }),
)(TimeOfDayCheckboxes);
