import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text, Button } from "@prism/psm-ui-components";
import { HotelBookingCartAddon, HotelCartAddon } from "dux/hotelCartAddons/HotelCartAddon";
import HotelCartAddonArrow from "dux/hotelCartAddons/HotelCartAddonArrow";
import {
  HotelBookingCartAddonAddButton,
  HotelCheckInOutCartAddonAddButton,
} from "dux/hotelCartAddons/HotelCartAddonAddButton";
import { selectPetAddonPricing } from "../hotelPricingSummary/hotelPricingSummarySelectors";
import { selectAppliedAddonsForCartColumn } from "dux/hotelEngagements/hotelEngagementSelectors";
import {
  selectCartProductIsMissingDates,
  selectHotelCartPetAddonPricing,
  selectHotelCartPetAddonsWithoutIncludedProducts,
} from "../servicesCartHotel/servicesCartHotelSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import Section from "@/web/common/Section";

export function HotelCartAddonsList({
  componentId,
  cellData = [],
  totalPrice,
  totalQuantity,
  addButton,
  diComp,
  petId,
  initIsExpanded = false,
}) {
  const isCellContentAvailable = cellData.length;
  const AddonComponent = diComp?.addon;

  // To set if addon list is showing
  const [isExpanded, setIsExpanded] = useState(initIsExpanded);

  useEffect(() => {
    setIsExpanded(initIsExpanded);
  }, [petId]);

  return (
    <Section
      componentId={componentId}
      addButton={addButton}
      title="Add-Ons Details"
      topLine
      paddingTop="1rem"
    >
      {!!isCellContentAvailable && (
        <LayoutStack style={{ justifyContent: "flex-start" }}>
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <LayoutBox padding="scale-0">
                <LayoutCluster>
                  <Text size="text-size-lg" bold>
                    Add-ons
                  </Text>
                  <HotelCartAddonArrow
                    componentID={`${componentId}__expandIcon`}
                    isExpanded={isExpanded}
                    clickHandler={() => setIsExpanded(!isExpanded)}
                  />
                </LayoutCluster>
              </LayoutBox>

              {!isExpanded && (
                <LayoutBox padding="scale-0">
                  <LayoutCluster>
                    <Text>Qty: {totalQuantity}</Text>
                    <Text>${totalPrice?.toFixed(2)}</Text>
                  </LayoutCluster>
                </LayoutBox>
              )}
            </LayoutCluster>
          </LayoutBox>

          <Button
            id={`${componentId}__expandButton`}
            size="xl"
            variant="link"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Expand all/Collapse all
          </Button>

          {isExpanded &&
            cellData?.map(addon => {
              const addonKey = addon?.groupingId ?? addon?.addOnProductNumber ?? addon?.productId;
              return <AddonComponent key={addonKey} addon={addon} />;
            })}
        </LayoutStack>
      )}
    </Section>
  );
}

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutCartAddonsSection = connect(state => {
  const petId = getCurrentPet(state);
  const addOns = selectAppliedAddonsForCartColumn(state, { petId });
  const addOnPricing = selectPetAddonPricing(petId)(state);

  return {
    componentId: "HotelCheckInOutCartAddonsSection",
    cellData: addOns,
    totalPrice: addOnPricing?.finalPrice || 0,
    totalQuantity: addOnPricing?.quantity || 0,
    addButton: <HotelCheckInOutCartAddonAddButton />,
    diComp: {
      addon: HotelCartAddon,
    },
    petId,
  };
})(HotelCartAddonsList);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonsSection = connect((state, { petId }) => {
  const addOns = selectHotelCartPetAddonsWithoutIncludedProducts(petId)(state);
  const addOnPricing = selectHotelCartPetAddonPricing(petId)(state);
  const hasAddonErrors = addOns?.some(({ productId } = {}) =>
    selectCartProductIsMissingDates({ petId, productId })(state),
  );

  return {
    componentId: "HotelBookingCartAddonsSection",
    cellData: addOns,
    totalPrice: addOnPricing?.finalPrice || 0,
    totalQuantity: addOnPricing?.quantity || 0,
    addButton: <HotelBookingCartAddonAddButton petId={petId} />,
    diComp: {
      addon: HotelBookingCartAddon,
    },
    petId,
    initIsExpanded: hasAddonErrors,
  };
})(HotelCartAddonsList);
