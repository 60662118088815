import React from "react";
import { connect } from "react-redux";
import { Heading, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutGrid } from "@/layout/grid/Grid";
import {
  selectDdcPackageAssociateById,
  selectDdcPackageExpirationDateById,
  selectDdcPackageNameById,
  selectDdcPackagePaymentOriginById,
  selectDdcPackagePurchaseDateById,
  selectDdcPackageRemainingUnitsById,
  selectDdcPackageServiceNameById,
} from "./ddcPurchasedPackagesSelectors";
import { DdcPackageReservedUnits } from "./DdcPackageUnits";

// TODO: components & containers for refund button, redeemed units, & reserved units
const DdcPackageRefundButton = () => <></>;
const DdcPackageRedeemedUnits = () => <></>;

export const DdcActivePackageComponent = props => {
  const {
    componentId,
    isHidden,
    packageId,
    name,
    serviceName,
    expirationDate,
    purchaseDate,
    paymentOrigin,
    associate,
    remainingUnits,
  } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        <LayoutGrid>
          <LayoutStack space="scale-0">
            <Heading size="body-bold" tagName="h4">
              {name}
            </Heading>
            <TextPassage>{serviceName}</TextPassage>
          </LayoutStack>
          <LayoutStack space="scale-0">
            <TextPassage>Expiration Date: {expirationDate}</TextPassage>
          </LayoutStack>
        </LayoutGrid>
        <LayoutCluster>
          <TextPassage>Purchased: {purchaseDate}</TextPassage>
          <DdcPackageRefundButton packageId={packageId} />
        </LayoutCluster>
        <LayoutGrid>
          <TextPassage>Paid: {paymentOrigin}</TextPassage>
          <TextPassage>Sold by: {associate}</TextPassage>
        </LayoutGrid>
        <LayoutGrid>
          <LayoutStack>
            <DdcPackageReservedUnits packageId={packageId} />
            <DdcPackageRedeemedUnits packageId={packageId} />
          </LayoutStack>
          <LayoutStack>
            <TextPassage>{remainingUnits?.length} remaining</TextPassage>
          </LayoutStack>
        </LayoutGrid>
      </LayoutStack>
    </LayoutBox>
  );
};

export const DdcActivePackage = connect((state, { packageId }) => {
  return {
    componentId: `DdcActivePackage-${packageId}`,
    name: selectDdcPackageNameById(state, { packageId }),
    serviceName: selectDdcPackageServiceNameById(state, { packageId }),
    expirationDate: selectDdcPackageExpirationDateById(state, { packageId }),
    purchaseDate: selectDdcPackagePurchaseDateById(state, { packageId }),
    paymentOrigin: selectDdcPackagePaymentOriginById(state, { packageId }),
    associate: selectDdcPackageAssociateById(state, { packageId }),
    remainingUnits: selectDdcPackageRemainingUnitsById(state, { packageId }),
  };
})(DdcActivePackageComponent);
