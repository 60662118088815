import { combineReducers } from "redux";

import pendingFrequencyReducer from "@/dux/frequency/pendingFrequencyReducer";
import eligibilityReducer from "@/dux/eligibility/reducers/eligibilityReducer";
import storeCapabilitiesReducer from "@/dux/enhancedServicesList/EnhancedServicesListReducer";
import eligiblePromotionsReducer from "@/dux/eligiblePromotions/eligiblePromotionsReducer";
import selectedDateReducer from "@/dux/selectedDate/selectedDateReducer";
import quickViewReducer from "@/dux/quickViews/_quickViewReducer";
import pendingAppointmentReducer from "@/web/pendingAppointment/reducers/pendingAppointmentReducer";
import accordionReducer from "@/dux/toggleAccordion/toggleAccordionReducer";
import foodReducer from "@/web/food/reducers/foodReducer";
import frequencyOptionsReducer from "@/dux/frequencyOptions/frequencyOptionsReducer";
import medicationReducer from "@/web/medication/reducers/medicationReducer";
import hotelHoursReducer from "@/web/hotelHours/hotelHoursReducer";
import generalCartReducer from "@/dux/generalCart/generalCartReducer";
import hotelArrivalsReducer from "@/dux/hotelArrivals/columnList/hotelArivalsReducer";
import hotelOverNightReducer from "@/dux/hotelOvernight/columnList/hotelOvernightReducer";
import hotelDeparturesReducer from "@/dux/hotelDepartures/columnList/hotelDeparturesReducer";
import hotelCheckedOutReducer from "@/dux/hotelCheckedOut/columnList/hotelCheckedOutReducer";
import hotelRoomCapacityReducer from "@/dux/hotelRoomCapacity/hotelRoomCapacityReducer";
import ddcRoomCapacityReducer from "@/dux/ddcRoomCapacity/ddcRoomCapacityReducer";
import ftcoReducer from "@/dux/bgm/ftco/ftcoReducer";
import storeEventNotificationsReducer from "dux/notificationCenter/storeEventNotificationsReducer";
import {
  trainingClassCachedMonthsReducer,
  trainingSessionAppointmentDatesReducer,
  trainingClassSessionsReducer,
  trainingAttendeesReducer,
  trainingViewsReducer,
} from "@/dux/trainingClassSessions/reducers";
import trainingPetServicesReducer from "@/dux/trainingPetServices/trainingPetServicesReducer";
import trainingAssociatesReducer from "@/dux/trainingAssociates/trainingAssociatesReducer";
import scheduleTrainingPageReducer from "@/dux/scheduleTrainingPage/scheduleTrainingPageReducer";
import storeHoursErrorReducer from "@/dux/storeHoursError/storeHoursErrorReducer";
import trainingClassAvailabilityReducer from "dux/trainingClassAvailability/trainingClassAvailabilityReducer";
import {
  filterAppointmentsByEngagementType,
  TrainingClassFilterButtonData,
} from "@/dux/filteringButtons/dux";
import customerProfileIncompleteModalReducer from "@/dux/customerProfileIncompleteModal/customerProfileIncompleteModalReducer";
import hotelItineraryReducer from "dux/hotelItinerary/hotelItineraryReducer";
import hotelRoomTypesReducer from "dux/hotelRoomTypes/hotelRoomTypesReducer";
import hotelEngagementsReducer from "dux/hotelEngagements/hotelEngagementsReducer";
import manageTrainingClassPageReducer from "@/dux/manageTrainingClassPage/manageTrainingClassPageReducer";
import reservationCartDetailsConfirmationReducer from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationReducer";
import hotelBeddingTypesReducer from "dux/hotelBeddingTypes/hotelBeddingTypesReducer";
import scheduleTrainingAppointmentReducer from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentReducer";
import hotelRoomAvailabilityReducer from "@/dux/hotelRoomAvailability/hotelRoomAvailabilityReducer";
import associateProfilePageReducer from "@/dux/associateProfile/associateProfilePageReducer";
import hotelPriceAdjustmentReducer from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentReducer";
import applyManualSpecialsHotelReducer from "dux/applySpecial/applyManualSpecialsHotelReducer";
import hotelEligibilityReducer from "dux/hotelEligibility/hotelEligibilityReducer";
import { serviceManagementToggleReducer } from "dux/servicesManagementToggle/serviceManagementToggleReducer";
import hotelScheduleSuspensionReducer from "dux/scheduleSuspensionPage/scheduleSuspensionReducer";
import hotelManageRoomDetailsDetailsReducer from "@/dux/hotelManageRoomDetailsDetails/hotelManageRoomDetailsDetailsReducer";
import hotelManageRoomDetailsTableReducer from "@/dux/hotelManageRoomDetailsTable/hotelManageRoomDetailsTableReducer";
import { hotelRoomManagementFilterPillsReducer } from "@/dux/hotelRoomManagementFilterPills/hotelRoomManagementFilterPillsReducer";
import hotelRoomManagementSearchReducer from "@/dux/hotelRoomManagementSearch/hotelRoomManagementSearchReducer";
import { hotelRoomSelectionReducer } from "@/dux/hotelRoomSelection/hotelRoomSelectionReducer";
import hotelManageRoomDetailsReducer from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsReducer";
import hotelCheckInOutPetNeedsReviewReducer from "@/dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewReducer";
import hotelManageRoomDetailsDatePickersReducer from "@/dux/hotelManageRoomDetailsDatePickers/hotelManageRoomDetailsDatePickersReducer";
import hotelManageRoomDetailsReasonsReducer from "@/dux/hotelManageRoomDetailsReasons/hotelManageRoomDetailsReasonsReducer";
import scheduleSuspensionFilterButtonsReducer from "dux/scheduleSuspensionFilterButtons/scheduleSuspensionFilterButtonsReducer";
import scheduleSuspensionPaginationReducer from "dux/scheduleSuspensionPagination/scheduleSuspensionPaginationReducer";
import cancellationReasonsReducer from "dux/cancellationreasons/cancellationReasonsReducer";
import voidTransactionsReasonsReducer from "core/reducers/voidTransactionsReasonsReducer";
import itineraryHistoryListReducer from "@/dux/itineraryHistoryList/itineraryHistoryListReducer";
import hotelAddonsReducer from "dux/hotelAddons/hotelAddonsLIstReducer";
import serviceProductFamiliesReducer from "@/dux/serviceProductFamilies/serviceProductFamiliesReducer";
import hotelBookingFlowReducer from "web/features/hotelBookingFlow/hotelBookingFlowReducer";
import storeWideNotesReducer from "dux/storeWideNotes/storeWideNotesReducer";
import { overBookingFilterPillsReducer } from "@/dux/overBookingFilterPills/overBookingFilterPillsReducer";
import overBookingNewExceptionDateReducer from "@/dux/overBookingNewExceptionDate/overBookingNewExceptionDateReducer";
import overBookingSetupTableReducer from "@/dux/overBookingSetupTable/overBookingSetupTableReducer";
import listOfNearByStoresReducer from "dux/listOfNearByStores/listOfNearByStoresReducer";
import overBookingExceptionSuspensionDetailsReducer from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsReducer";
import { currentAddonGroupingIdReducer } from "@/dux/hotelPriceOverride/hotelPriceOverrideReducer";
import servicesCartReducer from "@/dux/servicesCart/servicesCartReducer";
import { userStatusReducer } from "@/dux/userStatus/userStatusReducer";
import servicesCartHotelReducer from "@/dux/servicesCartHotel/servicesCartHotelReducer";
import featureFlaggingReducer from "./featureFlagging/featureFlaggingReducers";
import modalsReducer from "./modals/_modalsReducer";
import ruleViolationsReducer from "./ruleViolationsReducer";
import navigationReducer from "./navigationReducer";
import schedulesReducer from "./schedulesReducer";
import authReducer from "./authReducer";
import dynamicPricesReducer from "./dynamicPricesReducer";
import salonHoursReducer from "./salonHoursReducer";
import searchReducer from "./searchReducer";
import persistentReducer from "./persistentReducer";
import enumsReducer from "./enumsReducer";
import uiReducer from "./ui/uiReducer";
import entitiesReducer from "./entitiesReducer";
import errorReducer from "./errorReducer";
import isLoadingReducer from "./isLoadingReducer";
import medicationsListReducer from "@/dux/medications/medicationsReducer";
import hotelPricingSummaryReducer from "@/dux/hotelPricingSummary/hotelPricingSummaryReducer";
import { selectedSuspensionTypeReducer } from "dux/hotelSelectedSuspensionType/selectedSuspensionTypeReducer";
import hotelCheckInOutPetNotesReducer from "@/dux/hotelCheckInOutPetNotes/hotelCheckInOutPetNotesReducer";
import overbookingExceptionsPaginationReducer from "@/dux/overbookingExceptionsPagination/overbookingExceptionsPaginationReducer";
import { overBookingExceptionReducer } from "@/dux/overBookingException/overBookingExceptionReducer";
import reportSelectionReducer from "@/dux/reportSelection/reportSelectionReducer";
import reportDateSelectionReducer from "@/dux/reportDateSelection/reportDateSelectionReducer";
import reportsReducer from "@/dux/reports/reportsReducer";
import searchAddressByZipReducer from "@/dux/searchAddressByZip/searchAddressByZipReducer";
import foodOptionsListReducer from "@/dux/foodOptionsList/foodOptionsListReducer";
import ddcPackagesReducer from "@/dux/ddcPackages/ddcPackagesReducer";
import storeSettingsReducer from "@/dux/storeSettings/storeSettingsReducer";
import ddcPurchasedPackagesReducer from "@/dux/ddcPurchasedPackages/ddcPurchasedPackagesReducer";
import { servicesCartV2Reducer } from "@/dux/servicesCartV2/servicesCartV2Reducer";

/** ----------------------------------------------------------------------- **\
  The Reducer serves to derive the next state. The state and action is passed
  automatically via the Store Redux Object. Here, We use the redux library's
  combineReducers to combine sub-reducers for a full state.
  more info here: https://redux.js.org/api-reference/combinereducers
\** ----------------------------------------------------------------------- * */
const rootReducer = combineReducers({
  accordionViews: accordionReducer,
  associateProfilePage: associateProfilePageReducer,
  auth: authReducer,
  cancellationReasons: cancellationReasonsReducer,
  cart: generalCartReducer,
  customerProfileIncompleteModal: customerProfileIncompleteModalReducer,
  ddcPackages: ddcPackagesReducer,
  ddcPurchasedPackages: ddcPurchasedPackagesReducer,
  ddcRoomCapacity: ddcRoomCapacityReducer,
  dynamicPrices: dynamicPricesReducer,
  eligiblePromotions: eligiblePromotionsReducer,
  eligibility: eligibilityReducer,
  entities: entitiesReducer,
  enums: enumsReducer,
  error: errorReducer,
  featureFlagging: featureFlaggingReducer,
  food: foodReducer,
  foodOptions: foodOptionsListReducer,
  frequencyOptions: frequencyOptionsReducer,
  ftco: ftcoReducer,
  filterAppointmentsByEngagementType,
  hotelAddonsList: hotelAddonsReducer,
  hotelArrivals: hotelArrivalsReducer,
  hotelBeddingTypes: hotelBeddingTypesReducer,
  hotelBookingFlowReducer,
  hotelCheckedOut: hotelCheckedOutReducer,
  hotelEligibility: hotelEligibilityReducer,
  hotelDepartures: hotelDeparturesReducer,
  hotelEngagements: hotelEngagementsReducer,
  hotelHours: hotelHoursReducer,
  hotelItinerary: hotelItineraryReducer,
  hotelManualSpecials: applyManualSpecialsHotelReducer,
  hotelOvernight: hotelOverNightReducer,
  hotelPriceAdjustmentReasons: hotelPriceAdjustmentReducer,
  hotelPriceOverrideCurrentAddonGroupingId: currentAddonGroupingIdReducer,
  hotelPricingSummary: hotelPricingSummaryReducer,
  hotelReservationConfirmation: reservationCartDetailsConfirmationReducer,
  hotelRoomAvailability: hotelRoomAvailabilityReducer,
  hotelRoomCapacity: hotelRoomCapacityReducer,
  hotelRoomManagementDetails: hotelManageRoomDetailsReducer,
  hotelPetNeedsReview: hotelCheckInOutPetNeedsReviewReducer,
  hotelPetReservationNotes: hotelCheckInOutPetNotesReducer,
  hotelRoomMangementTable: hotelManageRoomDetailsTableReducer,
  hotelRoomManagementDetailsDatePickers: hotelManageRoomDetailsDatePickersReducer,
  hotelRoomManagementSuspensions: hotelManageRoomDetailsReasonsReducer,
  hotelRoomManagementDetailsDetailsInput: hotelManageRoomDetailsDetailsReducer,
  hotelRoomManagementSearch: hotelRoomManagementSearchReducer,
  hotelRoomTypes: hotelRoomTypesReducer,
  hotelSelectedSuspensionType: selectedSuspensionTypeReducer,
  hotelScheduleSuspensions: hotelScheduleSuspensionReducer,
  hotelScheduleSuspensionsPagination: scheduleSuspensionPaginationReducer,
  hotelScheduleSuspensionFilterButtons: scheduleSuspensionFilterButtonsReducer,
  hotelRoomFilters: hotelRoomManagementFilterPillsReducer,
  hotelRoomSelection: hotelRoomSelectionReducer,
  overBookingExceptionSuspension: overBookingExceptionSuspensionDetailsReducer,
  hotelSuspensionReasons: serviceManagementToggleReducer,
  isLoading: isLoadingReducer,
  itineraryHistory: itineraryHistoryListReducer,
  listOfNearByStores: listOfNearByStoresReducer,
  manageTrainingClassPage: manageTrainingClassPageReducer,
  medication: medicationReducer,
  medicationList: medicationsListReducer,
  modals: modalsReducer,
  navigation: navigationReducer,
  overBookingFilterPills: overBookingFilterPillsReducer,
  overBookingSetupTable: overBookingSetupTableReducer,
  overBookingNewExceptionDate: overBookingNewExceptionDateReducer,
  overbookingExceptionsPagination: overbookingExceptionsPaginationReducer,
  overBookingException: overBookingExceptionReducer,
  pendingAppointment: pendingAppointmentReducer,
  pendingFrequency: pendingFrequencyReducer,
  persistent: persistentReducer,
  quickViews: quickViewReducer,
  ruleViolations: ruleViolationsReducer,
  reportSelection: reportSelectionReducer,
  reportDateSelection: reportDateSelectionReducer,
  reports: reportsReducer,
  salonHours: salonHoursReducer,
  schedules: schedulesReducer,
  scheduleTrainingAppointment: scheduleTrainingAppointmentReducer,
  scheduleTrainingPage: scheduleTrainingPageReducer,
  search: searchReducer,
  searchAddressByZip: searchAddressByZipReducer,
  selectedDate: selectedDateReducer,
  servicesCart: servicesCartReducer,
  servicesCartHotel: servicesCartHotelReducer,
  servicesCartV2: servicesCartV2Reducer,
  storeCapabilities: storeCapabilitiesReducer,
  storeHoursError: storeHoursErrorReducer,
  storeWideNotes: storeWideNotesReducer,
  storeEventNotifications: storeEventNotificationsReducer,
  storeSettings: storeSettingsReducer,
  trainingAssociates: trainingAssociatesReducer,
  trainingAttendees: trainingAttendeesReducer,
  trainingClassAvailability: trainingClassAvailabilityReducer,
  trainingClassCachedMonths: trainingClassCachedMonthsReducer,
  trainingClassFilterButtonData: TrainingClassFilterButtonData,
  trainingClassSessions: trainingClassSessionsReducer,
  trainingPetServices: trainingPetServicesReducer,
  trainingSessionAppointmentDates: trainingSessionAppointmentDatesReducer,
  trainingViews: trainingViewsReducer,
  ui: uiReducer,
  userStatus: userStatusReducer,
  voidTransactionsReasons: voidTransactionsReasonsReducer,
  serviceProductFamilies: serviceProductFamiliesReducer,
});

export default rootReducer;
