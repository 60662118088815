import { endpointVersionString } from "../../utils/envUtils";
import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";

/**
 * GET customer purchased packages
 * @param {*} { customerKey }
 * @returns
 */
export function getPurchasedPackages({ customerKey }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/purchasedPackages`,
  );
}
