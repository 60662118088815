import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { getHotelPetServiceName } from "dux/ServiceNameAndSelection/serviceNameAndSelectionSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "@/core/constants/checkInOutConstants";
import { HotelCheckInOutEditIconContainer } from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutEditIcon";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";

/**
 * A component to provide a service name and a CTA to open a flyout.
 * Pass in a component for any flyout content and a component for desired CTA
 * @param props
 * @returns {JSX.Element|null}
 */
export const ServiceNameAndSelection = props => {
  const { componentId, isHidden, serviceName, showChangePrimaryServiceModal } = props;

  if (isHidden) return null;

  return (
    <Layout.Box id={componentId}>
      <Layout.Cluster space="cluster-space-4">
        <Text size="text-size-md" bold id={`${componentId}__serviceName`}>
          {serviceName}:
        </Text>
        <HotelCheckInOutEditIconContainer
          componentId={`${componentId}__editButton`}
          onClick={showChangePrimaryServiceModal}
        />
      </Layout.Cluster>
    </Layout.Box>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInServiceNameAndSelection = connect(
  state => {
    const petId = getCurrentPet(state);
    const selectedDate = getSelectedDate(state);
    const serviceName = getHotelPetServiceName(state, { petId, date: selectedDate });
    const isLoading = createLoadingSelector([GET_HOTEL_ITINERARY])(state);

    return {
      componentId: "HotelCheckInServiceNameAndSelection",
      isHidden: false,
      serviceName: isLoading ? "Loading Service name" : serviceName,
    };
  },
  dispatch => {
    return {
      showChangePrimaryServiceModal: () =>
        dispatch(showCheckInOutModal({ modalType: modalTypes.HOTEL_PRIMARY_SERVICE_CHANGE })),
    };
  },
)(ServiceNameAndSelection);
