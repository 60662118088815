import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";

import { getSelectedUser, getInvalidPinError } from "core/selectors/authSelectors";
import { getLoggedInUser } from "core/selectors/persistentSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import {
  authorizeUserByPin,
  clearInvalidPinError,
  clearSelectedUser,
} from "core/actionCreators/authActionCreators";
import authActionTypes from "core/actionTypes/authActionTypes";

import { EnterPinComponent } from "./EnterPinComponent";

const mapStateToProps = state => {
  const selectedUser = getSelectedUser(state);
  const loggedInUser = getLoggedInUser(state);

  return {
    componentId: "EnterPin",
    invalidPinError: getInvalidPinError(state),
    isLoading: createLoadingSelector([authActionTypes.AUTHORIZE_USER_BY_PIN])(state),
    nameToDisplay: !isEmpty(selectedUser)
      ? selectedUser?.name
      : `${loggedInUser?.name} ${loggedInUser?.familyName}`,
    isMissingSelectedUser: isEmpty(selectedUser) && isEmpty(loggedInUser),
  };
};

const mapDispatchToProps = dispatch => ({
  authorizeUserByPin: ({ pin }) => dispatch(authorizeUserByPin({ pin })),
  clearInvalidPinError: () => dispatch(clearInvalidPinError()),
  clearSelectedUser: () => dispatch(clearSelectedUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterPinComponent);
