import { connect } from "react-redux";
import {
  getFrequencyIsTouchedById,
  getIsFrequencyMissingField,
  getMedicationFrequencyById,
} from "dux/bookingPage/setMedFrequency/medicationFrequencySelectors";
import CommonErrorMessage from "../../web/common/errorAndMessages/CommonErrorMessage";
import { frequencyConstants, frequencyErrors } from "./FrequencyConstants";
import { selectIsMedSaveTouchedById } from "@/web/medication/medicationsSelector";

const { MANUAL_MISSING_DATES, REQUIRED } = frequencyErrors;

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const isManual =
    getMedicationFrequencyById(state, { medicationId, petId }) === frequencyConstants.MANUAL;
  const touched =
    selectIsMedSaveTouchedById(state, { medicationId, petId }) ||
    getFrequencyIsTouchedById(state, { medicationId, petId });
  return {
    message: isManual ? MANUAL_MISSING_DATES : REQUIRED,
    isError: true,
    isHidden: !touched || !getIsFrequencyMissingField(state, { medicationId, petId }),
    componentID: "frequencyErrorMessage-GenericContentWrapper",
  };
};

export default connect(mapStateToProps)(CommonErrorMessage);
