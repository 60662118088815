import React, { useEffect, useMemo, useRef } from "react";
import { Navigate } from "react-router-dom";
import { SET_PIN_INITIAL_FORM_VALUES } from "core/constants/quickPin";
import { routePaths } from "@/core/constants/routePaths";
import EnvironmentNotificationPinContainer from "web/environmentNotificaion/EnvironmentNotificationPinContainer";
import { useForm } from "@/dux/utils/formUtils/useForm";
import { validatePin } from "@/core/utils/validationUtils/pinValidation";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import {
  QuickPinForm,
  QuickPinInput,
  QuickPinPage,
  QuickPinProfileImg,
  QuickPinSubHeading,
  QuickPinSubmitBtn,
} from "./quickPinComponents";
import { ForgotPinButton, SwitchUserButton } from "./QuickPinIconButton";

export const EnterPinComponent = props => {
  const {
    componentId,
    nameToDisplay,
    authorizeUserByPin,
    invalidPinError,
    isLoading,
    clearInvalidPinError,
    clearSelectedUser,
    isMissingSelectedUser,
  } = props;

  const inputRef = useRef();

  const {
    values: { pin },
    touched,
    setFormInput,
  } = useForm(SET_PIN_INITIAL_FORM_VALUES);
  const error = useMemo(() => validatePin(pin), [pin]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      clearInvalidPinError();
      clearSelectedUser();
    };
  }, []);

  if (isMissingSelectedUser) return <Navigate to={routePaths.SELECT_USER} />;

  const handleSubmit = e => {
    e.preventDefault();

    if (error) return;
    authorizeUserByPin({ pin });
  };

  return (
    <QuickPinPage componentId={componentId}>
      <EnvironmentNotificationPinContainer />

      <QuickPinProfileImg />
      <QuickPinSubHeading>{`Hey ${nameToDisplay}, please enter your PIN code`}</QuickPinSubHeading>

      <QuickPinForm onSubmit={handleSubmit} error={(touched?.pin && error) || invalidPinError}>
        <QuickPinInput
          id={`${componentId}__pinInput`}
          placeholder="Personal PIN Code"
          name="pin"
          ref={inputRef}
          onChange={e => {
            if (invalidPinError) {
              clearInvalidPinError();
            }
            setFormInput(e);
          }}
          value={pin}
        />
        <QuickPinSubmitBtn id={`${componentId}__submit`} isLoading={isLoading} />
      </QuickPinForm>

      <LayoutBox>
        <LayoutCluster space="scale-0">
          <ForgotPinButton />
          <SwitchUserButton />
        </LayoutCluster>
      </LayoutBox>
    </QuickPinPage>
  );
};
