import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  EditableSection,
  Button,
  ButtonGroup,
  Datepicker,
  SparkyTextWrapper,
} from "@petsmart-ui/sparky";
import { Layout, Text } from "@prism/psm-ui-components";
import moment from "moment";
import TimePicker from "web/common/TimeRange/TimePicker";
import {
  setHotelBookingFlowDateTime,
  setHotelBookingPendingChanges,
} from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import { loadHotelHours } from "web/hotelHours/hotelHoursActions";
import {
  selectBookingDateTimeError,
  selectHotelHoursByDate,
} from "dux/hotelBookingDateTimeSelection/hotelBookingDateTimeSelectors";
import { getIsSRCAgent, selectCurrentStoreTimeZone } from "core/selectors/persistentSelectors";
import { isEmpty } from "lodash/fp";
import { dateTimeErrors } from "dux/hotelBookingDateTimeSelection/hotelBookingDateTimeConstants";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingEndDate,
  selectHotelBookingStartDate,
  selectShouldRestartToEdit,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import getMomentByDateAndTime from "@/core/utils/dateUtils/byDateAndTime";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { putCart } from "@/dux/servicesCart/servicesCartActions";
import { selectServicesCart } from "dux/servicesCart/servicesCartSelectors";
import { setHotelCartTimes } from "dux/servicesCartHotel/servicesCartHotelActions";
import {
  selectHotelCartEndTime,
  selectHotelCartStartTime,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import {
  formatCalendarDateMoment,
  formatDayHourMinutes,
  formatHourMinuteTime,
  formatMonthDateYear,
} from "@/core/utils/dateUtils/formatDateTime";
import {
  checkInDateId,
  checkInTimeId,
  checkOutDateId,
  checkOutTimeId,
} from "../_constants/checkInOutDateTimeConstants";

const HotelBookingDateTimeSelectionComponent = ({
  onContinue,
  setHotelBookingDateTime,
  componentID,
  canNavigate,
  onClick,
  isActive,
  callHotelHours,
  isSRC,
  error,
  currentStart,
  currentEnd,
  shouldRestartToEdit,
  storeTimeZone,
  savePendingAndPutCart = () => {},
  savePendingAndShowModal = () => {},
}) => {
  const times = useMemo(() => {
    // Only format with timezone if dateTimes are undefined
    const startTz = currentStart ? undefined : storeTimeZone;
    const endTz = currentEnd ? undefined : storeTimeZone;
    return {
      startDate: formatCalendarDateMoment(currentStart, startTz),
      endDate: formatCalendarDateMoment(currentEnd, endTz),
      startTime: formatHourMinuteTime(currentStart, startTz),
      endTime: formatHourMinuteTime(currentEnd, endTz),
    };
  }, [currentStart, currentEnd, storeTimeZone]);

  // Get initial hotel hours.
  useEffect(() => {
    callHotelHours({ startDate: times?.startDate, endDate: times?.endDate });
  }, []);

  // Call put cart when navigating off step.
  useEffect(() => {
    const { startDate, endDate, startTime, endTime } = times;
    const start = getMomentByDateAndTime(startDate, startTime);
    const end = getMomentByDateAndTime(endDate, endTime);

    if (!isActive) {
      savePendingAndPutCart({ start, end });
    }
  }, [isActive]);

  const setTimeWrapper = ({ startDate, endDate, startTime, endTime }) => {
    const start = getMomentByDateAndTime(startDate, startTime);
    const end = getMomentByDateAndTime(endDate, endTime);

    // If we already have passed service step, and are changing date -- show modal
    if (
      shouldRestartToEdit &&
      (!moment(startDate).isSame(times?.startDate, "day") ||
        !moment(endDate).isSame(times?.endDate, "day"))
    ) {
      savePendingAndShowModal({ startDate: start, endDate: end });
      return;
    }

    setHotelBookingDateTime({ startDate, endDate, startTime, endTime });
  };

  const description = () => {
    const { startDate, endDate, startTime, endTime } = times;
    const isSameDay = moment(times?.startDate).isSame(moment(endDate), "day");

    // If we can't navigate (meaning, we have not been to this step)
    // Or if it's active (meaning if we're currently editing the step)
    // We shouldn't show the description.
    const shouldHideDescription = !canNavigate || isActive;

    if (shouldHideDescription) return null;

    const start = `${formatMonthDateYear(startDate)} ${formatDayHourMinutes(startTime)}`;
    const end = isSameDay
      ? formatDayHourMinutes(endTime)
      : `${formatMonthDateYear(endDate)} ${formatDayHourMinutes(endTime)}`;

    return (
      <SparkyTextWrapper truncateOn={1}>
        {start} - {end}
      </SparkyTextWrapper>
    );
  };

  // Disable continue if there is an error and user is a SRC agent
  // OR if the error is the end time is before start time
  // OR if the error is the end time is the same as start time for single day
  // OR is start/end date is not a valid date.
  const shouldDisableContinue =
    (error(times) && isSRC) ||
    error(times) === dateTimeErrors.BEFORE_START_TIME ||
    error(times) === dateTimeErrors.START_END_MATCH ||
    !moment(times.startDate).isValid() ||
    !moment(times.endDate).isValid();

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Date Selection"
      description={description()}
    >
      <Layout.Box id={componentID} style={{ paddingBottom: "1em" }}>
        <Layout.Stack space="stack-space-4">
          <Text size="text-size-lg" bold>
            Check In
          </Text>
          <Layout.Cluster justify="left" style={{ overflow: "visible", zIndex: "99" }}>
            <Datepicker
              id={checkInDateId}
              label="Date"
              placeholder=""
              isOutsideRange={() => false}
              onDateChange={date => {
                const newDate = moment(date)?.format("YYYY-MM-DD");
                callHotelHours({ startDate: newDate });
                // If start date is after end date, set end date to start date.
                if (moment(date).isAfter(moment(times?.endDate), "day")) {
                  setTimeWrapper({
                    ...times,
                    startDate: newDate,
                    endDate: newDate,
                  });
                } else {
                  setTimeWrapper({ ...times, startDate: newDate });
                }
              }}
              date={moment(times.startDate).isValid() ? moment(times.startDate) : null}
            />
            <TimePicker
              componentId={checkInTimeId}
              error={null}
              time={times.startTime}
              onChange={newTime => {
                setTimeWrapper({
                  ...times,
                  startTime: newTime,
                });
              }}
            />
          </Layout.Cluster>
          <Text size="text-size-lg" bold>
            Check Out
          </Text>
          <Layout.Cluster justify="left" style={{ overflow: "visible" }}>
            <Datepicker
              id={checkOutDateId}
              label="Date"
              placeholder=""
              isOutsideRange={() => false}
              onDateChange={date => {
                callHotelHours({ endDate: moment(date)?.format("YYYY-MM-DD") });
                setTimeWrapper({ ...times, endDate: moment(date)?.format("YYYY-MM-DD") });
              }}
              date={moment(times.endDate).isValid() ? moment(times.endDate) : null}
            />
            <TimePicker
              componentId={checkOutTimeId}
              error={null}
              time={times.endTime}
              onChange={newTime => {
                setTimeWrapper({
                  ...times,
                  endTime: newTime,
                });
              }}
            />
          </Layout.Cluster>
        </Layout.Stack>
        <Text color="text-color-red" bold style={{ paddingTop: "1em" }}>
          {error(times)}
        </Text>
        <ButtonGroup align="right">
          <Button
            disabled={shouldDisableContinue}
            variant="primary"
            onClick={() => {
              onContinue();
            }}
            text="Next"
          />
        </ButtonGroup>
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingDateTimeSelection = connect(
  (state, { stepIndex }) => {
    const error = times => {
      const isSameDay = moment(times?.startDate).isSame(moment(times?.endDate), "day");
      return selectBookingDateTimeError(times, isSameDay)(state);
    };
    const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
      stepIndex,
    )(state);

    return {
      componentID: "hotelBookingFlow-dateSelection",
      canNavigate,
      isActive,
      openStep,
      furthestStep,
      error,
      isSRC: getIsSRCAgent(state),
      hotelHoursForDate: date => selectHotelHoursByDate(date)(state),
      shouldRestartToEdit: selectShouldRestartToEdit(state),
      currentStart: selectHotelBookingStartDate(state),
      currentEnd: selectHotelBookingEndDate(state),
      cartStart: selectHotelCartStartTime(state),
      cartEnd: selectHotelCartEndTime(state),
      cartId: selectServicesCart(state)?.servicesCartId,
      storeTimeZone: selectCurrentStoreTimeZone(state),
    };
  },
  (dispatch, { stepIndex }) => {
    const { setStep, onContinue } = commonHotelBookingStepActions(dispatch);
    return {
      dispatchSavePendingAndPutCart: ({ start, end }) => {
        // Set cart time for upcoming cart PUT
        dispatch(
          setHotelCartTimes({
            dropOffTime: moment(start)?.format("HH:mm:ss.sss"),
            pickUpTime: moment(end)?.format("HH:mm:ss.sss"),
          }),
        );

        // Dispatch a PUT to the Cart API
        dispatch(putCart());
      },
      dispatchHotelBookingDateTime: ({ startDate, endDate }) => {
        dispatch(setHotelBookingFlowDateTime({ startDate, endDate }));
      },
      loadHours: date => dispatch(loadHotelHours({ date })),
      savePendingAndShowModal: ({ startDate, endDate }) => {
        dispatch(
          setHotelBookingPendingChanges({
            startDate,
            endDate,
            openStep: stepIndex,
            furthestStep: stepIndex,
          }),
        );
        dispatch(showBookingModal(hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL));
      },
      setStep,
      onContinue,
    };
  },
  (mapProps, dispatchProps, { stepIndex }) => {
    const {
      savedStartDate,
      savedEndDate,
      componentID,
      canNavigate,
      isActive,
      openStep,
      furthestStep,
      error,
      isSRC,
      hotelHoursForDate,
      shouldRestartToEdit,
      currentStart,
      currentEnd,
      cartId,
      cartStart,
      cartEnd,
      storeTimeZone,
    } = mapProps;
    const {
      dispatchHotelBookingDateTime,
      dispatchSavePendingAndPutCart,
      loadHours,
      savePendingAndShowModal,
      setStep,
      onContinue,
    } = dispatchProps;

    return {
      // control what props get passed to the view
      savedStartDate,
      savedEndDate,
      onContinue: () => {
        onContinue({ openStep, furthestStep });
      },
      isSRC,
      cartId,
      setHotelBookingDateTime: ({ startDate, endDate, startTime, endTime }) => {
        const start = moment(startDate).set({
          hour: moment(startTime, "HH:mm").hour(),
          minutes: moment(startTime, "HH:mm").minute(),
        });
        const end = moment(endDate).set({
          hour: moment(endTime, "HH:mm").hour(),
          minutes: moment(endTime, "HH:mm").minute(),
        });
        dispatchHotelBookingDateTime({ startDate: start, endDate: end });
      },
      componentID,
      canNavigate,
      isActive,
      onClick: () => setStep(stepIndex),
      currentStart,
      currentEnd,
      shouldRestartToEdit,
      savePendingAndShowModal,
      error,
      storeTimeZone,
      savePendingAndPutCart: ({ start, end }) => {
        // If cartId is missing or both start and end times are the same, return and don't update.
        const isStartTimeSame = start.isSame(cartStart);
        const isEndTimeSame = end.isSame(cartEnd);

        const noTimeChange = isStartTimeSame && isEndTimeSame;

        if (!cartId || noTimeChange) return;

        dispatchSavePendingAndPutCart({ start, end });
      },
      callHotelHours: ({ startDate, endDate }) => {
        // If we have a start/end date and don't already have hours for those dates then retrieve them.
        if (startDate && isEmpty(hotelHoursForDate(startDate))) loadHours(startDate);
        if (endDate && isEmpty(hotelHoursForDate(endDate))) loadHours(endDate);
      },
    };
  },
)(HotelBookingDateTimeSelectionComponent);
