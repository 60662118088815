import React, { useState } from "react";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { Badge, Heading, RadioField, TextPassage, Price } from "@petsmart-ui/sparky";

// Utils
import { formatMoney } from "@/dux/_utils/moneyUtil";
import { formatUTCDate } from "@/core/utils/dateUtils/formatDateTime";

// Styles
import "./DdcPackagesRadioList.css";
import { color } from "@/web/common/styles/theme";

/**
 * React view component for Ddc packages radio list
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DdcPackagesRadioList = props => {
  const {
    componentId,
    isHidden,
    packages,
    selectedPackageId = null,
    onChange = () => {},
    disabled,
  } = props;

  if (isHidden || packages.length === 0) return null;

  return (
    <LayoutStack className="ddc-packages-radio-list" id={componentId}>
      {packages.map(packageItem => {
        const { packageType, packages } = packageItem;

        return (
          <LayoutBox key={packageType} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <RadioField
              key={packageType}
              label={
                <Heading tagName="h4" size="body-lg-bold">
                  {packageType}
                </Heading>
              }
              onChange={value => {
                onChange(value);
              }}
              selectedValue={selectedPackageId}
              fieldNote={!packages.length && "No packages found"}
            >
              {packages.map(item => {
                const {
                  packageId,
                  displayName,
                  pricing,
                  freePassExpirationDate,
                  paidPassExpirationDate,
                  canPurchase,
                  packageLimit,
                } = item;

                const isExpirationHidden = selectedPackageId === packageId;

                const text = (
                  <LayoutCluster
                    key={packageId}
                    flexWrap="nowrap"
                    style={{ justifyContent: "space-between", alignItems: "start" }}
                  >
                    <LayoutStack style={{ flex: "0.8" }}>
                      <TextPassage>
                        <span>{displayName}</span>
                      </TextPassage>
                      {isExpirationHidden && (
                        <>
                          <TextPassage size="sm">
                            <span>
                              <b>Free pass expires: </b>
                              {formatUTCDate(freePassExpirationDate)}
                            </span>
                          </TextPassage>
                          <TextPassage size="sm">
                            <span>
                              <b>Paid pass expires: </b>
                              {formatUTCDate(paidPassExpirationDate)}
                            </span>
                          </TextPassage>
                        </>
                      )}
                      {!canPurchase && (
                        <TextPassage style={{ color: color.red700 }}>
                          {packageLimit} package limit has been reached
                        </TextPassage>
                      )}
                    </LayoutStack>

                    {canPurchase && (
                      <Badge text={`Save ${formatMoney(pricing?.discount)}`} variant="sale" />
                    )}
                    <Price
                      values={[pricing?.finalPrice, pricing?.originalPrice]}
                      variant="disabled"
                    />
                  </LayoutCluster>
                );

                return (
                  <RadioField.Item
                    text={text}
                    value={packageId}
                    key={packageId}
                    disabled={disabled || !canPurchase}
                  />
                );
              })}
            </RadioField>
          </LayoutBox>
        );
      })}
    </LayoutStack>
  );
};
