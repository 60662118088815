import React, { useEffect } from "react";
import MedicationNameContainer from "../medicationName/MedicationNameContainer";
import AddNewBookingMedicationButtonContainer from "../../dux/bookingMedication/addMedBooking/AddNewBookingMedicationButtonContainer";
import CancelNewMedicationButtonContainer from "../addMedication/buttons/CancelNewMedicationButtonContainer";
import OtherMedicationNameContainer from "../otherMedicationName/OtherMedicationNameContainer";
import MedicationTypeAmountContainer from "../medicationTypeAmount/MedicationTypeAmountContainer";
import MedicationInstructionsContainer from "../medicationInstructions/MedicationInstructionsContainer";
import { MedTimeOfDay } from "@/dux/timeOfDayCheckboxes/TimeOfDayCheckboxes";
import MedicationSaveToProfileCheckboxContainer from "../../dux/bookingMedication/bookingSaveMedicationToProfile/MedicationSaveToProfileCheckboxContainer";
import { isNewMedication } from "../medication/medicationUtils";
import MedicationFrequencyErrorMessageContainer from "dux/frequency/MedicationFrequencyErrorMessageContainer";
import MedicationIsFromProfileCheckboxContainer from "../../dux/bookingMedication/bookingIsFromProfile/MedicationIsFromProfileCheckboxContainer";
import SetFrequencyMedicationButtonContainer from "dux/bookingPage/setMedFrequency/SetFrequencyMedicationButtonContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { UpdateBookingMedButtonContainer } from "@/dux/bookingFeeding/UpdateBookingFoodButtonContainer";
import { MedFormLayout } from "@/dux/_components/medication/MedFormLayout";

const BookingMedicationFormComponent = ({
  componentId,
  isHidden,
  medicationId,
  medicationIndex,
  medicationIds,
  petId,
  locationType,
  isEditingMed = false,
  preserveOrApplyMedData = () => {},
}) => {
  useEffect(() => {
    if (!isNewMedication(medicationId)) preserveOrApplyMedData();
  }, [isEditingMed]);

  const newMedicationIndex = isNewMedication(medicationId)
    ? medicationIds.length + 1
    : medicationIndex;

  return !isHidden ? (
    <MedFormLayout componentId={componentId}>
      <MedicationNameContainer
        medicationIndex={newMedicationIndex}
        medicationId={medicationId}
        petId={petId}
      />
      <OtherMedicationNameContainer medicationId={medicationId} petId={petId} />
      <MedicationTypeAmountContainer
        medicationId={medicationId}
        medicationIndex={newMedicationIndex}
        petId={petId}
      />
      <MedTimeOfDay
        medicationId={medicationId}
        medicationIndex={newMedicationIndex}
        petId={petId}
      />

      <LayoutBox padding="scale-0">
        <MedicationInstructionsContainer
          medicationId={medicationId}
          medicationIndex={newMedicationIndex}
          petId={petId}
        />
      </LayoutBox>

      <LayoutBox padding="scale-0">
        <MedicationSaveToProfileCheckboxContainer medicationId={medicationId} petId={petId} />
        <MedicationIsFromProfileCheckboxContainer medicationId={medicationId} petId={petId} />
      </LayoutBox>

      <LayoutCluster justify="space-between" style={{ padding: "15px 0px" }}>
        <SetFrequencyMedicationButtonContainer medicationId={medicationId} petId={petId} />
        <LayoutBox style={{ marginLeft: "auto" }}>
          <LayoutCluster>
            <UpdateBookingMedButtonContainer
              medicationId={medicationId}
              petId={petId}
              locationType={locationType}
            />
            <AddNewBookingMedicationButtonContainer
              medicationId={medicationId}
              petId={petId}
              locationType={locationType}
            />
            <CancelNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
          </LayoutCluster>
        </LayoutBox>
      </LayoutCluster>
      <MedicationFrequencyErrorMessageContainer medicationId={medicationId} petId={petId} />
    </MedFormLayout>
  ) : null;
};

export default BookingMedicationFormComponent;
