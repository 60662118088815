/** @module pinValidation */

import { pinValidationErrors } from "@/core/constants/authConstants";
import hasNonNumericCharacters from "./hasNonNumericCharacters";

/**
 * Verifies the set of numbers being entered are, or are not sequential
 *
 * @export isSequentialPin
 * @param {string} str
 * @returns{boolean}
 */
export function isSequentialPin(str) {
  return (
    str &&
    str.split("").every((curr, i, arr) => {
      const prev = arr[i - 1];

      if (i === 0) {
        return true;
      }

      if (curr - prev === 1) {
        return true;
      }
      return false;
    })
  );
}

/**
 *
 *
 * @export allCharactersSame
 * @param {*} str
 * @returns
 */
export function allCharactersSame(str) {
  return (
    str &&
    str.split("").every((current, index, arr) => {
      const start = arr[0];
      return current === start;
    })
  );
}

/**
 * Function to validate a user's quick pin
 * @export validatePin
 * @param {string} pin
 * @returns {string|null}
 */
export const validatePin = pin => {
  if (!pin) return pinValidationErrors.REQUIRED;
  if (pin?.length < 6) return pinValidationErrors.LENGTH;
  if (hasNonNumericCharacters(pin)) return pinValidationErrors.NON_NUMERIC;
  if (allCharactersSame(pin)) return pinValidationErrors.ALL_SAME_NUMBERS;
  if (isSequentialPin(pin)) return pinValidationErrors.SEQUENTIAL_NUMBERS;
  return null;
};

/**
 * Function to validate a user's quick pin confirmation
 * @export validatePin
 * @param {{ confirmPin: string, pin: string}}
 * @returns {string|null}
 */
export const validateConfirmPin = ({ confirmPin, pin } = {}) => {
  if (!confirmPin) return pinValidationErrors.CONFIRM_PIN;
  if (confirmPin !== pin) return pinValidationErrors.PINS_DONT_MATCH;
  return validatePin(confirmPin);
};
