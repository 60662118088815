/** @module transformObjectByMap */

/**
 * Attempts to look at all key value pairs in the 1st object and see if the 2nd
 * object (map) has that same key, if so a new object is created and returned, if not
 * the map object is returned as a new object.
 *
 * @export transformObjectByMap
 * @param {*} obj - object to be mapped through
 * @param {*} map - object to use as the map key
 * @returns {*} new object
 * @example
 * - transformObjectByMap( { foo: 'FOO', bar: 'BAR' }, { bar, 'BAZ' } )
 */
export default function transformObjectByMap(obj = {}, map) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (map.hasOwnProperty(key)) {
      newObj[key] = map[key](obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}
