import { endpointVersionString } from "core/utils/envUtils";
import { cartAPI } from "core/services/cart/_axiosConfigCart";

/**
 * Update status via "Cart" for pre-booking
 * @returns
 */
export function patchPreBookingStatus({ servicesCartId, customerKey }) {
  return cartAPI.patch(
    `${endpointVersionString()}/carts/${servicesCartId}/book?customerKey=${customerKey}`,
    {},
    {
      headers: { "Content-Length": 0 },
    },
  );
}
