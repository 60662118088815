import { createSelector } from "reselect";
import {
  getFirstHotelEngagementByPet,
  selectItineraryPetFoodsByPetId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import normalizeFormInput from "core/utils/normalizeUtils/normalizeFormInput";
import { selectFoodList, selectFoodOptions } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { convertTimeOfDayObjToArr, convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getReservationConfirmationPendingFoods } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSelectors";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { isValidFrequency } from "@/core/utils/validationUtils/validateFrequency";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";

export const getCheckInOutPetFoods = createSelector([selectItineraryPetFoodsByPetId], foods =>
  foods?.filter(food => !food.isDeleted),
);

export const getCheckInOutNewFoodNumber = createSelector([getCheckInOutPetFoods], foods => {
  return foods?.length + 1;
});

export const selectCheckInOutFoodIds = createSelector([getCheckInOutPetFoods], foods => {
  return foods?.map(food => food?.recordId) ?? [];
});

export const getCheckInFoodById = createSelector(
  [getCheckInOutPetFoods, (state, props) => props],
  (foods, { foodId }) => {
    return foods?.find(food => food?.recordId === foodId);
  },
);

export const getCheckInFoodFrequencyById = createSelector([getCheckInFoodById], food => {
  return food?.frequency || "";
});

export const getFoodFormData = createSelector(
  [selectFoodList, getCheckInOutPetFoods, (state, props) => props],
  (foodList, foods, { foodId }) => {
    const defaultFrequency = frequencyConstants.DAILY;
    if (!foods.length) {
      return { frequency: defaultFrequency };
    }
    // Get specific food from engagement's foods
    const food = foods?.find(listFood => listFood.recordId === foodId);

    // Get needed food details from the general food list
    const foodDetails = foodList.find(listFood => listFood.FoodId === food?.foodId);

    // If feeding name doesn't match the food list's name, it's an other name.
    const otherFoodName = food?.feedingName === foodDetails?.FoodName ? "" : food?.feedingName;

    // Set the different times of day to a boolean
    const { morning, afternoon, evening } = convertTimeOfDayToObj(food?.timeOfDay);

    return {
      foodType: normalizeFormInput(foodDetails, "FoodType"),
      foodName: foodDetails?.FoodName || "",
      otherFoodName,
      foodAmount: normalizeFormInput(food, "amount"),
      morning,
      afternoon,
      evening,
      foodInstructions: normalizeFormInput(food, "splInstruction"),
      foodId: normalizeFormInput(food, "recordId"),
      groupingId: food?.groupingId, // group id will be undefinded for new food
      frequency: food?.frequency ?? defaultFrequency,
      customFrequencyDates: food?.customFrequencyDates ?? [],
      externalId: food?.foodId,
    };
  },
);

export const getCheckInOutPetFoodOptions = ({ foods, petSpecies, foodType }) => {
  const sortedFoods =
    foods &&
    foods
      .filter(food => food.IsActive && food.SpeciesId === petSpecies && food.FoodType === foodType)
      .sort((a, b) => (a.FoodName > b.FoodName) - (a.FoodName < b.FoodName));
  const foodOptions =
    sortedFoods && sortedFoods.map(food => ({ label: food.FoodName, value: food.FoodId }));

  return foodOptions;
};

export const getIsCheckInOutPetFoodFormValid = ({ values, dates }) => {
  const {
    foodType,
    foodName,
    otherFoodName,
    foodAmount,
    morning,
    afternoon,
    evening,
    frequency,
  } = values;

  if (
    !isValidString(foodType) ||
    !isValidString(foodName) ||
    (!isValidString(otherFoodName) && foodName === "Other") ||
    !isValidString(foodAmount) ||
    (!morning && !afternoon && !evening) ||
    !isValidFrequency(frequency, dates)
  ) {
    return false;
  }

  return true;
};

/**
 * Helper function to update a food object with pending changes
 * @param {Object} obj
 * @param {Object} obj.foodToUpdate - the pending changes
 * @param {Object?} obj.food - the existing food or undefined if adding new food
 * @param {Object} obj.foodOptions - the food list options obj used to get food name by externalId
 * @returns {Object} updated food with properties needed for itinerary PUT call
 */
export const formatUpdatedFoodForItinerary = ({ foodToUpdate, food = {}, foodOptions = {} }) => {
  const defaultFrequency = frequencyConstants.DAILY;
  const timeOfDay = convertTimeOfDayObjToArr(foodToUpdate);
  const foodId = foodToUpdate?.externalId ?? food?.foodId;
  const foodName = foodOptions?.[foodId]?.FoodName;

  return {
    ...food,
    foodType: foodToUpdate?.foodType,
    feedingName: foodToUpdate?.otherFoodName || foodName,
    amount: foodToUpdate?.foodAmount,
    timeOfDay,
    splInstruction: foodToUpdate?.foodInstructions,
    foodId,
    engagementId: foodToUpdate?.engagementId,
    frequency: foodToUpdate?.frequency ?? food?.frequency ?? defaultFrequency,
    customFrequencyDates: foodToUpdate?.customFrequencyDates ?? food?.customFrequencyDates ?? [],
    groupingId: foodToUpdate?.groupingId ?? food?.groupingId,
    isDeleted: food?.isDeleted ?? false,
  };
};

export const getFoodUpdatesForItinerary = createSelector(
  [getReservationConfirmationPendingFoods, getCheckInOutPetFoods, selectFoodOptions],
  (pendingFoods, petFoods, foodOptions) => {
    if (!pendingFoods) {
      return petFoods;
    }

    const foods = petFoods?.map(food => {
      const foodToUpdate = pendingFoods[food?.recordId];

      // Don't update existing records when adding new record
      if (!foodToUpdate) return food;

      return formatUpdatedFoodForItinerary({ foodToUpdate, food, foodOptions });
    });

    if (pendingFoods?.NEW_FOOD) {
      const foodToUpdate = pendingFoods?.NEW_FOOD;

      const newFood = formatUpdatedFoodForItinerary({ foodToUpdate, foodOptions });
      foods.push(newFood);
    }

    return foods;
  },
);

/**
 * Selector return if the food remove buttons should be enabled
 * will only be false if there is only one food on an overnight reservation
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIfFoodCanBeRemoved
 * @param {object} state
 * @param {object} props
 * @param {string} props.petId
 * @returns {boolean} true if food can be removed false if button should be disabled
 * @example selectIfFoodCanBeRemoved(state, { petId })
 */
export const selectIfFoodCanBeRemoved = createSelector(
  [getCheckInOutPetFoods, getFirstHotelEngagementByPet],
  (food, { engagementType } = {}) => {
    return engagementType === engagementTypes.OVERNIGHT_BOARDING ? food?.length > 1 : true;
  },
);

/**
 * Selector to get all foods for a pet and then filter out food to be removed
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetFoodsWithoutFoodToBeRemoved
 * @param {string} foodToBeRemoved - recordId of food to be removed
 * @returns {Function} Selector
 * @example selectPetFoodsWithoutFoodToBeRemoved(foodToBeRemoved)(state, { petId })
 */
export const selectPetFoodsWithoutFoodToBeRemoved = foodToBeRemoved =>
  createSelector([getFoodUpdatesForItinerary], foods =>
    foods?.filter(({ recordId }) => recordId?.toString() !== foodToBeRemoved?.toString()),
  );
