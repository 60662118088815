import React, { useEffect } from "react";
import { connect } from "react-redux";
import { unstable_HistoryRouter as Router, Navigate, Routes, Route } from "react-router-dom";
import { get } from "lodash/fp";
import { useIdleTimer } from "react-idle-timer";
import styled from "styled-components";
import "@petsmart-ui/sparky/dist/head.css";

import SentryErrorBoundary from "dux/sentry/SentryErrorBoundary";
import { QuickQuoteContainer } from "dux/quickQuote/QuickQuoteContainer";
import { history } from "dux/utils/browser/browserHistory";
import setPersistFeatureFlagCookie from "core/utils/featureFlagging/PersistFeatureFlagCookie";
import { isAuthRoute } from "core/utils/authHelper";
import {
  getAssociateNumber,
  getJobRole,
  getSessionToken,
  getStoreNumber,
} from "core/selectors/persistentSelectors";
import AssociateProfilePage from "@/dux/associateProfile/AssociateProfilePage";
import { loadPriceAdjustmentReasons } from "core/actionCreators/priceAdjustmentActionCreator";
import { loadMaxPerBlockOrCheckReductionReasons } from "core/actionCreators/maxPerBlockOrCheckReductionReasonsActionCreator";
import { jobRoleConstants } from "core/constants/jobRoleConstants";
import { storeNumberWhiteList } from "core/whiteBlackLists/storeNumberLists";
import { createLoadingSelector, getServerError, getServerErrorMessage } from "core/selectors/utils";
import { clearServerErrors } from "core/actionCreators/errorActionCreators";

import { loadLunchBreakTimes } from "core/actionCreators/schedulesActionCreators";
import { loadSalonInfoWithHours } from "core/actionCreators/salonActionCreator";
import { loadStatuses } from "core/actionCreators/serviceStatusesActionCreator";
import { getStoreCapabilities } from "@/dux/enhancedServicesList/EnhancedServicesListActions";
import { getServiceProductFamilies } from "@/dux/serviceProductFamilies/serviceProductFamiliesActions";
import { pageNames } from "core/constants/checkInOutConstants";
import { getAuthModalType } from "core/selectors/modals/authModalSelectors";
import getIsEnhancedServicesWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/enhancedServices/getIsEnhancedServicesWorkflowFeatureFlagHidden";
import {
  getSystemBookingFlow,
  getSystemType,
} from "web/setSystemType/selectors/setSystemTypeSelectors";
import CustomerProfileIncompleteModal from "@/dux/customerProfileIncompleteModal/CustomerProfileIncompleteModal";
import ManageTrainingClassContainer from "@/dux/manageTrainingClassPage/ManageTrainingClassContainer";
import { CheckInOut } from "dux/checkInOut/CheckInOutPageShell";
import { setUserStatus } from "dux/userStatus/userStatusActions";
import { routePaths } from "@/core/constants/routePaths";
import { HotelServiceManagementPage } from "dux/serviceManagementPage/ServiceManagementPage";
import { RestrictedRouteByRole } from "core/utils/jobRolsUtils/jobRoleRouting";
import { DdcScheduleSuspensionPage } from "dux/scheduleSuspensionPage/scheduleSuspensionPage";
import { HotelRoomSelectionPage } from "@/dux/hotelRoomSelection/hotelRoomSelectionPage";
import { HotelRoomManagementPage } from "@/dux/hotelRoomManagement/hotelRoomManagementPage";
import { HotelManageRoomDetailsPage } from "@/dux/hotelManageRoomDetails/hotelManageRoomDetails";
import { PetsHotelAdminLayout } from "@/dux/_components/petsHotelAdminLayout/PetsHotelAdminLayout";
import getIsHotelWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { PurchaseDDCPackagesPage } from "@/dux/ddcPackages/DdcPackagesPage";
import getWorkflowsFeatureFlagState from "./enableDisableWorkflowFeatureFlag/selectors/_workflowsFeatureFlagState";
import { BookingPage } from "./common/contentWrappers/BookingPageWrapper";
import { displayFeatureFlagIcon } from "./displayFeatureFlagIcon/displayFeatureFlagIconActions";
import dispalyFeatureFlagIconConstants from "./displayFeatureFlagIcon/displayFeatureFlagIconConstants";
import FeatureFlagContainer from "./featureFlag/FeatureFlagContainer";
import shouldDisplayServerErrorHeader from "../core/utils/displayUtils/shouldDisplayServerErrorHeader";
import ConfirmationModalContainer from "./common/modals/shared/confirmationModal/ConfirmationModalContainer";
import PetSmartAuth from "./common/hoc/auth/PetSmartAuth";
import CheckSession from "./common/hoc/auth/CheckSession";
import QuickPinModals from "./quickPin/modals/QuickPinModalsContainer";
import ForgotPinContainer from "./quickPin/ForgotPinContainer";
import EnterPinContainer from "./quickPin/EnterPinContainer";
import SetPinContainer from "./quickPin/SetPinContainer";
import SelectUserContainer from "./quickPin/SelectUserContainer";
import Schedules from "./schedules/schedulesContainer";
import ServerErrorModal from "./serverErrorModal/ServerErrorModal";
import MyScheduleContainer from "./associateScheduling/MyScheduleComponent";
import { TemplateSchedulingContainer } from "./associateScheduling/TemplateSchedulingContainer";
import AssociateSchedulingContainer from "./associateScheduling/AssociateSchedulingComponent";
import SharedCheckInOutWrapper from "./petCheckInOut/sharedCheckInOutWrapperContainer";
import AssociatesSearchContainer from "./associates/search/AssociatesSearchComponent";
import EnvironmentComponent from "./login/environmentDetailsComponent";
import { SalonBooking } from "@/dux/booking/Booking";
import LoginContainer from "./login/loginContainer";
import PetParentProfile from "./petParentProfile/petParentProfile/PetParentProfileContainer";
import SearchSaloncontainer from "./searchSalonSRC/searchSalonContainer";
import authActionTypes from "../core/actionTypes/authActionTypes";
import usePrevious from "dux/utils/refUtils/usePrevious";
import { ScheduleTrainingPage } from "dux/_components/scheduleTraining/ScheduleTrainingPageComponent";
import { DashBoardPage } from "dux/_components/dashboard/DashboardComponent";
import { SrcDashBoard } from "web/searchSalonSRC/searchSRCComponent";
import { ReportsPage } from "@/dux/reports/Reports";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  height: 100%;
`;

const App = props => {
  const {
    storeNumber,
    isAuthorizing,
    jobRole,
    serverErrorMessage,
    closeServerErrorModal,
    modalType,
    confirmationModal,
    dispatchSetUserStatus,
  } = props;

  const onPresenceChange = ({ type }) => {
    const currentTime = Date.now();
    dispatchSetUserStatus({ status: type, time: currentTime });
  };

  useIdleTimer({
    onPresenceChange,
    throttle: 500,
    eventsThrottle: 500,
    timeout: window.env.USER_STATUS_TIMEOUT,
    events: ["mousemove", "keydown", "wheel", "mousedown"],
  });

  const prevStoreNumber = usePrevious(storeNumber);
  const prevIsAuthorizing = usePrevious(isAuthorizing);

  useEffect(() => {
    const { showFeatureFlagIcon } = props;
    const urlParams = get("location.search", history);

    /* * ----------------------------------------------------------------------- * *\
      BootStrapping if the feature flag Icon should display on screen and
      if Feature flagging cookie should be set or not.
    \* * ----------------------------------------------------------------------- * */
    if (urlParams.includes(dispalyFeatureFlagIconConstants.FEATURE_FLAG_PARAM)) {
      showFeatureFlagIcon({ isHidden: false });
    }

    setPersistFeatureFlagCookie(urlParams);
  }, []);

  useEffect(() => {
    const { sessionToken, loadStartupAPICalls } = props;
    const isNotAuthRoute = isAuthRoute(history?.location?.pathname) === false;
    const propsHaveUpdated = prevIsAuthorizing !== isAuthorizing || prevStoreNumber !== storeNumber;
    const shouldLoadStartupAPICalls =
      storeNumber && sessionToken && propsHaveUpdated && isAuthorizing === false && isNotAuthRoute;

    if (shouldLoadStartupAPICalls) {
      loadStartupAPICalls();
    }
  }, [prevStoreNumber, prevIsAuthorizing, storeNumber, isAuthorizing]);

  return (
    <>
      <SentryErrorBoundary>
        <Router history={history}>
          <Container className="app">
            <Routes>
              <Route path="/implicit/callback" element={<PetSmartAuth />} />
              <Route exact path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginContainer />} />
              <Route path="/env_details" element={<EnvironmentComponent />} />
              <Route
                path="/pet-parent-profile/:customerKey"
                element={
                  <CheckSession>
                    <PetParentProfile />
                  </CheckSession>
                }
              />
              <Route
                path="/pet-parent-profile/"
                element={
                  <CheckSession>
                    <PetParentProfile />
                  </CheckSession>
                }
              />
              <Route
                path={`${routePaths.PURCHASE}/:customerKey${routePaths.DDC_PACKAGES}`}
                element={
                  <CheckSession>
                    <PurchaseDDCPackagesPage />
                  </CheckSession>
                }
              />
              <Route
                path="/schedules"
                element={
                  <CheckSession>
                    <Schedules />
                  </CheckSession>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <CheckSession>
                    <DashBoardPage />
                  </CheckSession>
                }
              />
              <Route
                path="/search"
                element={
                  <CheckSession>
                    <SearchSaloncontainer />
                  </CheckSession>
                }
              />
              <Route
                path="/associates"
                element={
                  <CheckSession>
                    <AssociatesSearchContainer />
                  </CheckSession>
                }
              />
              <Route
                path="/searchSRC"
                element={
                  <CheckSession>
                    <SrcDashBoard />
                  </CheckSession>
                }
              />
              <Route
                path="/booking/:customerKey/*"
                element={
                  <CheckSession>
                    <BookingPage />
                  </CheckSession>
                }
              >
                {/* This route is here with no element/component in order for the itineraryId param to get returned by the useParams hook */}
                <Route path="select-service/:itineraryId" element={<></>} />
              </Route>
              <Route
                path={routePaths.SCHEDULE_TRAINING}
                element={
                  <CheckSession>
                    <ScheduleTrainingPage />
                  </CheckSession>
                }
              />
              <Route
                path="/standalone/:customerKey/*"
                element={
                  <CheckSession>
                    <SalonBooking isStandalone />
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.MANAGE_TRAINING_CLASS}
                element={
                  <CheckSession>
                    <ManageTrainingClassContainer />
                  </CheckSession>
                }
              />
              <Route
                path={`/${pageNames.CHECK_IN}/:customerKey/:itineraryId`}
                element={
                  <CheckSession>
                    <CheckInOut />
                  </CheckSession>
                }
              />
              <Route
                path={`/${pageNames.CHECK_OUT}/:customerKey/:itineraryId`}
                element={
                  <CheckSession>
                    <CheckInOut />
                  </CheckSession>
                }
              />
              <Route
                path={`/${pageNames.PAST_APPOINTMENT}/:customerKey/:itineraryId`}
                element={
                  <CheckSession>
                    <SharedCheckInOutWrapper />
                  </CheckSession>
                }
              />
              <Route
                path={`/${pageNames.FUTURE_APPOINTMENT}/:customerKey/:itineraryId`}
                element={
                  <CheckSession>
                    <SharedCheckInOutWrapper />
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.SERVICE_MANAGEMENT}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <HotelServiceManagementPage />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path={`${routePaths.MANAGE_HOTEL}/*`}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <PetsHotelAdminLayout />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.REPORTS}
                element={
                  <CheckSession>
                    <ReportsPage />
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.SCHEDULE_SUSPENSION}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <DdcScheduleSuspensionPage />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.ROOM_SELECTION}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <HotelRoomSelectionPage />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.ROOM_MANAGEMENT}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <HotelRoomManagementPage />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path={routePaths.ROOM_MANAGEMENT_DETAILS}
                element={
                  <CheckSession>
                    <RestrictedRouteByRole
                      currentRole={jobRole}
                      allowedRoles={[jobRoleConstants.MANAGER]}
                    >
                      <HotelManageRoomDetailsPage />
                    </RestrictedRouteByRole>
                  </CheckSession>
                }
              />
              <Route
                path="/quick-quote"
                element={
                  <CheckSession>
                    <QuickQuoteContainer />
                  </CheckSession>
                }
              />
              <Route
                path="/associate-scheduling/:associateId"
                element={
                  <CheckSession>
                    <AssociateSchedulingContainer />
                  </CheckSession>
                }
              />
              <Route
                path="/template-scheduling/:associateId"
                element={
                  <CheckSession>
                    <TemplateSchedulingContainer />
                  </CheckSession>
                }
              />
              <Route
                path="/my-schedule/:associateId"
                element={
                  <CheckSession>
                    <MyScheduleContainer />
                  </CheckSession>
                }
              />
              <Route
                path="/associate/:associateId"
                element={
                  <CheckSession>
                    <AssociateProfilePage />
                  </CheckSession>
                }
              />
              <Route
                path="/modify-appointment/:customerKey/:itineraryId"
                element={
                  <CheckSession>
                    <SalonBooking />
                  </CheckSession>
                }
              />
              <Route exact path="/quick-pin/select-user" element={<SelectUserContainer />} />
              <Route path="/quick-pin/set-pin" element={<SetPinContainer />} />
              <Route path="/quick-pin/enter-pin" element={<EnterPinContainer />} />
              <Route
                path="/quick-pin/forgot-pin"
                element={
                  <CheckSession>
                    <ForgotPinContainer />
                  </CheckSession>
                }
              />
            </Routes>
            {// The user can only access routes that are on a white list and are a particular
            // job code such as an SRC agent. If there is no salon/storeNumber in the redux
            // store, and the user is an SRC agent, we let them access the route, if not
            // the user will re-direct to the login page.
            !storeNumberWhiteList.includes(history.location.pathname) ? (
              !storeNumber && jobRole && jobRole !== jobRoleConstants.SRC ? (
                <Navigate replace to="/login" />
              ) : null
            ) : null}
            {serverErrorMessage && (
              <ServerErrorModal
                header="Server error"
                shouldDisplayHeader={shouldDisplayServerErrorHeader(serverErrorMessage)}
                serverErrorMessage={serverErrorMessage}
                closeServerErrorModal={closeServerErrorModal}
              />
            )}
            {modalType && <QuickPinModals modalType={modalType} />}
            {confirmationModal.show && <ConfirmationModalContainer />}
            <CustomerProfileIncompleteModal />
          </Container>
        </Router>
        <FeatureFlagContainer />
      </SentryErrorBoundary>
    </>
  );
};

const mapStateToProps = state => ({
  storeNumber: getStoreNumber(state),
  associateId: getAssociateNumber(state),
  jobRole: getJobRole(state),
  serverError: getServerError(state),
  serverErrorMessage: getServerErrorMessage(state),
  sessionToken: getSessionToken(state),
  isAuthorizing: createLoadingSelector([
    authActionTypes.AUTHORIZE_USER,
    authActionTypes.AUTHORIZE_USER_BY_PIN,
  ])(state),
  modalType: getAuthModalType(state),
  confirmationModal: state.modals.confirmationModal,
  workflowState: getWorkflowsFeatureFlagState(state),
  systemType: getSystemType(state),
  systemBookingFlow: getSystemBookingFlow(state),
  enhancedServicesHidden: getIsEnhancedServicesWorkflowFeatureFlagHidden(state),
  hotelHidden: getIsHotelWorkflowFeatureFlagHidden(state),
});

const mapDispatchToProps = dispatch => ({
  loadPriceAdjustmentReasons: () => dispatch(loadPriceAdjustmentReasons()),
  closeServerErrorModal: () => dispatch(clearServerErrors()),
  dispatchSetUserStatus: status => dispatch(setUserStatus(status)),
  loadLunchBreakTimes: () => dispatch(loadLunchBreakTimes()),
  loadSalon: ({ storeNumber }) => dispatch(loadSalonInfoWithHours({ storeNumber })),
  getStoreCapabilities: ({ storeNumber }) => dispatch(getStoreCapabilities({ storeNumber })),
  loadStatuses: () => dispatch(loadStatuses()),
  loadMaxPerBlockOrCheckReductionReasons: () => dispatch(loadMaxPerBlockOrCheckReductionReasons()),
  showFeatureFlagIcon: ({ isHidden }) => dispatch(displayFeatureFlagIcon({ isHidden })),
  getServiceProductFamilies: () => dispatch(getServiceProductFamilies()),
});

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    loadStartupAPICalls: () => {
      const { enhancedServicesHidden, hotelHidden, storeNumber } = stateProps;
      const {
        loadPriceAdjustmentReasons,
        loadSalon,
        getStoreCapabilities,
        loadLunchBreakTimes,
        loadStatuses,
        loadMaxPerBlockOrCheckReductionReasons,
        getServiceProductFamilies,
      } = dispatchProps;

      loadPriceAdjustmentReasons();
      loadLunchBreakTimes();
      loadSalon({ storeNumber });
      loadStatuses();
      loadMaxPerBlockOrCheckReductionReasons();

      if (!hotelHidden) {
        getServiceProductFamilies();
      }
      if (!enhancedServicesHidden) {
        getStoreCapabilities({ storeNumber });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(App);
