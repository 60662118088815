import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import { formatHotelRoomSelectionLabel } from "@/core/utils/checkInOutUtils";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_HOTEL_ROOM_AVAILABILITY } from "./hotelRoomAvailabilityActions";

/**
 * Helper to generate a key for room availability for a given bucket and date range
 * @function
 * @name roomAvailabilityKey
 * @param {String|Number} roomTypeBucketId
 * @param {String} startDateTime
 * @param {String} endDateTime
 * @returns {String}
 * @example roomAvailabilityKey(roomTypeBucketId, startDateTime, endDateTime)
 */
export const roomAvailabilityKey = (roomTypeBucketId, startDateTime, endDateTime) =>
  [
    roomTypeBucketId,
    startDateTime ? formatCalendarDateMoment(startDateTime) : "",
    endDateTime ? formatCalendarDateMoment(endDateTime) : "",
  ].join("_");

/**
 *  Selector to get all available hotel rooms
 *
 *  @memberOf Selectors.HotelEligibility
 *  @function
 *  @name getRoomAvailability
 *  @param { Object } state - redux state
 *  @returns { Array }
 *  @example
 *
 *  getRoomAvailability(state)
 */
export const getRoomAvailability = createSelector([getState], state => {
  return state?.hotelRoomAvailability;
});

/**
 * Selector to get all hotel rooms for a given room bucket Id, start date, and end date
 * @memberOf Selectors.HotelEligibility
 * @function
 * @name getRoomAvailabilityByBucketId
 * @param {Number} roomTypeBucketId
 * @returns {(state: Object) => Object} selector to get room availability for the given bucket
 * @example getRoomAvailabilityByBucketId(roomTypeBucketId, startDateTime, endDateTime)(state)
 */
export const getRoomAvailabilityByBucketId = (roomTypeBucketId, startDateTime, endDateTime) =>
  createSelector(
    [getRoomAvailability],
    roomAvailability =>
      roomAvailability[roomAvailabilityKey(roomTypeBucketId, startDateTime, endDateTime)] ?? {},
  );

/**
 *  Selector to get available rooms based on roomTypeBucketId
 *
 *  @memberOf Selectors.HotelEligibility
 *  @function
 *  @name getRoomAvailabilityOptions
 *  @param { Object } state - redux state
 *  @returns { Array }
 *  @example
 *
 *  getRoomAvailabilityOptions(roomTypeBucketId, startDateTime, endDateTime)(state)
 */
export const getRoomAvailabilityOptions = (roomTypeBucketId, startDateTime, endDateTime) =>
  createSelector(
    [getRoomAvailabilityByBucketId(roomTypeBucketId, startDateTime, endDateTime)],
    roomAvailability => {
      return Object.values(roomAvailability)?.map(roomBucket => {
        return {
          label: roomBucket?.roomTypeBucketName,
          value: roomBucket?.rooms
            .filter(room => room.isAssignable)
            .map(room => {
              const roomNumber = room?.roomNumber;
              const label = formatHotelRoomSelectionLabel(room);

              return { label, value: roomNumber };
            }),
        };
      });
    },
  );

/**
 * Selector to check if a given room number is a valid option for a given roomTypeBucketId
 * @memberOf Selectors.hotelRoomAvailability
 * @function
 * @name selectIsRoomValid
 * @param { Object } arg
 * @param { Object } arg.roomTypeBucketId
 * @param { Object } arg.roomNumber
 * @returns { Boolean } - true if room is valid, false otherwise
 * @example selectIsRoomValid({ roomTypeBucketId, roomNumber, startDateTime, endDateTime })(state)
 */
export const selectIsRoomValid = ({
  roomTypeBucketId,
  startDateTime,
  endDateTime,
  roomNumber,
} = {}) =>
  createSelector(
    [getRoomAvailabilityOptions(roomTypeBucketId, startDateTime, endDateTime)],
    roomOptions =>
      roomOptions?.some(({ value: options } = {}) =>
        options?.find(({ value } = {}) => +value === +roomNumber),
      ),
  );

/**
 * Selector to get room availability loading state
 * @memberOf Selectors.hotelRoomAvailability
 * @function
 * @name getRoomAvailabilityLoadingState
 * @param {Object} state
 * @returns {Object}
 * @example getRoomAvailabilityLoadingState(state)
 */
export const getRoomAvailabilityLoadingState = createSelector(
  [getRoomAvailability],
  hotelRoomAvailability => hotelRoomAvailability?.loading ?? {},
);

/**
 * Selector to check if rooms are loading for a given bucket and dates
 * @memberOf Selectors.hotelRoomAvailability
 * @function
 * @name getRoomAvailabilityIsLoading
 * @param {String} roomTypeBucketId
 * @param {String} startDateTime
 * @param {String} endDateTime
 * @returns {Boolean} - true if room data is loading
 * @example getRoomAvailabilityIsLoading(roomTypeBucketId, startDateTime, endDateTime)(state)
 */
export const getRoomAvailabilityIsLoading = (roomTypeBucketId, startDateTime, endDateTime) => {
  const key = roomAvailabilityKey(roomTypeBucketId, startDateTime, endDateTime);
  return createSelector(
    [getRoomAvailabilityLoadingState, createLoadingSelector([GET_HOTEL_ROOM_AVAILABILITY])],
    (loading, isApiCallLoading) => isApiCallLoading && !!loading[key],
  );
};
