import { connect } from "react-redux";
import {
  getBundleAddOnIdsByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getSelectedAddOnIds } from "dux/_selectors/addOnsSelectors";
import wrapAddonIdsToObjects from "dux/utils/addOns/wrapAddonIdsToObjects";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getAddonsByService } from "core/selectors/entitiesSelector";
import { getAppointmentByPet, getCurrentPetServiceId } from "core/selectors/cartSelectors";
import { setAddons } from "core/actionCreators/ui/web/cartActionCreators";
import { BookingAddonsPriceListComponent } from "./BookingAddonsPriceListComponent";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const petServiceId = getCurrentPetServiceId(state, { petId });
  const appointment = getAppointmentByPet(state, { petId });
  const addOns = getAddonsByService(state, { petId, petServiceId: appointment.petService });
  const bundleAddOns = getBundleAddOnIdsByPetServiceId(state, { petId, petServiceId });
  const isActiveBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });

  // We only want the add-on option to be disabled if there is an active bundle available.
  // This prevents us from getting into a situation where there is no active bundle available,
  // an add-on is force-added, and then is unable to be unchecked.
  const disabledOptions = isActiveBundleAvailable ? bundleAddOns : [];

  return {
    componentId: "SalonBookingAddonsPriceList",
    petId,
    bundleAddOns,
    disabledOptions,
    selectedAddOns: getSelectedAddOnIds(state, { petId }),
    properties: { id: "addOnId", name: "addOnName" },
    addOns: [
      { label: "Most Popular", value: addOns.mostPopular || [] },
      { label: "Other Add-ons", value: addOns.mainAddOns || [] },
    ],
  };
};

const mapDispatchToProps = dispatch => ({
  handleOnSelectionChange: ({ petId, addOns }) => {
    dispatch(setAddons({ addons: wrapAddonIdsToObjects(addOns), petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSelectionChange: addOn => {
    const { selectedAddOns, petId } = propsFromState;
    const { handleOnSelectionChange } = propsFromDispatch;

    // Remove add-on id if already listed in the array.
    // If not, append it to the list of selected add-ons.
    const addOns = selectedAddOns.includes(addOn)
      ? selectedAddOns?.filter(selectedAddOn => selectedAddOn !== addOn)
      : [...selectedAddOns, addOn];

    handleOnSelectionChange({ petId, addOns });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BookingAddonsPriceListComponent);
