import React, { useState } from "react";
import { LayoutBox } from "@/layout/box/Box";
import { SelectField } from "@petsmart-ui/sparky";

const SelectWithWrapper = props => {
  const {
    title,
    options,
    componentId,
    isHidden,
    value,
    defaultValue,
    onChange,
    error,
    disabled,
    name,
    style,
    className,
    onFocus = () => {},
    onBlur = () => {},
  } = props;

  const [touched, setTouched] = useState(false);

  if (isHidden) return null;

  const showError = (props?.showError || touched) && !!error;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <SelectField
        defaultValue={defaultValue}
        fieldNote={showError && error}
        isError={!!showError}
        items={options}
        label={title}
        name={name}
        value={value}
        disabled={disabled}
        className={className}
        onFocus={onFocus}
        onBlur={e => {
          onBlur(e);
          setTouched(true);
        }}
        onChange={e => {
          const option = e?.target?.selectedOptions?.[0];
          onChange({ value: option?.value, label: option?.label });
        }}
        style={style}
      />
    </LayoutBox>
  );
};

export default SelectWithWrapper;
