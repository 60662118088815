import { connect } from "react-redux";
import { frequencyTypes } from "dux/frequency/FrequencyConstants";
import { foodFrequencyTouched } from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";
import {
  getPendingFrequencyType,
  getPendingId,
  getPendingPetId,
} from "dux/frequency/frequencySelectors";
import { medicationFrequencyTouched } from "dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { color } from "web/common/styles/theme";
import { hideBookingModal } from "core/actionCreators/bookingActionCreators";
import ClickableText from "../../../web/common/ClickableText";
import { clearPendingFrequency } from "../actions/frequencyActions";
import { getCheckForSameDay } from "../dateRangeForFrequencySelectors";

const mapStateToProps = (state, { onClose }) => {
  return {
    componentID: "frequencyModalCancelButton",
    pendingId: getPendingId(state),
    petId: getPendingPetId(state),
    isVisible: true,
    label: "Go Back",
    styleObj: { color: color.blue500, padding: "10px" },
    frequencyType: getPendingFrequencyType(state),
    onClose,
    // here for merge props
    isSameDay: getCheckForSameDay(state),
  };
};

const mapDispatchToProps = dispatch => ({
  hideFrequencyModal: ({ pendingId, petId, frequencyType, isSameDay }) => {
    dispatch(hideBookingModal());

    if (!isSameDay) {
      if (frequencyType === frequencyTypes.MEDICATION) {
        dispatch(medicationFrequencyTouched({ medicationId: pendingId, petId }));
      }
      if (frequencyType === frequencyTypes.FOOD) {
        dispatch(foodFrequencyTouched({ foodId: pendingId, petId }));
      }
      if (frequencyType === frequencyTypes.ADDON) {
        dispatch(clearPendingFrequency());
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { pendingId, petId, frequencyType, isSameDay, onClose } = propsFromState;
    if (onClose) onClose();
    propsFromDispatch.hideFrequencyModal({ pendingId, petId, frequencyType, isSameDay });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
