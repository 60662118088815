import { connect } from "react-redux";
import { CancelButton } from "@/web/addFood/buttons/CancelNewFoodButtonContainer";
import {
  hideAddNewMedication,
  clearNewMedication,
} from "../../newMedication/actions/newMedicationActions";
import { NEW_MEDICATION_ID } from "../../newMedication/newMedicationConstants";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  return {
    componentId: `newMedication__cancelButton`,
    medicationId,
    petId,
    isHidden: medicationId !== NEW_MEDICATION_ID,
    label: "Cancel",
  };
};

const mapDispatchToProps = dispatch => ({
  hideNewMed: petId => {
    dispatch(hideAddNewMedication({ petId }));
    dispatch(clearNewMedication({ petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { petId } = propsFromState;
    propsFromDispatch.hideNewMed(petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CancelButton);
