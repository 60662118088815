import { createSelector } from "reselect";

export const getSpecies = state => state.enums.species;
export const getEnumMedications = state => state.enums.medications;
export const getVaccinations = state => state?.enums?.vaccinations;
export const getBreeds = state => state?.enums?.breeds;
export const getGenders = state => state.enums.genders;
export const getColors = state => state.enums.colors;
export const getConditions = state => state.enums.conditions;
export const getStates = state => state.enums.states;
export const getCountries = state => state.enums.countries;

/**
 * selector to get Species List
 *
 * @memberOf Enums
 * @type {OutputSelector<[(function(*): *)], unknown[], (...args: SelectorResultArray<[(function(*): *)]>) => (unknown[] & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const getSpeciesList = createSelector([getSpecies], species => Object.values(species));

export const getBreedsList = createSelector([getBreeds], breeds => Object.values(breeds));

export const getGendersList = createSelector([getGenders], genders => Object.values(genders));

export const getVaccinationsList = createSelector([getVaccinations], vaccinations =>
  Object.values(vaccinations ?? {}),
);

export const getColorsList = createSelector([getColors], colors => Object.values(colors));

export const getPetHealthConditionsList = createSelector([getConditions], conditions =>
  Object.values(conditions),
);

export const getBreedsOptions = createSelector([getBreedsList], breeds =>
  breeds.map(breed => ({
    value: breed.BreedId,
    label: breed.Description,
    speciesId: breed.SpeciesId,
  })),
);

export const getGendersOptions = createSelector([getGendersList], genders =>
  genders.map(gender => ({ value: gender.GenderId, label: gender.Name })),
);

export const getSpeciesOptions = createSelector([getSpeciesList], speciesList =>
  speciesList
    .filter(species => species.SpeciesId === 1 || species.SpeciesId === 2)
    .map(species => ({ value: species.SpeciesId, label: species.Name })),
);

export const getColorsOptions = createSelector([getColorsList], colors =>
  colors.map(color => ({ value: color.ColorId, label: color.Description })),
);

export const getStatesOptions = createSelector([getStates], states =>
  Object.keys(states).map(state => ({ value: state, label: state })),
);

export const getCountriesOptions = createSelector([getCountries], countries =>
  Object.keys(countries).map(country => ({ value: country, label: country })),
);
