import { connect } from "react-redux";
import { isEqual, get } from "lodash/fp";

import { history } from "@/dux/utils/browser/browserHistory";
import PetProfileMedicationFormComponent from "../common/PetProfileMedicationFormComponent";
import {
  getMedicationIds,
  getMedicationById,
  getPetMedicationById,
  getMedIsDiscontinuedById,
} from "../medication/medicationsSelector";
import { getMedicationIsShowingDetailsForType } from "../medicationList/medicationListSelectors";
import { updatePetMedication } from "../medication/actions/createUpdateDeleteMedicationActions";
import { formatMedication } from "../medication/medicationUtils";
import isRequiredPetMedicationFieldMissing from "../newMedication/utils/isRequiredPetMedicationFieldMissing";
import { isFromBooking } from "../../core/utils/validationUtils/isFromValidation";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { medicationTypes } from "../newMedication/newMedicationConstants";
import { setMedSaveButtonTouched } from "../medication/actions/medicationsActions";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId } = ownProps;
  const medicationList = selectMedicationsList(state);
  const locationType = medicationTypes.PROFILE;
  return {
    componentId: `petProfileMedForm-${medicationId}`,
    petId,
    medicationId,
    medicationList,
    medicationIndex,
    isShowingDetails: getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType,
    }),
    isHidden:
      isFromBooking(get(["location", "pathname"], history)) ||
      !getMedicationIsShowingDetailsForType(state, { medicationId, petId, locationType }),
    medicationIds: getMedicationIds(state, { petId }),
    medication: getMedicationById(state, { medicationId, petId }),
    petMedication: getPetMedicationById(state, { petId, medicationId }),
    canUpdateFromFeatureFlag: !getIsHotelWorkflowFeatureFlagHidden(state),
    disabled: getMedIsDiscontinuedById(state, { petId, medicationId }),
  };
};

const mapDispatchToProps = (dispatch, { medicationId, petId }) => ({
  updatePetMedications: (medication, isMedicationDetailsShowing) =>
    dispatch(updatePetMedication({ medication, petId, isMedicationDetailsShowing })),
  setSaveTouched: () => dispatch(setMedSaveButtonTouched({ medicationId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: () => {
    const {
      medication,
      petMedication,
      canUpdateFromFeatureFlag,
      medicationList,
      petId,
      disabled,
    } = propsFromState;
    if (disabled) return;

    const medToSend = petMedication && formatMedication(medication);
    const petMed = petMedication && formatMedication(petMedication);

    // If med in state has changed and nothing required is missing, update.
    if (
      canUpdateFromFeatureFlag &&
      !isEqual(petMed, medToSend) &&
      !isRequiredPetMedicationFieldMissing({ medication: medToSend, medicationList, petId })
    ) {
      propsFromDispatch.updatePetMedications(medToSend, medication.isMedicationDetailsShowing);
    }

    propsFromDispatch.setSaveTouched();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PetProfileMedicationFormComponent);
