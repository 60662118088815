import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { getCurrentCustomerFirstName } from "core/selectors/persistent/customer/customerSelectors";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";
import { getEligiblePromotionReasonDDC } from "dux/eligiblePromotions/eligiblePromotionsSelectors";
import { ELIGIBLE_PROMOTIONS_DDC_LABEL } from "dux/eligiblePromotions/eligiblePromotionConstants";
import EligiblePromotionsBGMTextComponent from "dux/_components/eligiblePromotions/EligiblePromotionsBGMTextComponent";
import { isDDCBannerHidden } from "./helpers/isHiddenHelpers";

export const DdcBannerText = connect(state => {
  return {
    componentID: "DdcBannerText",
    firstName: getCurrentCustomerFirstName(state),
    label: `${ELIGIBLE_PROMOTIONS_DDC_LABEL}`,
    reason: getEligiblePromotionReasonDDC(state),
  };
})(EligiblePromotionsBGMTextComponent);

export const DdcEligiblePromotionsBanner = connect(state => {
  return {
    componentId: "DDCEligiblePromotionsBanner",
    isHidden: isDDCBannerHidden(state),
    src: PreCheckedInIcon,
    diComp: DdcBannerText,
    styleObj: {
      padding: "1em",
      justifyContent: "center",
      borderBottom: `1px solid ${color.gray300}`,
      backgroundColor: color.green50,
    },
  };
})(IconWithLabel);
