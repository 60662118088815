import { useForm } from "dux/utils/formUtils/useForm";
import { Button, Form, Text } from "@prism/psm-ui-components";
import { Button as SparkyButton } from "@petsmart-ui/sparky";
import { connect } from "react-redux";
import { createLoadingSelector } from "core/selectors/utils";
import associateActionTypes from "core/actionTypes/associateActionTypes";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { history } from "dux/utils/browser/browserHistory";
import { isFromCheckInOut } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { getPetSpeciesId } from "core/selectors/ui/vaccinations/vaccinationsSelectors";
import { color } from "web/common/styles/theme";
import { medicationTypes, NEW_MEDICATION_ID } from "web/newMedication/newMedicationConstants";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import {
  clearPendingMedById,
  saveReservationCartDetails,
  setPendingMed,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { selectCheckInOutDateRangeForFrequencyByPet } from "dux/hotelEngagements/hotelEngagementSelectors";
import CommonModal from "web/common/modals/commonModal";
import SetFrequencyModalContainer from "dux/frequency/modal/SetFrequencyModalContainer";
import {
  clearPendingFrequency,
  setPendingFrequency,
  setPendingFrequencyMedicationDates,
  setPendingId,
} from "dux/frequency/actions/frequencyActions";
import { frequencyErrors, frequencyTypes } from "dux/frequency/FrequencyConstants";
import {
  getCheckInOutNewMedNumber,
  getIsCheckInOutPetMedFormValid,
  getMedFormData,
} from "dux/_components/checkInOutMedications/CheckInOutCartMedsSelectors";
import { getMedicationIsShowingDetailsForType } from "web/medicationList/medicationListSelectors";
import {
  clearNewMedication,
  hideAddNewMedication,
} from "web/newMedication/actions/newMedicationActions";
import { getIsNotAddingNewMedication } from "web/newMedication/selectors/newMedicationSelector";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "core/constants/validationErrors";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { v4 as uuidv4 } from "uuid";
import {
  getPendingFrequency,
  getPendingFrequencyMedicationDates,
  getPendingId,
} from "@/dux/frequency/frequencySelectors";
import { checkIfFrequencyUpdated } from "@/core/utils/frequencyUtils/checkIfFrequencyUpdated";
import { getFrequencyAndDates } from "@/core/utils/frequencyUtils/getFrequencyAndDates";
import { HotelCheckInOutMedRemoveButton } from "@/dux/hotelRemoveButton/HotelRemoveButton";
import { stripEmojis } from "@/core/utils/stringUtils/unicodeUtils";
import { getPetMedicationOptions } from "@/web/medicationName/medicationNameSelectors";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { minimizeWhitespace } from "@/core/utils/stringUtils/stringModificationUtils";
import { isValidFrequency } from "@/core/utils/validationUtils/validateFrequency";
import { selectDefaultFrequency } from "@/dux/frequency/dateRangeForFrequencySelectors";
import MedicationSaveToProfileCheckboxContainer from "@/dux/bookingMedication/bookingSaveMedicationToProfile/MedicationSaveToProfileCheckboxContainer";
import { createPetMedication } from "@/web/medication/actions/createUpdateDeleteMedicationActions";
import { formatMedFromCheckIn } from "@/web/medication/medicationUtils";
import { getMedicationSaveToProfileById } from "@/dux/bookingMedication/bookingSaveMedicationToProfile/medicationSaveToProfileSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { showHideMedicationDetails } from "@/web/medicationList/actions/medicationListActions";
import { getMedIsDiscontinuedById } from "@/web/medication/medicationsSelector";
import { CheckInOutPetProfileMedDropdown } from "@/dux/checkInOutPetProfileFoodMedDropdown/CheckInOutPetProfileFoodMedDropdown";
import { MedFormLayout } from "../medication/MedFormLayout";
import { MedNameField } from "@/web/medicationName/MedicationNameContainer";
import { OtherMedNameField } from "@/web/otherMedicationName/OtherMedicationNameContainer";
import { MedAmountField } from "@/web/medicationTypeAmount/MedicationTypeAmountContainer";
import { TimeOfDayCheckboxes } from "@/dux/timeOfDayCheckboxes/TimeOfDayCheckboxes";

const CheckInOutMedsForm = ({
  isDisabled,
  medFormData,
  medIndex,
  medNameOptions,
  isHidden,
  onSubmit,
  onShowFrequencyModal,
  callHideNewMed,
  medId,
  setMedFormInvalid,
  invalidMedForms,
  isNotAddingNewMed,
  clearNewMed,
  componentId,
  clearFrequency = () => {},
  frequencyDates,
  hasFrequencyUpdated = () => {},
  petId,
  isRemoveDisabled,
  isRemoveButtonHidden,
  defaultFrequency,
  saveToProfile,
  hideMedForm = () => {},
}) => {
  const {
    values,
    setFormValues,
    setTextAreaInput,
    didFormFieldsUpdate,
    setFormValue,
    touched,
  } = useForm(medFormData, minimizeWhitespace);

  const {
    externalId,
    medDisplayName,
    otherMedName,
    medAmount,
    morning,
    afternoon,
    evening,
    medInstructions,
    frequency = defaultFrequency,
    petProfileMedId,
  } = values;

  const [isModalShowing, setModalShowing] = useState(false);
  const [disableClickOutside, setDisableClickOutside] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const isFormValid = useMemo(
    () => getIsCheckInOutPetMedFormValid({ values, dates: frequencyDates }),
    [
      medDisplayName,
      otherMedName,
      medAmount,
      morning,
      afternoon,
      evening,
      frequency,
      frequencyDates,
    ],
  );

  // If the form gets closed, reset the form values and error state
  useEffect(() => {
    if (isHidden) {
      setFormValues(medFormData);
      setShowErrors(false);

      if (medId === NEW_MEDICATION_ID) {
        callHideNewMed();
        if (setMedFormInvalid) setMedFormInvalid(invalidMedForms?.filter(form => form !== medId));
      }
    }
  }, [isHidden]);

  useEffect(() => {
    if (invalidMedForms)
      if (medId === NEW_MEDICATION_ID && isNotAddingNewMed) {
        return;
      }

    if (!isFormValid && invalidMedForms && invalidMedForms?.find(form => form === medId)) {
    } else if (!isFormValid) {
      if (setMedFormInvalid) setMedFormInvalid([...invalidMedForms, medId]);
    } else if (setMedFormInvalid)
      setMedFormInvalid(invalidMedForms?.filter(form => form !== medId));
  }, [isFormValid, isNotAddingNewMed]);

  useEffect(() => {
    if (medId === NEW_MEDICATION_ID) {
      callHideNewMed();
    }
  }, [petId]);

  const ref = useRef(null);

  const onClickOutside = () => {
    const didFrequencyOrDatesUpdate = hasFrequencyUpdated(frequency);
    const didUpdate = didFormFieldsUpdate || didFrequencyOrDatesUpdate || saveToProfile;

    if (isDisabled || !didUpdate || (medId === NEW_MEDICATION_ID && isNotAddingNewMed)) {
      return;
    }

    // Sanitize user inputs
    const sanitizedValues = {
      ...values,
      medInstructions: !!values.medInstructions ? stripEmojis(values.medInstructions) : "",
    };

    if (isFormValid) onSubmit(sanitizedValues);
    setShowErrors(true);
  };

  const timeOfDayTouched =
    showErrors || !!touched?.morning || !!touched?.afternoon || !!touched?.evening;

  ClickOutside(ref, onClickOutside, isModalShowing || disableClickOutside);

  return !isHidden ? (
    <div ref={ref}>
      <LayoutBox>
        <CheckInOutPetProfileMedDropdown
          medId={medId}
          petProfileMedId={petProfileMedId}
          onChange={med => setFormValues({ ...values, ...med })}
        />

        <MedFormLayout componentId={componentId}>
          <MedNameField
            medicationIndex={medIndex}
            options={medNameOptions}
            onChange={e => {
              const name = medNameOptions?.find(med => med.value === +e?.value)?.medName;
              setFormValue("medDisplayName", e?.label);
              setFormValue("medName", name);
              setFormValue("externalId", e?.value);
              setFormValue("petProfileMedId", "");

              // Set clear otherName if value goes from other to a different value
              if (e.label !== "Other (Follow Up)" && medDisplayName === "Other (Follow Up)") {
                setFormValue("otherMedName", "");
              }
            }}
            value={externalId}
            isDisabled={isDisabled}
            error={!isValidString(medDisplayName) && REQUIRED_FIELD_ERROR_MESSAGE}
            showError={showErrors}
          />

          <OtherMedNameField
            onChange={value => setFormValue("otherMedName", value)}
            disabled={isDisabled || medDisplayName !== "Other (Follow Up)"}
            currentValue={otherMedName ?? ""}
            error={
              !isValidString(otherMedName) &&
              medDisplayName === "Other (Follow Up)" &&
              REQUIRED_FIELD_ERROR_MESSAGE
            }
            showError={showErrors}
          />

          <MedAmountField
            medicationIndex={medIndex}
            onChange={value => setFormValue("medAmount", value)}
            currentValue={medAmount}
            disabled={isDisabled}
            error={!isValidString(medAmount) && REQUIRED_FIELD_ERROR_MESSAGE}
            showError={showErrors}
          />

          <TimeOfDayCheckboxes
            componentId={`${componentId}__medTimeOfDay`}
            label={`Medication ${medIndex} time of day`}
            error={
              timeOfDayTouched && !morning && !afternoon && !evening && REQUIRED_FIELD_ERROR_MESSAGE
            }
            morning={morning}
            afternoon={afternoon}
            evening={evening}
            disabled={isDisabled}
            onChange={(newTimeOfDay = {}) =>
              Object.entries(newTimeOfDay).map(([key, value]) => setFormValue(key, value))
            }
          />

          <LayoutBox padding="scale-0">
            <LayoutStack>
              <Text id={`${componentId}__label--medInstructions`} size="text-size-m,">
                Medication {medIndex} instructions
              </Text>
              <Form.Field.TextArea
                style={{
                  border: "1px solid black",
                  fontSize: "1.15rem",
                  fontFamily: "Lato",
                  color: isDisabled ? color.gray600 : "inherit",
                }}
                width="100%"
                rows={4}
                name="medInstructions"
                value={medInstructions}
                onInput={setTextAreaInput}
                disabled={isDisabled}
              />
            </LayoutStack>
          </LayoutBox>

          <MedicationSaveToProfileCheckboxContainer medicationId={medId} petId={petId} />

          <LayoutBox padding="scale-0">
            <LayoutStack>
              <LayoutCluster style={{ justifyContent: "flex-start" }}>
                <SparkyButton
                  id={`${componentId}__button--setFrequency`}
                  onClick={() => {
                    setModalShowing(true);
                    onShowFrequencyModal({ frequency });
                  }}
                  variant="primary"
                  size="lg"
                  disabled={isDisabled}
                  text="Set Frequency"
                />
                <SparkyButton
                  id={`${componentId}__cancelButton--medFrequency`}
                  variant="link"
                  size="lg"
                  onClick={() => {
                    if (medId === NEW_MEDICATION_ID) clearNewMed();
                    setFormValues(medFormData);
                    clearFrequency();
                    setMedFormInvalid(invalidMedForms.filter(form => form !== medId));
                    hideMedForm({ petId, medId });
                  }}
                  disabled={isDisabled}
                  text="Cancel"
                />
              </LayoutCluster>
              {showErrors && !isValidFrequency(frequency, frequencyDates) && (
                <Text id={`${componentId}__requiredField--medFrequency`} color="text-color-red">
                  {frequencyErrors.REQUIRED}
                </Text>
              )}
            </LayoutStack>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <HotelCheckInOutMedRemoveButton
              medId={medId}
              medIndex={medIndex}
              isDisabed={isRemoveDisabled}
              isHidden={isRemoveButtonHidden}
              petId={petId}
              onClick={() => setDisableClickOutside(true)}
              onConfirm={() => setDisableClickOutside(false)}
            />
          </LayoutBox>
        </MedFormLayout>

        {isModalShowing && (
          <CommonModal
            id={`${componentId}__commonModal--deleteMed`}
            onClose={() => {
              setModalShowing(false);
            }}
          >
            <SetFrequencyModalContainer
              id={`${componentId}__modalContent--deleteMed`}
              onClose={() => {
                setModalShowing(false);
              }}
            />
          </CommonModal>
        )}
      </LayoutBox>
    </div>
  ) : null;
};

const setPendingMedAndCallItineraryPut = ({
  dispatch,
  petId,
  data,
  customerKey,
  hotelItineraryId,
  medId = NEW_MEDICATION_ID,
  groupingId,
  saveToProfile,
}) => {
  const medData = { ...data, groupingId };
  dispatch(setPendingMed({ medData, medId, petId }));
  dispatch(
    saveReservationCartDetails({
      petId,
      hotelItineraryId,
      customerKey,
    }),
  );

  if (saveToProfile)
    dispatch(
      createPetMedication({
        medication: formatMedFromCheckIn(medData),
        petId,
        isFromBooking: true,
      }),
    );
};

export const CheckInOutNewMed = connect(
  (state, { setMedFormInvalid, invalidMedForms }) => {
    const petId = getCurrentPet(state);
    const itineraryId = getCurrentItinerary(state);
    const medId = NEW_MEDICATION_ID;
    const medIndex = getCheckInOutNewMedNumber(state, { petId });
    const isNotAddingNewMed = getIsNotAddingNewMedication(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const customerKey = getCurrentCustomerKey(state);
    const usePendingFrequency = getPendingId(state) === medId;
    const { customFrequencyDates, ...medFormData } = getMedFormData(state, {
      medId,
      petId,
    });

    const pendingFrequency = usePendingFrequency && getPendingFrequency(state);
    const pendingFrequencyDates = usePendingFrequency && getPendingFrequencyMedicationDates(state);

    return {
      componentId: `checkInOut_medicationForm--${medId}`,
      isDisabled: isReservationDisabled,
      isRemoveDisabled: isReservationDisabled,
      isLoading: createLoadingSelector([associateActionTypes.UPDATE_ASSOCIATE])(state),
      isHidden: !isFromCheckInOut(get(["location", "pathname"], history)) || isNotAddingNewMed,
      medFormData,
      medNameOptions: getPetMedicationOptions(state),
      speciesId: getPetSpeciesId(state, { petId }),
      medIndex,
      petId,
      medId,
      isRemoveButtonHidden: true,
      setMedFormInvalid,
      invalidMedForms,
      isNotAddingNewMed,
      customerKey,
      hotelItineraryId: itineraryId,
      pendingAppointmentDates: selectCheckInOutDateRangeForFrequencyByPet(state, { petId }),
      pendingFrequency,
      pendingFrequencyDates,
      defaultFrequency: selectDefaultFrequency(state),
      saveToProfile: getMedicationSaveToProfileById(state, { medicationId: medId, petId }),
      frequencyDates: pendingFrequencyDates || customFrequencyDates || [],
      hasFrequencyUpdated: frequency =>
        checkIfFrequencyUpdated(
          frequency,
          customFrequencyDates,
          pendingFrequency,
          pendingFrequencyDates,
        ),
    };
  },
  dispatch => ({
    setPendingMeds: ({ petId, data, customerKey, hotelItineraryId, saveToProfile }) =>
      setPendingMedAndCallItineraryPut({
        dispatch,
        petId,
        data,
        customerKey,
        hotelItineraryId,
        groupingId: uuidv4(),
        saveToProfile,
      }),
    clearPendingMed: () => dispatch(clearPendingMedById({ medId: NEW_MEDICATION_ID })),
    setFrequency: ({ medId, petId, frequency, dates = [] }) => {
      dispatch(setPendingId({ pendingId: medId, petId, frequencyType: frequencyTypes.MEDICATION }));
      dispatch(setPendingFrequency({ frequency }));
      dispatch(setPendingFrequencyMedicationDates({ dates }));
    },
    hideNewMed: ({ petId }) => {
      dispatch(hideAddNewMedication({ petId }));
      dispatch(clearNewMedication({ petId }));
    },
    clearFrequency: () => dispatch(clearPendingFrequency()),
    hideMedForm: ({ petId, medId }) =>
      dispatch(showHideMedicationDetails({ medicationId: medId, petId, locationTypes: [] })),
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onShowFrequencyModal: () => {
      const { setFrequency } = dispatchProps;
      const {
        petId,
        medId,
        medFormData,
        pendingAppointmentDates,
        pendingFrequency,
        pendingFrequencyDates,
        defaultFrequency,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: medFormData?.frequency,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setFrequency({
        medId,
        petId,
        frequency,
        dates,
      });
    },
    callHideNewMed: () => {
      const { hideNewMed } = dispatchProps;
      const { petId } = stateProps;
      hideNewMed({ petId });
    },
    clearNewMed: () => {
      const { clearPendingMed, hideNewMed } = dispatchProps;
      const { petId } = stateProps;
      clearPendingMed();
      hideNewMed({ petId });
    },
    onSubmit: values => {
      const { setPendingMeds, clearPendingMed, hideNewMed, setFrequency } = dispatchProps;
      const {
        petId,
        medId,
        customerKey,
        hotelItineraryId,
        pendingFrequency,
        pendingFrequencyDates,
        pendingAppointmentDates,
        defaultFrequency,
        saveToProfile,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setPendingMeds({
        data: {
          ...values,
          frequency,
          customFrequencyDates: dates,
        },
        petId,
        customerKey,
        hotelItineraryId,
        saveToProfile,
      });

      hideNewMed({ petId });
      clearPendingMed();
      setFrequency({ medId, petId, frequency: defaultFrequency });
    },
  }),
)(CheckInOutMedsForm);

export const CheckInOutUpdateMedForm = connect(
  (state, { medicationId, medicationIndex, setMedFormInvalid, invalidMedForms }) => {
    const petId = getCurrentPet(state);
    const itineraryId = getCurrentItinerary(state);
    const isShowingDetails = getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType: medicationTypes.CART,
    });
    const isReservationDisabled = selectIsReservationDisabled(state);
    const customerKey = getCurrentCustomerKey(state);
    const usePendingFrequency = getPendingId(state) === medicationId;
    const { customFrequencyDates, ...medFormData } = getMedFormData(state, {
      medId: medicationId,
      petId,
    });

    const pendingFrequency = usePendingFrequency && getPendingFrequency(state);
    const pendingFrequencyDates = usePendingFrequency && getPendingFrequencyMedicationDates(state);
    const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });

    return {
      componentId: `checkInOut_medicationForm--${medicationId}`,
      isDisabled: isReservationDisabled || isDiscontinued,
      isRemoveDisabled: isReservationDisabled,
      isLoading: createLoadingSelector([associateActionTypes.UPDATE_ASSOCIATE])(state),
      isHidden: !isFromCheckInOut(get(["location", "pathname"], history)) || !isShowingDetails,
      medFormData,
      medNameOptions: getPetMedicationOptions(state),
      speciesId: getPetSpeciesId(state, { petId }),
      medIndex: medicationIndex,
      petId,
      medId: medicationId,
      isRemoveButtonHidden: false,
      setMedFormInvalid,
      invalidMedForms,
      customerKey,
      hotelItineraryId: itineraryId,
      pendingAppointmentDates: selectCheckInOutDateRangeForFrequencyByPet(state, { petId }),
      pendingFrequency: usePendingFrequency && getPendingFrequency(state),
      pendingFrequencyDates: usePendingFrequency && getPendingFrequencyMedicationDates(state),
      customFrequencyDates,
      defaultFrequency: selectDefaultFrequency(state),
      saveToProfile: getMedicationSaveToProfileById(state, { medicationId, petId }),
      frequencyDates: pendingFrequencyDates || customFrequencyDates || [],
      hasFrequencyUpdated: frequency =>
        checkIfFrequencyUpdated(
          frequency,
          customFrequencyDates,
          pendingFrequency,
          pendingFrequencyDates,
        ),
    };
  },
  dispatch => ({
    setPendingMeds: ({ medId, petId, data, customerKey, hotelItineraryId, saveToProfile }) =>
      setPendingMedAndCallItineraryPut({
        dispatch,
        medId,
        petId,
        data,
        customerKey,
        hotelItineraryId,
        saveToProfile,
      }),
    setFrequency: ({ medId, petId, frequency, dates = [] }) => {
      dispatch(setPendingId({ pendingId: medId, petId, frequencyType: frequencyTypes.MEDICATION }));
      dispatch(setPendingFrequency({ frequency }));
      dispatch(setPendingFrequencyMedicationDates({ dates }));
    },
    clearFrequency: () => dispatch(clearPendingFrequency()),
    hideMedForm: ({ petId, medId }) =>
      dispatch(showHideMedicationDetails({ medicationId: medId, petId, locationTypes: [] })),
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onShowFrequencyModal: () => {
      const { setFrequency } = dispatchProps;
      const {
        petId,
        medId,
        medFormData,
        pendingAppointmentDates,
        pendingFrequency,
        pendingFrequencyDates,
        customFrequencyDates,
        defaultFrequency,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: medFormData?.frequency,
        frequencyDates: customFrequencyDates,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setFrequency({
        medId,
        petId,
        frequency,
        dates,
      });
    },
    onSubmit: values => {
      const { setPendingMeds } = dispatchProps;
      const {
        petId,
        medId,
        customerKey,
        hotelItineraryId,
        pendingFrequency,
        pendingFrequencyDates,
        pendingAppointmentDates,
        customFrequencyDates,
        defaultFrequency,
        saveToProfile,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: values?.frequency,
        frequencyDates: customFrequencyDates,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setPendingMeds({
        data: {
          ...values,
          frequency,
          customFrequencyDates: dates,
        },
        petId,
        medId,
        customerKey,
        hotelItineraryId,
        saveToProfile,
      });
    },
  }),
)(CheckInOutMedsForm);
