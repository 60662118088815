import { connect } from "react-redux";
import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import { getSameDayFilteredOptions } from "dux/frequency/frequencySelectors";
import { getCheckForSameDay } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { setPendingFrequency } from "dux/frequency/actions/frequencyActions";
import { showHideMedicationDetails } from "web/medicationList/actions/medicationListActions";
import {
  getMedicationIsShowingDetailsForType,
  getMedicationIsShowingDetails,
} from "web/medicationList/medicationListSelectors";
import { isFromBooking } from "core/utils/validationUtils/isFromValidation";
import { setMedicationFrequency } from "dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { showAddNewMedication, setNewMedication } from "../actions/newMedicationActions";
import AddButton from "../../common/buttons/addButton";
import { NEW_MEDICATION_ID } from "../newMedicationConstants";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType } = ownProps;
  const medicationId = NEW_MEDICATION_ID;
  return {
    componentId: "BookingMedicationAddButton",
    petId,
    medicationId,
    locationType,
    isHidden:
      !isFromBooking(get(["location", "pathname"], history)) ||
      getMedicationIsShowingDetailsForType(state, {
        medicationId,
        petId,
        locationType,
      }),
    opacity: "1",
    label: "Add Medication",
    fontSize: "regular",
    isLocationTypeShowing: getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType,
    }),
    typesShowing: getMedicationIsShowingDetails(state, { medicationId, petId }),

    // These values are here for the Set Frequency button and modal. When the Food Booking Add button
    // is clicked, we need to know if it is DCC or hotel ,e.g. the isSameDay, and we need options for the
    // setPendingFrequency action below. Once that action is fired, it sets the frequency value and auto checks
    // the date checkbox in the set frequency modal.
    isSameDay: getCheckForSameDay(state),
    options: getSameDayFilteredOptions(state),
  };
};

const mapDispatchToProps = dispatch => ({
  showFeedingForm: ({ medicationId, petId, locationTypes, options, isSameDay }) => {
    dispatch(setNewMedication({ petId }));
    dispatch(showAddNewMedication({ petId }));
    dispatch(
      showHideMedicationDetails({
        medicationId,
        petId,
        locationTypes,
      }),
    );

    // If it is DDC (a single day appointment) we will dispatch this action to auto populate the
    // frequency dropdown and date(s) checkboxes in the Set Frequency modal. and set the frequency value
    // to everyday in state.
    if (isSameDay) {
      dispatch(setPendingFrequency({ frequency: options[0].value }));
      dispatch(
        setMedicationFrequency({
          petId,
          medicationId: NEW_MEDICATION_ID,
          frequency: options[0].value,
        }),
      );
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    const {
      medicationId,
      petId,
      typesShowing,
      locationType,
      isLocationTypeShowing,
      isSameDay,
      options,
    } = propsFromState;
    let locationTypes;
    if (!isLocationTypeShowing) {
      locationTypes = [...typesShowing, locationType];
    }
    propsFromDispatch.showFeedingForm({ medicationId, petId, locationTypes, options, isSameDay });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddButton);
