import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { searchConstants } from "../constants/searchConstants";
import { jobRoleConstants } from "../constants/jobRoleConstants";
import { STORE_NUMBER_BY_JOB_ROLE } from "../constants";

export const getStoreNumber = state => get("persistent.currentStore", state);
export const getAssociateNumber = state =>
  get("persistent.session.oktaUserInfoResponse.associateId", state);

// TODO: Move all to own module and convert to Selectors
export const getAccessToken = state => state.persistent.session.accessToken;
export const getIdToken = state => state.persistent.session.idToken;
export const getClientSessionToken = state => state.persistent.session.clientSessionToken;
export const getSessionToken = state => state.persistent.session.sessionToken;
export const getLoggedInUser = state => state?.persistent?.session?.oktaUserInfoResponse;
export const getRedirectToForgotPin = state => state.persistent.redirectToForgotPin;

// If there is a job code in the state, return it, if not return the default code for an associate.
export const getJobRole = createSelector([getLoggedInUser], user => user && user.accessLevel);

export const getUsername = createSelector(
  [getLoggedInUser],
  user => user && `${user.name} ${user.familyName}`,
);

export const getOktaEmail = createSelector([getLoggedInUser], user => user && user.username);

export const getIsAdmin = createSelector([getLoggedInUser], user => user && user.isAdmin);

export const getIsAccessLevelMocked = createSelector(
  [getLoggedInUser],
  user => user && user.isAccessLevelMocked,
);

// If there is no search component name in state, set it to Customer.
export const getSearchComponentName = state =>
  get("persistent.searchComponentName", state) || searchConstants.CUSTOMER;

// ID of the current Pet
export const getCurrentPet = state => get("persistent.currentPet", state);

export const getPrevSearchComponentName = state => get("persistent.prevSearchComponentName", state);

export const hasManagerPrivileges = createSelector(
  [getJobRole],
  jobRole => jobRole === jobRoleConstants.MANAGER,
);

export const getSourceId = createSelector([getStoreNumber, getJobRole], (storeNumber, jobRole) =>
  jobRole === jobRoleConstants.SRC ? STORE_NUMBER_BY_JOB_ROLE[jobRoleConstants.SRC] : storeNumber,
);

export const getIsUserPinRequired = createSelector(
  [getLoggedInUser],
  user => user && user.isPinRequired,
);

export const getIsSRCAgent = createSelector(
  [getJobRole],
  jobRole => jobRole === jobRoleConstants.SRC,
);

export const getSecureRedirectRoute = state => get("persistent.secureRedirectRoute", state);

export const getAuthenticatedSubscriptionKey = state =>
  state?.persistent?.authenticatedSubscriptionKey;

export const getDisplayName = createSelector(
  [getLoggedInUser],
  user => user && `${user.displayName}`,
);

/**
 * Selector to get time zone of current store
 * @memberOf Selectors.persistent
 * @function
 * @name selectCurrentStoreTimeZone
 * @param {Object} state - redux state
 * @returns {string} timezone
 * @example selectCurrentStoreTimeZone(state)
 */
export const selectCurrentStoreTimeZone = createSelector(
  [state => state?.entities?.salon, getStoreNumber],
  (salon = {}, storeNumber) => {
    return salon[storeNumber]?.TimeZone?.TimeZoneId;
  },
);
