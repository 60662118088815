import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { Heading } from "@petsmart-ui/sparky";
import { DdcPackagesRadioList } from "./DdcPackagesRadioList";
import { LayoutStack } from "@/layout/stack/Stack";
import { SearchableList } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Actions
import { getDdcPackages, setSelectedDdcPackage } from "./ddcPackagesActions";
import {
  POST_CART_V2,
  postCartV2,
  PATCH_CART_PRODUCT_V2,
  patchCartProductV2,
} from "../servicesCartV2/servicesCartV2Actions";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getStoreRegion } from "@/core/selectors/entitiesSelector";
import {
  selectDdcPackages,
  selectSelectedDdcPackage,
  selectDdcPackagesCartPostPayload,
  selectDdcPackagesCartPatchPayload,
} from "./ddcPackagesSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { selectServicesCartIdV2 } from "../servicesCartV2/servicesCartV2Selectors";

// Utils
import isEmpty from "lodash/isEmpty";
import fuzzyFilter from "@/core/utils/arrayUtils/fuzzyFilter";

// Local helpers
export const filterDdcPackages = ({ searchValue, ddcPackages }) => {
  if (!searchValue) {
    return ddcPackages;
  }

  const fuzzySearchPackages = ddcPackages?.map(packages => {
    const groupPackages = packages?.groupPackages.map(groupPackage => {
      return {
        ...groupPackage,
        packages: fuzzyFilter(groupPackage?.packages, "displayName", searchValue),
      };
    });

    const individualPackages = packages?.individualPackages.map(individualPackage => {
      return {
        ...individualPackage,
        packages: fuzzyFilter(individualPackage?.packages, "displayName", searchValue),
      };
    });

    return {
      groupPackages,
      individualPackages,
    };
  });

  return fuzzySearchPackages;
};

/**
 * React view component for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DDCPackagesListColumnComponent = props => {
  const {
    componentId,
    isHidden,
    fetchDdcPackages,
    ddcPackages,
    storeRegion,
    setSelectedPackage,
    selectedDdcPackage,
    isLoading,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [filteredPackages, setFilteredPackages] = useState(ddcPackages);

  useEffect(() => {
    setFilteredPackages(ddcPackages);

    // Clear package selection on unmount
    return () => setSelectedPackage(null);
  }, [ddcPackages]);

  useEffect(() => {
    // To avoid race condition - fetch ddc packages api call is dependent on hours api call
    // fetch ddc packages needs region as dependency
    if (storeRegion) fetchDdcPackages();
  }, [storeRegion]);

  // Handlers
  const handleOnChange = event => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);

    const fuzzySearchPackages = filterDdcPackages({ searchValue, ddcPackages });
    setFilteredPackages(fuzzySearchPackages);
  };

  if (isHidden || !filteredPackages.length) return null;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutBox id={componentId} padding="scale-G1">
        <LayoutStack>
          <LayoutBox padding="scale-0">
            <Heading tagName="h2" size="title">
              Purchase DDC Play & Save
            </Heading>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <SearchableList
              id={componentId}
              placeholder="Search"
              value={searchValue}
              onChange={handleOnChange}
              variant="full"
            >
              <LayoutStack space="scale-G2" style={{ marginTop: "10px" }}>
                {/* Group Passes */}
                <LayoutStack>
                  <Heading tagName="h2" size="title">
                    Group Passes
                  </Heading>
                  <DdcPackagesRadioList
                    packages={filteredPackages[0]?.groupPackages}
                    componentId="DdcPackagesRadioListGroup"
                    onChange={selectedPackage => setSelectedPackage(selectedPackage)}
                    selectedPackageId={selectedDdcPackage}
                    disabled={isLoading}
                  />
                </LayoutStack>

                {/* Individual Passes */}
                <LayoutStack>
                  <Heading tagName="h2" size="title">
                    Individual Passes
                  </Heading>
                  <DdcPackagesRadioList
                    packages={filteredPackages[0]?.individualPackages}
                    componentId="DdcPackagesRadioListIndividual"
                    onChange={selectedPackage => setSelectedPackage(selectedPackage)}
                    selectedPackageId={selectedDdcPackage}
                    disabled={isLoading}
                  />
                </LayoutStack>
              </LayoutStack>
            </SearchableList>
          </LayoutBox>
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

/**
 * Redux Connect function for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesListColumn
 * @example <DDCPackagesListColumn />
 */
export const DDCPackagesListColumn = connect(
  state => {
    const ddcPackages = selectDdcPackages(state);
    const selectedDdcPackage = selectSelectedDdcPackage(state);
    const storeNumber = getStoreNumber(state);
    const storeRegion = getStoreRegion(state, { storeNumber });

    return {
      componentId: "DDCPackagesListColumn",
      isHidden: isEmpty(ddcPackages),
      ddcPackages,
      selectedDdcPackage,
      storeRegion,
      isLoading: createLoadingSelector([POST_CART_V2, PATCH_CART_PRODUCT_V2])(state),
      getCartPostPayload: packageId => selectDdcPackagesCartPostPayload(state, { packageId }),
      getCartPatchPayload: packageId => selectDdcPackagesCartPatchPayload(state, { packageId }),
      cartId: selectServicesCartIdV2(state),
    };
  },
  dispatch => {
    return {
      fetchDdcPackages: () => {
        dispatch(getDdcPackages());
      },
      setSelectedPackage: selectedPackages => {
        dispatch(setSelectedDdcPackage(selectedPackages));
      },
      postServicesCartV2: cart => {
        dispatch(postCartV2(cart));
      },
      patchServicesCartV2: ({ cartId, cart }) => {
        dispatch(patchCartProductV2({ cartId, cart }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { selectedDdcPackage, getCartPostPayload, getCartPatchPayload, cartId } = stateProps;
    const {
      fetchDdcPackages,
      setSelectedPackage,
      postServicesCartV2,
      patchServicesCartV2,
    } = dispatchProps;
    return {
      ...stateProps,
      fetchDdcPackages,
      setSelectedPackage: newPackage => {
        if (!newPackage || newPackage === selectedDdcPackage) return;
        setSelectedPackage(newPackage);

        if (!cartId) {
          // Create new cart with POST
          const cart = getCartPostPayload(newPackage);
          postServicesCartV2(cart);
        } else {
          // Update selected product with PATCH
          const cart = getCartPatchPayload(newPackage);
          patchServicesCartV2({ cartId, cart });
        }
      },
    };
  },
)(DDCPackagesListColumnComponent);
