import { put, takeLatest, call, all, select } from "redux-saga/effects";
import {
  GET_PURCHASED_DDC_PACKAGES,
  getDdcPurchasedPackagesRequest,
  getDdcPurchasedPackagesSuccess,
  getDdcPurchasedPackagesFailure,
} from "./ddcPurchasedPackagesActions";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { getPurchasedPackages } from "@/core/services/associateWebBff/getPurchasedPackages";

function* onGetDdcPurchasedPackages() {
  try {
    yield put(getDdcPurchasedPackagesRequest());

    const customerKey = yield select(getCurrentCustomerKey);
    const response = yield call(getPurchasedPackages, { customerKey });
    const result = response?.data?.results?.[0];
    const packages = normalizeArrayByProperty(result?.packages, "packageId");

    yield put(getDdcPurchasedPackagesSuccess(packages));
  } catch (error) {
    yield put(getDdcPurchasedPackagesFailure(error));
  }
}

function* watchOnGetDdcPurchasedPackages() {
  yield takeLatest(GET_PURCHASED_DDC_PACKAGES, onGetDdcPurchasedPackages);
}

export default function* ddcPurchasedPackagesSaga() {
  yield all([watchOnGetDdcPurchasedPackages()]);
}
