import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ForgotPinSVG from "assets/icons/forgotPin.svg";
import SmileySVG from "assets/icons/smiley.svg";
import { showAuthModal } from "core/actionCreators/authActionCreators";
import { modalTypes } from "core/constants/quickPin";
import { routePaths } from "@/core/constants/routePaths";
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { color } from "../common/styles/theme";

export const QuickPinIconButton = props => {
  const { componentId, iconSrc, navigateTo, onClick, label, isHighlighted } = props;

  const navigate = useNavigate();

  const handleClick = e => {
    if (onClick) onClick(e);
    if (navigateTo) navigate(navigateTo);
  };

  const iconColor = isHighlighted ? "#7cffe2" : color.white0;
  return (
    <Button
      id={componentId}
      onClick={handleClick}
      style={{ background: "none", border: "none" }}
      text={
        <LayoutStack style={{ alignItems: "center", justifyContent: "center" }}>
          <img
            src={iconSrc}
            alt=""
            style={{
              borderRadius: "50%",
              border: `0.25rem solid ${iconColor}`,
              height: "50px",
              width: "50px",
              margin: "0 2em",
            }}
          />
          <TextPassage size="lg" style={{ color: iconColor, textWrap: "nowrap" }}>
            {label}
          </TextPassage>
        </LayoutStack>
      }
    />
  );
};

export const ForgotPinButton = connect(
  () => {
    return {
      componentId: "ForgotPinButton",
      iconSrc: ForgotPinSVG,
      label: "Forgot PIN Code",
    };
  },
  dispatch => ({
    onClick: () => dispatch(showAuthModal({ modalType: modalTypes.AUTHORIZATION_REQUIRED })),
  }),
)(QuickPinIconButton);

export const SwitchUserButton = () => (
  <QuickPinIconButton
    componentId="SwitchUserButton"
    label="Switch User"
    iconSrc={SmileySVG}
    navigateTo={routePaths.SELECT_USER}
    isHighlighted
  />
);
