import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import { flatten, values, get, pipe, uniqBy } from "lodash/fp";

export const getRoomTypes = createSelector([getState], state => {
  return state?.hotelRoomTypes;
});

/**
 * Returns an array of room types irrespective of species and deduped
 * @memberOf Selectors.hotelRoomTypeSelection
 * @function
 * @name selectCombinedRoomTypes
 * @param {Object} state
 * @returns {Array} combined species array of room types, deduped off of roomTypeId
 * @example selectCombinedRoomTypes(state)
 */
export const selectCombinedRoomTypes = createSelector([getRoomTypes], (roomTypes = {}) => {
  return pipe(values, flatten, uniqBy("roomTypeId"))(roomTypes);
});

export const getRoomTypesBySpecies = createSelector(
  [getRoomTypes, (state, props) => props],
  (roomTypes, { speciesId }) => {
    return get(speciesId, roomTypes);
  },
);

export const getRoomTypeSelectorOptions = createSelector([getRoomTypesBySpecies], roomTypes => {
  return roomTypes?.map(({ roomName }) => ({ label: roomName, value: roomName }));
});

export const getPetDefaultRoomType = ({ roomTypes, roomType }) => {
  return roomTypes && roomTypes.find(room => room.roomName === roomType)?.roomTypeId;
};

export const getPetRoomName = ({ roomTypes, petProfile }) => {
  return (
    roomTypes && roomTypes.find(room => room.roomTypeId === petProfile?.defaultRoomType)?.roomName
  );
};
