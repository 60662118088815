import React from "react";
import { connect } from "react-redux";
import { AddonsListAppliedAddonButton } from "dux/hotelAddons/AddonsListAppliedAddonButton";
import { AddonListAddButton } from "dux/hotelAddons/AddonsListAddButton";
import { formatMoney } from "../_utils/moneyUtil";
import { selectEnhancedAddonChildNames } from "./hotelAddonsLIstSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { renderAddonListPricing } from "./HotelAddonsLIst";
import { GenericList } from "../_components/genericList/GenericList";
import { selectCartProductIsMissingDates } from "../servicesCartHotel/servicesCartHotelSelectors";

/**
 * @param {Object} obj - destructed params.
 * @param {string} obj.componentId
 * @param {boolean} obj.isHidden
 * @param {Object} obj.addon
 * @param {Object} obj.appliedAddon
 * @param {Object[]} obj.childAddons
 * @param {Function} obj.renderAddonListPricing
 * @param {Function} obj.setShowingAddonId
 * @return {JSX.Element|null}
 */
export const HotelAddonListItem = ({
  componentId,
  isHidden,
  addonNameComp,
  addonListPricing,
  name,
  childNames,
  onClick = () => {},
  hasError = false,
}) => {
  if (isHidden) return null;

  const AddonName = addonNameComp;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster
        space="scale-0"
        style={{ flexWrap: "nowrap", justifyContent: "space-between" }}
      >
        <AddonName
          componentId={`${componentId}__button`}
          buttonLabel={name}
          onClick={onClick}
          hasError={hasError}
        />

        {addonListPricing}
      </LayoutCluster>

      {childNames}
    </LayoutBox>
  );
};

const renderEnhancedAddonChildren = childNames => (
  <GenericList style={{ margin: 0 }} list={childNames} />
);

// HOTEL ENHANCED ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelEnhancedAddon = connect((state, { petId, addon, appliedAddon }) => {
  const childNames = selectEnhancedAddonChildNames(addon?.addOnId)(state);

  const price = formatMoney(addon.price);
  const qty = appliedAddon?.quantity || "0";
  const appliedPrice = formatMoney(appliedAddon?.finalPrice ?? 0);

  return {
    componentId: `HotelEnhancedAddon-${addon?.addOnId}`,
    addonNameComp: appliedAddon ? AddonsListAppliedAddonButton : AddonListAddButton,
    addonListPricing: renderAddonListPricing(price, qty, appliedPrice),
    name: addon?.addOnName,
    childNames: renderEnhancedAddonChildren(childNames),
    hasError: selectCartProductIsMissingDates({ petId, productId: addon?.addOnId })(state),
  };
})(HotelAddonListItem);
