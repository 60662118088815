import React from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { Button } from "@petsmart-ui/sparky";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { getFoodById } from "web/food/foodsSelector";
import { createLoadingSelector } from "core/selectors/utils";
import isRequiredPetFoodFieldMissing from "web/newFood/utils/isRequiredPetFoodFieldMissing";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { getFoodIsShowingDetailsForType } from "web/foodList/foodListSelectors";
import { preserveFood, setFoodSaveButtonTouched } from "@/web/food/actions/foodsActions";
import { getMedicationById } from "@/web/medication/medicationsSelector";
import { getMedicationIsShowingDetailsForType } from "@/web/medicationList/medicationListSelectors";
import { NEW_MEDICATION_ID } from "@/web/newMedication/newMedicationConstants";
import isRequiredPetMedicationFieldMissing from "@/web/newMedication/utils/isRequiredPetMedicationFieldMissing";
import {
  preserveMedications,
  setMedSaveButtonTouched,
} from "@/web/medication/actions/medicationsActions";
import { putCart, PUT_CART } from "../servicesCart/servicesCartActions";

export const UpdateBookingButtonComponent = ({
  isHidden,
  componentId,
  text,
  disabled,
  variant,
  onClick = () => {},
}) => {
  if (isHidden) return null;

  return (
    <Button id={componentId} text={text} disabled={disabled} variant={variant} onClick={onClick} />
  );
};

export const UpdateBookingFoodButtonContainer = connect(
  (state, { foodId, petId, locationType }) => {
    const foodList = selectFoodList(state);
    const food = getFoodById(state, { foodId, petId });
    const isLoading = createLoadingSelector([PUT_CART])(state);

    // Only compare fields present on the food form to determine if button should be disabled
    const { preserved, ...pendingFood } = food;
    const getFoodPropertiesToCompare = ({ timeOfDay, concreteSchedule, ...foodObj } = {}) => ({
      externalId: foodObj?.externalId,
      type: foodObj?.type,
      name: foodObj?.name,
      amount: foodObj?.amount,
      timeOfDay: Array.isArray(timeOfDay) ? [...timeOfDay].sort() : [],
      specialInstructions: foodObj?.specialInstructions,
      frequency: foodObj?.frequency,
      concreteSchedule: Array.isArray(concreteSchedule) ? [...concreteSchedule].sort() : [],
    });
    const foodHasntBeenUpdated = isEqual(
      getFoodPropertiesToCompare(preserved),
      getFoodPropertiesToCompare(pendingFood),
    );

    const saveToProfUpdated = preserved?.saveToProfile !== pendingFood?.saveToProfile;
    const nothingUpdated = foodHasntBeenUpdated && !saveToProfUpdated;

    return {
      isHidden:
        foodId !== NEW_FOOD_ID
          ? false
          : getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
      componentId: "foodDetails-updateFoodButton",
      text: isLoading ? "Updating..." : "Update",
      disabled: nothingUpdated || isLoading,
      variant: "primary",
      foodHasntBeenUpdated,
      hasErrors: isRequiredPetFoodFieldMissing({ food, foodList, isInBooking: true, petId }),
    };
  },
  (dispatch, { foodId, petId }) => ({
    updateFood: () => dispatch(preserveFood({ foodId, petId })),
    updateCart: () => dispatch(putCart()),
    setSaveTouched: () => dispatch(setFoodSaveButtonTouched({ foodId, petId })),
  }),
  (stateProps, dispatchProps) => {
    const {
      isHidden,
      componentId,
      text,
      disabled,
      variant,
      foodHasntBeenUpdated,
      hasErrors,
    } = stateProps;
    const { updateFood, updateCart, setSaveTouched } = dispatchProps;
    return {
      isHidden,
      componentId,
      text,
      disabled,
      variant,
      onClick: () => {
        setSaveTouched();
        if (hasErrors) return;

        updateFood();
        // Only call the put cart if a value that the cart api cares about was updated
        if (!foodHasntBeenUpdated) updateCart();
      },
    };
  },
)(UpdateBookingButtonComponent);

export const UpdateBookingMedButtonContainer = connect(
  (state, { medicationId, petId, locationType }) => {
    const medicationList = selectMedicationsList(state);
    const medication = getMedicationById(state, { medicationId, petId });
    const isLoading = createLoadingSelector([PUT_CART])(state);

    // Only compare fields present on the med form to determine if button should be disabled
    const { preserved, ...pendingMed } = medication;
    const getMedPropertiesToCompare = ({ timeOfDay, concreteSchedule, ...medObj } = {}) => ({
      externalId: medObj?.externalId,
      name: medObj?.name,
      amount: medObj?.amount,
      timeOfDay: Array.isArray(timeOfDay) ? [...timeOfDay].sort() : [],
      specialInstructions: medObj?.specialInstructions,
      frequency: medObj?.frequency,
      concreteSchedule: Array.isArray(concreteSchedule) ? [...concreteSchedule].sort() : [],
    });
    const medHasntBeenUpdated = isEqual(
      getMedPropertiesToCompare(preserved),
      getMedPropertiesToCompare(pendingMed),
    );

    const saveToProfUpdated = preserved?.saveToProfile !== pendingMed?.saveToProfile;
    const nothingUpdated = medHasntBeenUpdated && !saveToProfUpdated;

    return {
      isHidden:
        medicationId !== NEW_MEDICATION_ID
          ? false
          : getMedicationIsShowingDetailsForType(state, { medicationId, petId, locationType }),
      componentId: "medicationDetails-updateMedicationButton",
      text: isLoading ? "Updating..." : "Update",
      disabled: nothingUpdated || isLoading,
      hasErrors: isRequiredPetMedicationFieldMissing({
        medication,
        medicationList,
        isInBooking: true,
        petId,
      }),
      variant: "primary",
      medHasntBeenUpdated,
    };
  },
  (dispatch, { medicationId, petId }) => ({
    updateMed: () => dispatch(preserveMedications({ medicationId, petId })),
    updateCart: () => dispatch(putCart()),
    setSaveTouched: () => dispatch(setMedSaveButtonTouched({ medicationId, petId })),
  }),
  (stateProps, dispatchProps) => {
    const {
      isHidden,
      componentId,
      text,
      disabled,
      variant,
      medHasntBeenUpdated,
      hasErrors,
    } = stateProps;
    const { updateMed, updateCart, setSaveTouched } = dispatchProps;
    return {
      isHidden,
      componentId,
      text,
      disabled,
      variant,
      onClick: () => {
        setSaveTouched();
        if (hasErrors) return;

        updateMed();
        // Only call the put cart if a value that the cart api cares about was updated
        if (!medHasntBeenUpdated) updateCart();
      },
    };
  },
)(UpdateBookingButtonComponent);
