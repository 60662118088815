/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const POST_CART_V2 = "POST_CART_V2";
export const POST_CART_V2_REQUEST = "POST_CART_V2_REQUEST";
export const POST_CART_V2_SUCCESS = "POST_CART_V2_SUCCESS";
export const POST_CART_V2_FAILURE = "POST_CART_V2_FAILURE";
export const PATCH_CART_PRODUCT_V2 = "PATCH_CART_PRODUCT_V2";
export const PATCH_CART_PRODUCT_V2_REQUEST = "PATCH_CART_PRODUCT_V2_REQUEST";
export const PATCH_CART_PRODUCT_V2_SUCCESS = "PATCH_CART_PRODUCT_V2_SUCCESS";
export const PATCH_CART_PRODUCT_V2_FAILURE = "PATCH_CART_PRODUCT_V2_FAILURE";

export const SET_SERVICES_CART_V2 = "SET_SERVICES_CART_V2";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const postCartV2 = cart => ({
  type: POST_CART_V2,
  cart,
});

export const postCartV2Request = () => ({
  type: POST_CART_V2_REQUEST,
});

export const postCartV2Success = () => ({
  type: POST_CART_V2_SUCCESS,
});

export const postCartV2Failure = error => ({
  type: POST_CART_V2_FAILURE,
  error,
});

export const setServicesCartV2 = cart => ({
  type: SET_SERVICES_CART_V2,
  cart,
});

export const patchCartProductV2 = ({ cartId, cart }) => ({
  type: PATCH_CART_PRODUCT_V2,
  cartId,
  cart,
});

export const patchCartProductV2Request = () => ({
  type: PATCH_CART_PRODUCT_V2_REQUEST,
});

export const patchCartProductV2Success = () => ({
  type: PATCH_CART_PRODUCT_V2_SUCCESS,
});

export const patchCartProductV2Failure = error => ({
  type: PATCH_CART_PRODUCT_V2_FAILURE,
  error,
});
