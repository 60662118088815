import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SET_PIN_INITIAL_FORM_VALUES } from "@/core/constants/quickPin";
import { routePaths } from "@/core/constants/routePaths";
import { useForm } from "@/dux/utils/formUtils/useForm";
import { validateConfirmPin, validatePin } from "@/core/utils/validationUtils/pinValidation";
import {
  QuickPinForm,
  QuickPinInput,
  QuickPinPage,
  QuickPinProfileImg,
  QuickPinSubHeading,
  QuickPinSubmitBtn,
} from "./quickPinComponents";

export const SetPinComponent = props => {
  const {
    componentId,
    isRedirectedToSetPin,
    isLoading,
    setOrUpdateUserPin,
    invalidPinError,
    clearInvalidPinError,
    clearRedirectToSetPin,
  } = props;

  const {
    values: { pin, confirmPin },
    touched,
    setFormInput,
  } = useForm(SET_PIN_INITIAL_FORM_VALUES);

  const pinError = useMemo(() => validatePin(pin), [pin]);
  const confirmPinError = useMemo(() => validateConfirmPin({ confirmPin, pin }), [pin, confirmPin]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isRedirectedToSetPin) {
      navigate(routePaths.LOGIN);
    }

    return () => {
      clearInvalidPinError();
      clearRedirectToSetPin();
    };
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    if (pinError || confirmPinError) return;
    setOrUpdateUserPin({ pin });
  };

  return (
    <QuickPinPage componentId={componentId}>
      <QuickPinProfileImg />
      <QuickPinSubHeading>Please choose your new PIN code</QuickPinSubHeading>
      <QuickPinForm
        onSubmit={handleSubmit}
        error={
          (touched.pin && pinError) || (touched.confirmPin && confirmPinError) || invalidPinError
        }
      >
        <QuickPinInput
          id={`${componentId}__pinInput`}
          placeholder="Personal PIN Code"
          name="pin"
          onChange={setFormInput}
          value={pin}
        />
        <QuickPinInput
          id={`${componentId}__confirmPinInput`}
          placeholder="Confirm PIN"
          name="confirmPin"
          onChange={setFormInput}
          value={confirmPin}
        />
        <QuickPinSubmitBtn id={`${componentId}__submit`} isLoading={isLoading} />
      </QuickPinForm>
    </QuickPinPage>
  );
};
