import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";

// Components
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { HotelItineraryHistoryFilterButtons } from "../itineraryHistoryListFilterButtons/itineraryHistoryListFilterButtons";
import { ItineraryHistoryListPagination } from "../itineraryHistoryListPagination/itineraryHistoryListPagination";
import LoadingWrapper from "../../web/common/LoadingWrapper";
import PetParentProfileColumn from "../../web/petParentProfile/PetParentProfileColumn";
import { DdcPackagesHistoryListPagination } from "../ddcPackagesHistoryListPagination/DdcPackagesHistoryListPagination.js";

// Actions
import { GET_HOTEL_ROOM_TYPES } from "../hotelRoomTypes/hotelRoomTypesActions";
import { GET_ITINERARY_HISTORY_LIST, getItineraryHistoryList } from "./itineraryHistoryListActions";
import customersActionTypes from "../../core/actionTypes/customersActionTypes";
import petsActionTypes from "../../core/actionTypes/petsActionTypes";
import {
  getDdcPurchasedPackages,
  GET_PURCHASED_DDC_PACKAGES,
} from "../ddcPurchasedPackages/ddcPurchasedPackagesActions";

// Selectors
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "@/core/selectors/entitiesSelector";
import { selectFilteredItineraryHistoryList } from "./itineraryHistoryListSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { selectInActiveDdcPackageIds } from "../ddcPurchasedPackages/ddcPurchasedPackagesSelectors";
import getIsDdcPackagesWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ddcPackages/getIsDdcPackagesWorkflowFeatureFlagHidden";

// Utils
import { createLoadingSelector } from "../../core/selectors/utils";

// Constants
import { engagementTypeIds } from "web/setSystemType/constants/setSystemTypeConstants";

// MAIN COMPONENT ----------------------------------------------------------------------------------------------------
const ItineraryHistoryList = props => {
  const {
    isHidden,
    isDdcPassFeatureHidden,
    componentId,
    isCustomerLoaded,
    isLoading,
    isLoadingDdcPackages,
    disabled,
    loadItineraryHistory,
    loadDdcPackagesHistory,
    hasItineraryHistory,
    inActiveDdcPurchasedPackageIds,
    diComp,
    compName,
  } = props;

  const FilterButtons = diComp[compName];

  // Local state
  const [showAppointments, setShowAppointments] = useState(true);

  useEffect(() => {
    if (isCustomerLoaded) loadItineraryHistory();
  }, [isCustomerLoaded]);

  useEffect(() => {
    if (!isDdcPassFeatureHidden && inActiveDdcPurchasedPackageIds?.length < 0)
      loadDdcPackagesHistory();
  }, []);

  if (!isHidden) {
    return (
      <LayoutBox id={componentId}>
        <PetParentProfileColumn
          title="Appointments (all pets)"
          disabled={disabled}
          noRightBorder
          shadow
        >
          <LayoutStack space="scale-G1">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <Button
                disabled={showAppointments}
                size="lg"
                variant="link"
                text="Appointments"
                onClick={() => setShowAppointments(true)}
              />
              {!isDdcPassFeatureHidden && (
                <Button
                  disabled={!showAppointments}
                  size="lg"
                  variant="link"
                  text="History"
                  onClick={() => setShowAppointments(false)}
                />
              )}
            </LayoutCluster>

            {showAppointments ? (
              <>
                {/* Filter Pills */}
                <FilterButtons />

                <LoadingWrapper isLoading={isLoading}>
                  {hasItineraryHistory && !isLoading && (
                    // Paginated List
                    <ItineraryHistoryListPagination />
                  )}
                  {!hasItineraryHistory && !isLoading && (
                    <TextPassage size="lg">
                      <b>No future or past appointments</b>
                    </TextPassage>
                  )}
                </LoadingWrapper>
              </>
            ) : (
              <LoadingWrapper isLoading={isLoadingDdcPackages}>
                {inActiveDdcPurchasedPackageIds?.length > 0 ? (
                  // Paginated List
                  <DdcPackagesHistoryListPagination
                    inActiveDdcPurchasedPackageIds={inActiveDdcPurchasedPackageIds}
                  />
                ) : (
                  <TextPassage size="lg">
                    <b>No DDC inactive packages history</b>
                  </TextPassage>
                )}
              </LoadingWrapper>
            )}
          </LayoutStack>
        </PetParentProfileColumn>
      </LayoutBox>
    );
  }

  return null;
};

// HOTEL ITINERARY HISTORY CONTAINER ---------------------------------------------------------------------------------
export const HotelItineraryHistoryColumn = connect(
  (state, { customerKey }) => ({
    isHidden: getIsHotelWorkflowFeatureFlagHidden(state),
    isDdcPassFeatureHidden: getIsDdcPackagesWorkflowFeatureFlagHidden(state),
    componentId: "HotelItineraryHistoryColumn",
    isCustomerLoaded: !!getCustomer(state, { customerKey }),
    isLoading: createLoadingSelector([
      GET_ITINERARY_HISTORY_LIST,
      GET_HOTEL_ROOM_TYPES,
      petsActionTypes.LOAD_PETS_BY_CUSTOMER,
      customersActionTypes.LOAD_CUSTOMER,
    ])(state),
    isLoadingDdcPackages: createLoadingSelector([GET_PURCHASED_DDC_PACKAGES])(state), // work on using ddc endpoint todo
    // Using customer key from URL params instead of getCurrentCustomerKey(state) because this component
    // renders before the key is updated in the store. When using the key from state and switching between
    // different pet parent profiles the API would get called with the previous customer key.
    customerKey,
    disabled: !getCurrentCustomerKey(state),
    hasItineraryHistory: selectFilteredItineraryHistoryList(state)?.length > 0,
    inActiveDdcPurchasedPackageIds: Object.values(selectInActiveDdcPackageIds(state)),
    diComp: { filterButtons: HotelItineraryHistoryFilterButtons },
    compName: "filterButtons",
  }),

  dispatch => {
    const productFamilyID = `${engagementTypeIds.DAY_CAMP},${engagementTypeIds.DAY_CARE},${engagementTypeIds.OVERNIGHT_BOARDING}`;

    return {
      dispatchGetDdcPackagesHistoryList: () => dispatch(getDdcPurchasedPackages()),
      dispatchGetItineraryHistoryList: customerKey => {
        dispatch(getItineraryHistoryList({ customerKey, productFamilyID }));
      },
    };
  },

  (stateProps, dispatchProps) => {
    const {
      isHidden,
      isDdcPassFeatureHidden,
      componentId,
      isCustomerLoaded,
      isLoading,
      isLoadingDdcPackages,
      disabled,
      customerKey,
      hasItineraryHistory,
      inActiveDdcPurchasedPackageIds,
      diComp,
      compName,
    } = stateProps;
    const { dispatchGetItineraryHistoryList, dispatchGetDdcPackagesHistoryList } = dispatchProps;

    return {
      isHidden,
      isDdcPassFeatureHidden,
      componentId,
      isCustomerLoaded,
      isLoading,
      isLoadingDdcPackages,
      disabled,
      hasItineraryHistory,
      inActiveDdcPurchasedPackageIds,
      diComp,
      compName,
      loadItineraryHistory: () => {
        dispatchGetItineraryHistoryList(customerKey);
      },
      loadDdcPackagesHistory: () => {
        dispatchGetDdcPackagesHistoryList();
      },
    };
  },
)(ItineraryHistoryList);
