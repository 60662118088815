import { connect } from "react-redux";
import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import { showAddNewMedication, setNewMedication } from "../actions/newMedicationActions";
import AddButton from "../../common/buttons/addButton";
import { getMedicationCount } from "../../medicationCount/medicationCountSelector";
import {
  NEW_MEDICATION_ID,
  MEDICATION_MAX_COUNT,
  medicationTypes,
} from "../newMedicationConstants";
import { showHideMedicationDetails } from "../../medicationList/actions/medicationListActions";
import {
  getMedicationIsShowingDetails,
  getMedicationIsShowingDetailsForType,
} from "../../medicationList/medicationListSelectors";
import {
  isFromBooking,
  isFromCheckInOut,
} from "../../../core/utils/validationUtils/isFromValidation";

const mapStateToProps = (state, ownProps) => {
  const { petId, buttonDisabled } = ownProps;
  const medicationCount = getMedicationCount(state, { petId });
  const medicationId = NEW_MEDICATION_ID;
  const locationType = medicationTypes.PROFILE;
  return {
    componentId: "BookingMedicationAddButton",
    buttonDisabled,
    petId,
    medicationId,
    locationType,
    isHidden: isFromCheckInOut(get(["location", "pathname"], history))
      ? false
      : isFromBooking(get(["location", "pathname"], history)) ||
        medicationCount >= MEDICATION_MAX_COUNT,
    opacity: "1",
    label: "Add Medication",
    fontSize: "regular",
    isLocationTypeShowing: getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType,
    }),
    typesShowing: getMedicationIsShowingDetails(state, { medicationId, petId }),
  };
};

const mapDispatchToProps = dispatch => ({
  showFeedingForm: ({ medicationId, petId, locationTypes }) => {
    dispatch(setNewMedication({ petId }));
    dispatch(showAddNewMedication({ petId }));
    dispatch(
      showHideMedicationDetails({
        medicationId,
        petId,
        locationTypes,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    const {
      medicationId,
      petId,
      locationType,
      typesShowing,
      isLocationTypeShowing,
    } = propsFromState;
    let locationTypes;
    if (!isLocationTypeShowing) {
      locationTypes = [...typesShowing, locationType];
    }
    propsFromDispatch.showFeedingForm({ medicationId, petId, locationTypes });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddButton);
