import React, { useState } from "react";
import { Text } from "@prism/psm-ui-components";
import HotelCartAddonArrow from "dux/hotelCartAddons/HotelCartAddonArrow";
import { connect } from "react-redux";
import {
  HotelBookingCartAddonQtyCartButton,
  HotelCartAddonQtyCartButton,
} from "dux/hotelAddonsEditCartQuantityFlyout/HotelAddonsQtyCartButton";
import { selectIsOvernight } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import {
  HotelAddonPriceChange,
  HotelBookingAddonPriceChange,
} from "dux/priceChange/PriceChangeComponent";
import {
  HotelCartAddonFrequencyButton,
  HotelBookingCartAddonFrequencyButton,
} from "@/dux/HotelAddonsCartQtyButton/HotelAddonsCartQtyButton";
import {
  HotelBookingCartAddonSpecialPrice,
  HotelCheckInOutCartAddonSpecialPrice,
} from "@/dux/_components/petCard/HotelSpecials";
import { selectCurrentHotelEngagement } from "dux/hotelEngagements/hotelEngagementSelectors";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { LayoutBox } from "@/layout/box/Box";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { HotelCartAddonPrice } from "@/dux/cartPrice/cartPrice";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  HotelBookingCartAddonInstructions,
  HotelCheckInOutCartAddonInstructions,
} from "./HotelCartAddonInstructions";
import { Heading } from "@petsmart-ui/sparky";
import {
  HotelBookingCartIncludedAddons,
  HotelCheckInOutCartIncludedAddons,
} from "./HotelCartIncludedAddons";

const EmptyComponent = () => <></>;

export const HotelCartAddonComponent = props => {
  const { componentId, isHidden, label, addonId, diComp, petId } = props;

  // To set if addon is showing
  const [isExpanded, setIsExpanded] = useState(false);

  const QtyButton = diComp?.qtyButton || EmptyComponent;
  const Price = diComp?.price || EmptyComponent;
  const PriceChange = diComp?.priceChange || EmptyComponent;
  const Special = diComp?.special || EmptyComponent;
  const Instructions = diComp?.instructions || EmptyComponent;
  const IncludedProducts = diComp?.includedProducts || EmptyComponent;

  if (isHidden) return null;

  return (
    <LayoutBox id={`${componentId}-${addonId}`}>
      <LayoutStack style={{ justifyContent: "left" }}>
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <LayoutBox padding="scale-0">
              <LayoutCluster>
                <Heading size="body-lg-bold" tagName="span">
                  {label}
                </Heading>
                <HotelCartAddonArrow
                  componentID={`${componentId}__expandIcon`}
                  isExpanded={isExpanded}
                  clickHandler={() => setIsExpanded(!isExpanded)}
                />
              </LayoutCluster>
            </LayoutBox>

            <LayoutBox padding="scale-0">
              <LayoutCluster>
                <QtyButton petId={petId} addonId={addonId} />
                <Price petId={petId} addonId={addonId} />
                <PriceChange petId={petId} addonId={addonId} />
              </LayoutCluster>
            </LayoutBox>
          </LayoutCluster>

          <LayoutBox style={{ padding: "0 0.25rem" }}>
            <Special petId={petId} addonId={addonId} />
          </LayoutBox>

          {isExpanded && <Instructions petId={petId} addonId={addonId} />}

          <IncludedProducts petId={petId} addonId={addonId} isExpanded={isExpanded} />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCartAddon = connect((state, { addon }) => {
  const { addOnName, addOnProductNumber, isEnhancedAddOn } = addon;
  const petId = getCurrentPet(state);
  const engagementType = selectCurrentHotelEngagement(state, { petId })?.engagementType;
  const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;

  return {
    componentId: "HotelCartAddon",
    label: addOnName,
    addonId: addOnProductNumber,
    petId,
    isHidden: !addOnProductNumber || !addOnName,
    diComp: {
      qtyButton: isOvernight ? HotelCartAddonFrequencyButton : HotelCartAddonQtyCartButton,
      price: HotelCartAddonPrice,
      priceChange: HotelAddonPriceChange,
      special: HotelCheckInOutCartAddonSpecialPrice,
      instructions: HotelCheckInOutCartAddonInstructions,
      includedProducts: isEnhancedAddOn && HotelCheckInOutCartIncludedAddons,
    },
  };
})(HotelCartAddonComponent);

export const HotelCartIncludedAddon = connect((state, { addon }) => {
  const { addOnName, addOnProductNumber } = addon || {};
  return {
    componentId: "HotelCartIncludedAddon",
    label: addOnName,
    addonId: addOnProductNumber,
    petId: getCurrentPet(state),
    isHidden: !addOnProductNumber || !addOnName,
    diComp: {
      instructions: HotelCheckInOutCartAddonInstructions,
    },
  };
})(HotelCartAddonComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddon = connect((state, { addon }) => {
  const { productId, productName, pricing, petId, isEnhancedAddOn } = addon;
  const isOvernight = selectIsOvernight(state);

  return {
    componentId: "HotelBookingCartAddon",
    label: productName,
    addonId: productId,
    petId,
    isHidden: !productId || !productName,
    diComp: {
      qtyButton: isOvernight
        ? HotelBookingCartAddonFrequencyButton
        : HotelBookingCartAddonQtyCartButton,
      price: () => <Text>${pricing?.finalPrice ?? 0}</Text>,
      priceChange: HotelBookingAddonPriceChange,
      special: HotelBookingCartAddonSpecialPrice,
      instructions: HotelBookingCartAddonInstructions,
      includedProducts: isEnhancedAddOn && HotelBookingCartIncludedAddons,
    },
  };
})(HotelCartAddonComponent);

export const HotelBookingCartIncludedAddon = connect((state, { addon }) => {
  const { productId, productName, petId } = addon || {};
  return {
    componentId: "HotelBookingCartIncludedAddon",
    label: productName,
    addonId: productId,
    petId,
    isHidden: !productId || !productName,
    diComp: {
      instructions: HotelBookingCartAddonInstructions,
    },
  };
})(HotelCartAddonComponent);
