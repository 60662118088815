import React from "react";
import { connect } from "react-redux";
import SelectWithWrapper from "../common/SelectWithWrapper";
import { setFoodName } from "./actions/setFoodNameActions";
import {
  getPetFoodOptions,
  getFoodNameExternalIdById,
  getFoodNameByExternalId,
} from "./foodNameSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { OTHER_FOOD_NAME } from "../newFood/newFoodConstants";
import { getFoodIsDiscontinuedById, selectIsFoodSaveTouchedById } from "../food/foodsSelector";

export const FoodNameField = ({ foodIndex, ...props }) => {
  return (
    <SelectWithWrapper
      {...props}
      title={`Food ${foodIndex} name`}
      name="foodName"
      className="foodName"
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId } = ownProps;
  const value = getFoodNameExternalIdById(state, { foodId, petId });
  const currentFoodName = getFoodNameByExternalId(state, { foodId, petId });
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  const discontinuedOption = [{ value, label: currentFoodName }];
  const nameOptions = getPetFoodOptions(state, { foodId, petId });

  return {
    foodId,
    petId,
    foodIndex,
    options: isDiscontinued ? discontinuedOption : nameOptions,
    componentId: `foodDetails-foodName-${foodIndex}`,
    value,
    currentFoodName,
    error: !value && REQUIRED_FIELD_ERROR_MESSAGE,
    disabled: isDiscontinued,
    showError: selectIsFoodSaveTouchedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodType: ({ option, foodId, currentFoodName, petId }) => {
    const externalId = +option?.value;
    if (currentFoodName === OTHER_FOOD_NAME && option?.label !== OTHER_FOOD_NAME) {
      dispatch(setFoodName({ name: option?.label, externalId, foodId, petId }));
    } else if (option?.label === OTHER_FOOD_NAME) {
      dispatch(setFoodName({ name: "", externalId, foodId, petId }));
    } else {
      dispatch(setFoodName({ name: option?.label, externalId, foodId, petId }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: option => {
    const { foodId, petId, currentFoodName } = propsFromState;
    propsFromDispatch.onChangeFoodType({ option, foodId, currentFoodName, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FoodNameField);
