import React from "react";
import { isEmpty } from "lodash/fp";
import styled from "styled-components";
import Select from "../common/Select";
import Img from "../common/Img";
import InitialsCircle from "../common/InitialsCircle";
import { fontSizes } from "../common/styles/responsive/fonts";
import FormInput from "../common/FormInput";
import { NEW_PET_ID } from "../../core/constants";
import { device } from "web/common/styles/responsive/devices";

const StyledFormHeader = styled.div`
  display: ${({ show = true }) => (show ? "flex" : "none")};
  align-items: center;
  margin-bottom: ${props => (props.isPet ? "0" : "2.3125rem")};
  grid-column: 1 / -1;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  cursor: ${props => (props.isPet ? "pointer" : "auto")};
  ${props => props.isPetActive === false && props.isPet && `opacity: 0.5; pointer-events: none;`};

  @media ${device.laptop} {
    font-size: 1.2857rem;
  }

  // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    font-size: ${fontSizes.extraBig};
  }
`;

const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  width: 8rem;
`;

const AddPet = styled.span`
  cursor: pointer;
  margin-right: 0.625rem;
  font-weight: bold;
`;

const StyledImage = styled(({ isPet, isPetActive, ...rest }) => <Img {...rest} />)`
  margin-right: 1.875rem;
  ${props =>
    props.isPetActive === false &&
    props.isPet &&
    `
    opacity: 0.5;
   {
        pointer-events: none;
    }
`};

  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.laptop} {
    display: fex;
  }
`;

const options = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

class FormHeaderComponent extends React.Component {
  nameInputRef = React.createRef();
  state = {
    isEditing: false,
    nameInputFocused: false,
  };

  componentDidMount() {
    const { petName, isPet } = this.props;
    if (isPet && !petName) {
      this.setState({
        isEditing: true,
      });
      this.focusOnPetNameField();
    }
  }

  componentDidUpdate(prevProps) {
    const { isPet, petName, petId } = this.props;

    const { isEditing } = this.state;
    if (isPet && !petName && !isEditing) {
      this.setState({
        isEditing: true,
      });
    }

    if (isPet && petName && isEditing && prevProps.petId !== petId) {
      this.setState({
        isEditing: false,
      });
    }

    if (this.shouldFocusOnPetNameField()) {
      this.focusOnPetNameField();
    }

    const shouldClearNameInputFocused = prevProps.petId !== petId;
    if (shouldClearNameInputFocused) {
      this.setState({ nameInputFocused: false });
    }
  }

  shouldFocusOnPetNameField = () => {
    const { isEditing, nameInputFocused } = this.state;
    const { petId } = this.props;

    return isEditing && petId === NEW_PET_ID && !nameInputFocused;
  };

  focusOnPetNameField = () => {
    const nameInputRef = this.nameInputRef.current;
    if (nameInputRef) {
      nameInputRef.querySelector("#petName").focus();
      this.setState({ nameInputFocused: true });
    }
  };

  handleNameChangeBlur = () => {
    const { onSubmit, values, errors, petName } = this.props;
    this.setState({ isEditing: false });

    const shouldUpdatePet = isEmpty(errors);
    if (petName && shouldUpdatePet) {
      onSubmit({ formValues: values });
    }
  };

  handleNameChangeKeyPress = e => {
    if (e.key === "Enter") {
      this.handleNameChangeBlur();
    }
  };

  render() {
    const {
      componentId = "formHeader",
      photo,
      fullName,
      petName,
      onNameChange,
      isPet,
      show,
      isPetActive,
      isActive,
      onAddPet,
      isAdding,
      showAddPet,
      onSetIsActive,
      name,
      isHidden,
    } = this.props;
    const { isEditing } = this.state;

    if (isHidden) return null;
    return (
      <StyledFormHeader id={componentId} show={show} isPet={isPet}>
        {photo && <StyledImage isPet={isPet} isPetActive={isActive} src={photo} />}
        {!isPet && fullName && <InitialsCircle displayName={fullName} margin={"0 1.65rem 0 0"} />}
        {isEditing ? (
          <FormInput
            id={`${componentId}__input`}
            modifier="name"
            id="petName"
            name={name}
            alignCenter
            innerRef={this.nameInputRef}
            value={petName}
            onChange={e => {
              onNameChange(e.target.value);
            }}
            onBlur={this.handleNameChangeBlur}
            onKeyPress={this.handleNameChangeKeyPress}
            label="*Pet Name"
          />
        ) : (
          <Title
            isPetActive={isActive}
            onClick={() => {
              if (isPet) {
                this.setState({ isEditing: true });
              }
            }}
            isPet={isPet}
          >
            {fullName}
          </Title>
        )}
        <LeftActions>
          {showAddPet && !isAdding && <AddPet onClick={onAddPet}>New pet</AddPet>}
          {!isAdding ? (
            <StyledSelect
              id={`${componentId}__select`}
              disabled={isPet && !petName}
              options={options}
              value={options.find(option => option.value === isActive)}
              onChange={({ value }) => onSetIsActive(value)}
            />
          ) : null}
        </LeftActions>
      </StyledFormHeader>
    );
  }
}

export default FormHeaderComponent;
