/**
 * Primary responsibility is to take a object that represents css ( example {position:"absolute",top:0} )
 * and turns it into a string that styled components library can use e.g.
 *
 * @export setStyleObjToStyledCompReadyString
 * @param {*} { styleObj }
 * @returns
 */
export default function setStyleObjToStyledCompReadyString({ styleObj } = {}) {
  // styleObject is an object literal and must be converted to a string to manipulate.
  const stringifiedObj = JSON.stringify(styleObj);

  // We need to remove all non word or digit characters such as brackets {}, Quote "", but want to leave the semicolon :
  const specialCharRemoved = stringifiedObj && stringifiedObj.replace(/[^a-zA-Z:,0-9 ]/g, "");

  // Each obj property has a coma at teh end of each, however css requires a semi colon at the end of each statement,
  const comaToSemiColon = specialCharRemoved && specialCharRemoved.replace(",", ";");

  // just add a semi colon to end of string so it completes the final css statement.
  return `${comaToSemiColon};`;
}
