import { getProps, getState } from "@/core/selectors/commonSelector";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";
import { createSelector } from "reselect";

/**
 * Selector to get available ddc packages
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectDdcPackages
 * @param {Object} state
 * @returns {Array} Available DDC packages
 * @example selectDdcPackages(state)
 */
export const selectDdcPackages = createSelector([getState], state => {
  return state?.ddcPackages?.ddcPackages || [];
});

/**
 * Selector to select selected ddc package
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectSelectedDdcPackage
 * @param {Object} state
 * @returns {object} Selected DDC packages
 * @example selectSelectedDdcPackage(state)
 */
export const selectSelectedDdcPackage = createSelector([getState], state => {
  return state?.ddcPackages?.selectedPackage;
});

/**
 * Selector to select selected ddc package data
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectDdcPackageById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {object} package object
 * @example selectDdcPackageById(state, { packageId })
 */
export const selectDdcPackageById = createSelector(
  [selectDdcPackages, getProps],
  ([allPackages], { packageId }) => {
    const groupPackages = allPackages?.groupPackages?.map(({ packages }) => packages);
    const individualPackages = allPackages?.individualPackages?.map(({ packages }) => packages);
    const flattenedPackages = flattenArray([groupPackages, individualPackages]);

    return flattenedPackages?.find(pack => pack?.packageId?.toString() === packageId?.toString());
  },
);

/**
 * Selector to build cart V2 post payload for DDC Packages
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectDdcPackagesCartPostPayload
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {object} package object
 * @example selectDdcPackagesCartPostPayload(state, { packageId })
 */
export const selectDdcPackagesCartPostPayload = createSelector(
  [selectDdcPackageById, getCurrentCustomerKey, getStoreNumber, getProps],
  (selectedPackage, customerKey, storeNumber, { packageId }) => {
    const sku = selectedPackage?.sku;
    if (!sku || !customerKey) return {};

    return {
      customerKey,
      products: [{ petId: null, productId: packageId, sku, quantity: 1 }],
      lineOfBusiness: "Hotel",
      storeNumber,
      additionalData: { salon: null, hotel: null, training: null },
    };
  },
);

/**
 * Selector to build cart V2 patch payload for updating the selected product
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectDdcPackagesCartPatchPayload
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {object}
 * @example selectDdcPackagesCartPatchPayload(state, { packageId })
 */
export const selectDdcPackagesCartPatchPayload = createSelector(
  [selectDdcPackageById, getCurrentCustomerKey, getStoreNumber, getProps],
  (selectedPackage, customerKey, storeNumber, { packageId }) => {
    const sku = selectedPackage?.sku;
    if (!sku || !customerKey) return {};

    return {
      customerKey,
      product: { petId: null, productId: packageId, sku, quantity: 1 },
      lineOfBusiness: "Hotel",
      storeNumber,
    };
  },
);
