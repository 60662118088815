import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import {
  getPendingFrequency,
  getPendingFrequencyAddonDates,
  getPendingFrequencyFoodDates,
  getPendingFrequencyMedicationDates,
  getPendingFrequencyType,
  getSameDayFilteredOptions,
} from "dux/frequency/frequencySelectors";
import {
  getCheckForSameDay,
  selectDateRangeForFrequency,
} from "@/dux/frequency/dateRangeForFrequencySelectors";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "core/constants/validationErrors";
import {
  setCachedFrequency,
  setPendingFrequency,
  setPendingFrequencyAddonDates,
  setPendingFrequencyFoodDates,
  setPendingFrequencyMedicationDates,
} from "dux/frequency/actions/frequencyActions";
import SelectWithWrapper from "web/common/SelectWithWrapper";

export const FrequencySelectionView = props => {
  const { componentId, frequencyType } = props;

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-8">{FREQUENCY_SELECTION[frequencyType]}</Layout.Stack>
    </Layout.Box>
  );
};

/**
 * Helper function for the food and meds frequency selection.
 * @param {String} value - selection option
 * @param {Array} pendingAppointmentDates - all dates for multi stay day.
 * @param {Array} pendingFrequencyDates - frequency specific dates.
 * @returns {Array}
 */
export const setFrequencyDates = (value, pendingAppointmentDates, pendingFrequencyDates) => {
  if (value === frequencyConstants.DAILY) {
    return pendingAppointmentDates;
  }
  if (value === frequencyConstants.EOD1) {
    return pendingAppointmentDates.filter((pendingDate, index) => index % 2 === 0);
  }
  if (value === frequencyConstants.EOD2) {
    return pendingAppointmentDates.filter((pendingDate, index) => index % 2 !== 0);
  }
  if (value === frequencyConstants.FIRST_DAY) {
    return [pendingAppointmentDates[0]];
  }
  if (value === frequencyConstants.LAST_DAY) {
    return [pendingAppointmentDates[pendingAppointmentDates.length - 1]];
  }
  if (value === frequencyConstants.MANUAL) {
    return pendingFrequencyDates;
  }
};

/**
 * Redux Container for the Frequency Selection View.
 */
export const FrequencySelection = connect(state => {
  return {
    componentId: "FrequencySelection",
    frequencyType: getPendingFrequencyType(state),
  };
})(FrequencySelectionView);

/**
 * Redux Container fr the Food Frequency Selection
 */
export const FrequencyFoodSelection = connect(
  state => {
    const isSameDay = getCheckForSameDay(state);
    const pendingAppointmentDates = selectDateRangeForFrequency(state);
    const pendingFrequencyFoodDates = getPendingFrequencyFoodDates(state);
    const value = isSameDay ? frequencyConstants.DAILY : getPendingFrequency(state);

    return {
      componentId: "FrequencyFoodSelection",
      title: "Food Frequency",
      options: getSameDayFilteredOptions(state),
      value,
      defaultValue: isSameDay ? frequencyConstants.DAILY : null,
      error: !value && REQUIRED_FIELD_ERROR_MESSAGE,
      display: "block",
      setLocalValue: true,
      disabled: isSameDay,

      pendingFrequencyFoodDates,
      pendingAppointmentDates,
    };
  },

  dispatch => {
    return {
      handleOnChange: ({
        option,
        cachedFrequency,
        pendingAppointmentDates,
        pendingFrequencyFoodDates,
      }) => {
        dispatch(setCachedFrequency({ cachedFrequency }));
        dispatch(setPendingFrequency({ frequency: option.value }));

        const dates = setFrequencyDates(
          option.value,
          pendingAppointmentDates,
          pendingFrequencyFoodDates,
        );
        dispatch(setPendingFrequencyFoodDates({ dates }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      pendingFrequencyFoodDates,
      pendingAppointmentDates,
      disabled,
    } = mapProps;

    const { handleOnChange } = dispatchProps;

    // control what props get passed to the view
    return {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      disabled,

      // actions to pass to view
      onChange: option => {
        handleOnChange({
          option,
          cachedFrequency: value,
          pendingAppointmentDates,
          pendingFrequencyFoodDates,
        });
      },
    };
  },
)(SelectWithWrapper);

/**
 * Redux Container fr the Medication Frequency Selection
 */
export const FrequencyMedicationSelection = connect(
  state => {
    const isSameDay = getCheckForSameDay(state);
    const pendingAppointmentDates = selectDateRangeForFrequency(state);
    const pendingFrequencyMedicationDates = getPendingFrequencyMedicationDates(state);
    const value = isSameDay ? frequencyConstants.DAILY : getPendingFrequency(state);

    return {
      componentId: "FrequencyMedicationSelection",
      title: "Medication Frequency",
      options: getSameDayFilteredOptions(state),
      value,
      defaultValue: isSameDay ? frequencyConstants.DAILY : null,
      disabled: isSameDay,
      error: !value && REQUIRED_FIELD_ERROR_MESSAGE,
      display: "block",
      setLocalValue: true,

      pendingFrequencyMedicationDates,
      pendingAppointmentDates,
    };
  },

  dispatch => {
    return {
      handleOnChange: ({
        option,
        cachedFrequency,
        pendingAppointmentDates,
        pendingFrequencyMedicationDates,
      }) => {
        dispatch(setCachedFrequency({ cachedFrequency }));
        dispatch(setPendingFrequency({ frequency: option.value }));

        const dates = setFrequencyDates(
          option.value,
          pendingAppointmentDates,
          pendingFrequencyMedicationDates,
        );
        dispatch(setPendingFrequencyMedicationDates({ dates }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      disabled,
      pendingFrequencyMedicationDates,
      pendingAppointmentDates,
    } = mapProps;

    const { handleOnChange } = dispatchProps;

    // control what props get passed to the view
    return {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      disabled,

      // actions to pass to view
      onChange: option => {
        handleOnChange({
          option,
          cachedFrequency: value,
          pendingAppointmentDates,
          pendingFrequencyMedicationDates,
        });
      },
    };
  },
)(SelectWithWrapper);

/**
 * Redux Container for the Addon Frequency Selection
 */
export const FrequencyAddonSelection = connect(
  state => {
    const isSameDay = getCheckForSameDay(state);
    const pendingAppointmentDates = selectDateRangeForFrequency(state);
    const pendingFrequencyAddonDates = getPendingFrequencyAddonDates(state);
    const value = isSameDay ? frequencyConstants.DAILY : getPendingFrequency(state);

    return {
      componentId: "FrequencyAddonSelection",
      title: "Set Frequency",
      options: getSameDayFilteredOptions(state),
      value,
      defaultValue: isSameDay ? frequencyConstants.DAILY : null,
      disabled: isSameDay,
      error: !value && REQUIRED_FIELD_ERROR_MESSAGE,
      display: "block",
      setLocalValue: true,
      pendingFrequencyAddonDates,
      pendingAppointmentDates,
    };
  },

  dispatch => {
    return {
      handleOnChange: ({
        option,
        cachedFrequency,
        pendingAppointmentDates,
        pendingFrequencyAddonDates,
      }) => {
        dispatch(setCachedFrequency({ cachedFrequency }));
        dispatch(setPendingFrequency({ frequency: option.value }));

        const dates = setFrequencyDates(
          option.value,
          pendingAppointmentDates,
          pendingFrequencyAddonDates,
        );
        dispatch(setPendingFrequencyAddonDates({ dates }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      disabled,
      pendingFrequencyAddonDates,
      pendingAppointmentDates,
    } = mapProps;

    const { handleOnChange } = dispatchProps;

    // control what props get passed to the view
    return {
      componentId,
      title,
      options,
      value,
      defaultValue,
      error,
      width,
      display,
      setLocalValue,
      disabled,

      // actions to pass to view
      onChange: option => {
        handleOnChange({
          option,
          cachedFrequency: value,
          pendingAppointmentDates,
          pendingFrequencyAddonDates,
        });
      },
    };
  },
)(SelectWithWrapper);

/**
 * Colleciton of frequency selection types
 * @type {{Medication: JSX.Element, Food: JSX.Element}}
 */
export const FREQUENCY_SELECTION = {
  Food: <FrequencyFoodSelection />,
  Medication: <FrequencyMedicationSelection />,
  Addon: <FrequencyAddonSelection />,
};
