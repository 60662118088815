import { createSelector } from "reselect";
import moment from "moment";
import normalizeArrayByProperty from "../../../utils/normalizeUtils/normalizeArray";
import isExpired from "../../../utils/dateUtils/isExpired";
import { getPet, getItinerary, getPets } from "../../entitiesSelector";
import { getVaccinationsList } from "../../enumsSelectors";
import getVaccinationsState from "./_vaccinationsState";
import { getProps } from "../../commonSelector";

/**
 * Helper function to sort an array of vaccination objects
 * @param {Object[]} vax array of objects containing Name properties
 * @returns array sorted by Name
 */
export const sortVax = vax => vax?.sort((a, b) => (a?.Name > b?.Name) - (a?.Name < b?.Name));

/**
 * Selector to get list of vaccinations from a given pet
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns array of vaccinations for pet
 * @example getPetVaccinations(state, { petId })
 * @todo move to entitiesSelectors or petEntitiesSelectors
 */
export const getPetVaccinations = createSelector([getPet], pet => (pet && pet.vaccinations) || []);

/**
 * Selector to get the speciesId from a given pet
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns the pet's speciesId
 * @example getPetSpeciesId(state, { petId })
 * @todo move to entitiesSelectors or petEntitiesSelectors
 */
export const getPetSpeciesId = createSelector([getPet], pet => pet && pet.speciesId);

/**
 * Selector to get an array of vaccinations required for a pet based on their species
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns array of vaccinations based on given pets species
 * @example getRequiredPetVaccinationsByBreed(state, { petId })
 */
export const getRequiredPetVaccinationsByBreed = createSelector(
  [getVaccinationsList, getPetSpeciesId],
  (vaccinationsList, petSpeciesId) =>
    vaccinationsList?.filter(({ SpeciesId }) => SpeciesId === petSpeciesId),
);

/**
 * Selector to get expiration dates for all required vaccinations for a given pet
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns {Object} key value pairs where key is the vax name and value is expiration date or ""
 * @example getRequiredPetVaccinationsList(state, { petId })
 */
export const getRequiredPetVaccinationsList = createSelector(
  [getRequiredPetVaccinationsByBreed, getPetVaccinations],
  (requiredVaccinations, petVaccinations) => {
    const sortedVaccinations = sortVax(requiredVaccinations);
    const formFields = sortedVaccinations?.reduce((accumulator, requiredVaccination) => {
      // Filter by active flag and sort by latest expiration date
      const petVaccination = petVaccinations
        .filter(vaccination => vaccination?.activeFlag)
        .sort((a, b) => new Date(b?.expirationDate) - new Date(a?.expirationDate))
        .find(vaccination => vaccination?.vaccinationId === requiredVaccination?.VaccinationId);

      if (petVaccination) {
        if (petVaccination?.expirationDate) {
          return {
            ...accumulator,
            // We need to explicitly set to work with UTC time since the moment constructor
            // translates to local time, causing  midnight of the current day i.e. 2023-11-30T00:00:00+00:00
            // to return the previous day (11/29/2023) instead. Details can be found in the following stack
            // overflow article: https://stackoverflow.com/questions/19648688/moment-js-returning-wrong-date
            [requiredVaccination?.Name]: moment
              .utc(petVaccination?.expirationDate)
              .format("YYYY-MM-DD"),
          };
        }

        return { ...accumulator, [requiredVaccination?.Name]: "" };
      }
      return { ...accumulator };
    }, {});

    return formFields;
  },
);

/**
 * Selector to get pet vaccination data formatted for form
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns {{ name: string, expirationDate?: string, isExpired: boolean }[]}
 * @example getPetVaccinationsFormData(state, { petId })
 */
export const getPetVaccinationsFormData = createSelector(
  [getRequiredPetVaccinationsList],
  vaccinations => {
    return Object.keys(vaccinations).map(key => ({
      name: key,
      expirationDate: vaccinations[key],
      isExpired: isExpired(vaccinations[key]),
    }));
  },
);

/**
 * Selector to get list of options for vaccinations that can be added to the current pet
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns {{ value: string|number, label: string }[]}
 * @example getPetVaccinationOptions(state, { petId })
 */
export const getPetVaccinationOptions = createSelector(
  [getRequiredPetVaccinationsByBreed, getPetVaccinations],
  (requiredVaccinations, petVaccinations) => {
    const sortedVaccinations = sortVax(requiredVaccinations) ?? [];
    const vaccinationOptions = sortedVaccinations
      .filter(
        o1 => !petVaccinations.find(o2 => o1.VaccinationId === o2.vaccinationId && o2.activeFlag),
      )
      .map(vaccination => ({ value: vaccination.VaccinationId, label: vaccination.Name }));

    return vaccinationOptions;
  },
);

/**
 * Selector to check if a given pet has an expired vaccination
 * @param {Object} state
 * @param {{ petId: string|number }} props
 * @returns {Boolean}
 * @example getPetHasExpiredVaccination(state, { petId })
 */
export const getPetHasExpiredVaccination = createSelector(
  [getPetVaccinationsFormData],
  formFields =>
    Object.values(formFields)
      .filter(Boolean)
      .some(formField => isExpired(formField)),
);

/**
 * Selector to get vaccinationToDelete state
 * @param {Object} state
 * @returns vaccinationToDelete
 * @example getVaccinationToDelete(state)
 */
export const getVaccinationToDelete = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations?.vaccinationToDelete,
);

/**
 * Selector to get customerKey and petId from vaccinations state
 * @param {Object} state
 * @returns {Object}
 * @example getCustomerAndPetKeys(state)
 */
export const getCustomerAndPetKeys = createSelector([getVaccinationsState], vaccinations => ({
  customerKey: vaccinations?.customerKey,
  petId: vaccinations?.petId,
}));

/**
 * Selector to get showDropdown from vaccinations state
 * @param {Object} state
 * @returns showDropdown
 * @example getShowVaccinationDropdown(state)
 */
export const getShowVaccinationDropdown = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations?.showDropdown,
);

/**
 * Selector to get if any pet is missing required vaccinations
 * @param {Object} state
 * @param {{ itineraryId: string|number }} props
 * @returns {Object|undefined}
 * @example getVaccinationVerification(state, { itineraryId })
 */
export const getVaccinationVerification = createSelector(
  [getItinerary, getPets],
  (itinerary, pets) => {
    const petList = itinerary && itinerary.pets;

    const verificationList = petList?.map(pet => {
      const currentPet = pets?.[pet];
      const vaccination = currentPet?.vaccinations?.find(vacc => {
        if ((vacc?.vaccinationId === 9 || vacc?.vaccinationId === 11) && vacc?.activeFlag) {
          return vacc;
        }
      });

      return vaccination
        ? { expirationDate: vaccination.expirationDate, pet: currentPet?.petId }
        : { vaccination: false, pet: currentPet?.petId };
    });

    if (verificationList) {
      return normalizeArrayByProperty(verificationList, "pet");
    }
  },
);

/**
 * Selector to get isAddVaccinationFormOpen from vaccinations state
 * @param {Object} state
 * @returns isAddVaccinationFormOpen
 * @example getIsAddVaccinationFormOpen(state)
 */
export const getIsAddVaccinationFormOpen = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations?.isAddVaccinationFormOpen,
);

/**
 * Selector to get vaccinationId and petVaccinationId based on given name and pet species
 * @param {Object} state
 * @param {{ petId: string|number, name: string }} props
 * @returns {{ vaccinationId: string|number|undefined petVaccinationId: string|number|undefined }}
 * @example getVaccinationIdsByName(state, { petId, name })
 */
export const getVaccinationIdsByName = createSelector(
  [getVaccinationsList, getPetVaccinations, getPetSpeciesId, getProps],
  (vaccinationsList, petVaccinations, speciesId, { name } = {}) => {
    // UI displays latest expiration date vaccine
    // Sorting in reverse order to make latest vaccine to end of the list
    // normalizeArrayByProperty - will pick up the last record
    const sortedPetVaccinations = petVaccinations
      ?.filter(vaccination => vaccination?.expirationDate && vaccination?.activeFlag)
      ?.sort((a, b) => new Date(a?.expirationDate) - new Date(b?.expirationDate));

    const normalizedPetVaccinations = normalizeArrayByProperty(
      sortedPetVaccinations,
      "vaccinationId",
    );
    const currentVaccination = vaccinationsList?.find(
      vaccination => vaccination.Name === name && vaccination.SpeciesId === speciesId,
    );

    const vaccinationId = currentVaccination?.VaccinationId;
    const petVaccinationId =
      normalizedPetVaccinations?.[vaccinationId]?.petVaccinationId ||
      petVaccinations?.find(vaccination => vaccination.vaccinationId === vaccinationId)
        ?.petVaccinationId;
    return {
      vaccinationId,
      petVaccinationId,
    };
  },
);
