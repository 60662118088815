import { connect } from "react-redux";
import React from "react";
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Selectors
import { selectSelectedDdcPackage } from "@/dux//ddcPackages/ddcPackagesSelectors";
import { selectCartTotalPrice } from "../servicesCartV2/servicesCartV2Selectors";
import { createLoadingSelector } from "@/core/selectors/utils";

// Utils
import { formatMoney } from "../_utils/moneyUtil";
import isEmpty from "lodash/isEmpty";

// Actions
import { POST_CART_V2 } from "../servicesCartV2/servicesCartV2Actions";

/**
 * React view component for DDC packages total price and purchase button
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DdcPackagesPriceAndPurchaseComponent = props => {
  const { componentId, totalPrice, disabled, onClick = () => {}, isHidden, isLoading } = props;
  if (isHidden) return null;

  return (
    <LoadingWrapper isLoading={isLoading} displayContainer="block">
      <LayoutCluster
        id={componentId}
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <LayoutBox style={{ paddingLeft: 0 }}>
          <LayoutStack space="scale-0">
            <TextPassage>Total price</TextPassage>
            <TextPassage>{totalPrice}</TextPassage>
          </LayoutStack>
        </LayoutBox>

        <LayoutBox style={{ paddingRight: 0 }}>
          <Button text="Purchase" onClick={onClick} disabled={disabled} />
        </LayoutBox>
      </LayoutCluster>
    </LoadingWrapper>
  );
};

/**
 * Redux Connect function for DDC packages total price and purchase button
 * @memberOf Views.Purchase
 * @function
 * @name DdcPackagesPriceAndPurchase
 * @example <DdcPackagesPriceAndPurchase />
 */
export const DdcPackagesPriceAndPurchase = connect(state => {
  const selectedDdcPackage = selectSelectedDdcPackage(state);
  const totalPrice = selectCartTotalPrice(state);

  return {
    componentId: "DdcPackagesPriceAndPurchase",
    totalPrice: formatMoney(totalPrice),
    disabled: isEmpty(selectedDdcPackage),
    isLoading: createLoadingSelector([POST_CART_V2])(state),
  };
})(DdcPackagesPriceAndPurchaseComponent);
