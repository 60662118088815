import React from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import styled from "styled-components";

import Img from "../common/Img";
import ProfileImageButton from "../common/ProfileImageButton";
import CloseButtonSVG from "../../assets/icons/closeButton.svg";
import ClockSVG from "../../assets/icons/clock.svg";
import ProfileSVG from "../../assets/icons/profile.svg";
import { routePaths } from "../../core/constants/routePaths";


// TODO: replace with QuickPinPage
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #505050;
`;

const StyledHeading = styled.h3`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  white-space: pre-line;
  color: white;
  margin: 2em 0;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 900px;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0 0 2.5em 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const ButtonImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 4em 0;
  cursor: pointer;
`;

// TODO: replace with new container for QuickPinIconButton
const ButtonImage = styled(Img)`
  border-radius: 50%;
  border: 0.25em solid #ffffff;
  margin: 2em 3em 1em 3em;
  height: 50px;
  width: 50px;
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
        border-color: #7cffe2;
    `};
`;

const ButtonText = styled.div`
  color: ${({ isHighlighted }) => (isHighlighted ? "#7cffe2" : "#fff")};
`;

class SelectUserComponent extends React.Component {
  componentDidMount() {
    this.props.fetchAuthorizedUsers();
  }

  render() {
    const {
      authedUsers,
      router: { navigate },
      setSelectedUser,
      showModal,
    } = this.props;
    return (
      <SectionWrapper>
        <StyledHeading>Choose your profile</StyledHeading>
        <UsersContainer>
          {authedUsers.map(({ name, username }) => (
            <ProfileImageButton
              key={name}
              onClick={() => {
                setSelectedUser({ name, username });
                navigate(routePaths.ENTER_PIN);
              }}
              name={name}
              image={ProfileSVG}
            />
          ))}
        </UsersContainer>
        <ButtonContainer>
          <ButtonImageWrapper onClick={showModal}>
            <ButtonImage isHighlighted src={ClockSVG} />
            <ButtonText isHighlighted>Start Shift</ButtonText>
          </ButtonImageWrapper>
        </ButtonContainer>
      </SectionWrapper>
    );
  }
}

export default withRouteProps(SelectUserComponent);
