import { SET_SERVICES_CART_V2 } from "./servicesCartV2Actions";

export const servicesCartV2Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SERVICES_CART_V2:
      return action?.cart;
    default:
      return state;
  }
};
