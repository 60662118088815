import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { Heading, TextField, TextPassage, Button } from "@petsmart-ui/sparky";
import { color } from "web/common/styles/theme";
import ProfileSVG from "assets/icons/profile.svg";

export const quickPinFormWidth = "250px";

/**
 * Helper component for common styling on the quick pin pages
 * @param {{ componentId: String, children: JSX.ELement }} props
 * @returns {JSX.Element}
 */
export const QuickPinPage = ({ componentId, children }) => {
  return (
    <LayoutStack
      id={componentId}
      space="scale-G1"
      style={{ alignItems: "center", justifyContent: "center", backgroundColor: color.gray700 }}
    >
      {children}
    </LayoutStack>
  );
};

/**
 * Helper component for common profile image styling on the quick pin pages
 * @returns {JSX.Element}
 */
export const QuickPinProfileImg = () => {
  return (
    <LayoutBox>
      <img
        src={ProfileSVG}
        alt=""
        style={{
          borderRadius: " 50%",
          margin: "5px",
          height: "125px",
          width: "125px",
          boxShadow: `1px 1px 22px 3px ${color.gray900}`,
        }}
      />
    </LayoutBox>
  );
};

/**
 * Helper component for common sub heading styling on the quick pin pages
 * @param {{ children: JSX.Element }} props
 * @returns {JSX.Element}
 */
export const QuickPinSubHeading = ({ children }) => {
  return (
    <LayoutBox>
      <Heading tagName="h4" size="headline" style={{ color: color.white0 }}>
        {children}
      </Heading>
    </LayoutBox>
  );
};

/**
 * Helper component for common form styling on the quick pin pages
 * @param {{ onSubmit: Function, children: JSX.Element, error: String }} props
 * @returns {JSX.Element}
 */
export const QuickPinForm = ({ onSubmit, children, error }) => {
  return (
    <LayoutBox style={{ display: "flex", margin: "0 10% 0 10%" }}>
      <form onSubmit={onSubmit}>
        <LayoutStack space="scale-0">{children}</LayoutStack>

        <LayoutBox style={{ color: color.red300, maxWidth: quickPinFormWidth }}>
          <TextPassage size="lg" align="center">
            {error}
          </TextPassage>
        </LayoutBox>
      </form>
    </LayoutBox>
  );
};

/**
 * Helper component for common input styling on the quick pin pages
 * @param {{ id: String, placeholder: String, name: String, ref: Object, onChange: Function, value: String }} props
 * @returns {JSX.Element}
 */
export const QuickPinInput = React.forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      type="password"
      maxLength="6"
      hideLabel
      floating={false}
      style={{ width: quickPinFormWidth }}
    />
  );
});

/**
 * Helper component for common submit button styling on the quick pin pages
 * @param {{ id: String, isLoading: Boolean }} props
 * @returns {JSX.Element}
 */
export const QuickPinSubmitBtn = ({ id, isLoading }) => {
  return (
    <Button
      id={id}
      type="Submit"
      disabled={isLoading}
      text="Submit"
      variant="primary"
      size="lg"
      loading={isLoading}
      style={{ width: quickPinFormWidth }}
    />
  );
};
