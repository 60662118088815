import { put, takeLatest, call, all } from "redux-saga/effects";
import {
  postCartV2Endpoint,
  patchCartProductV2Endpoint,
} from "@/core/services/cart/servicesCartV2Endpoints";
import {
  PATCH_CART_PRODUCT_V2,
  patchCartProductV2Failure,
  patchCartProductV2Request,
  patchCartProductV2Success,
  POST_CART_V2,
  postCartV2Failure,
  postCartV2Request,
  postCartV2Success,
  setServicesCartV2,
} from "./servicesCartV2Actions";

function* onPostCartV2({ cart }) {
  try {
    yield put(postCartV2Request());
    const response = yield call(postCartV2Endpoint, { cart });
    yield put(setServicesCartV2(response?.data?.result));
    yield put(postCartV2Success());
  } catch (error) {
    yield put(postCartV2Failure(error));
  }
}

function* onPatchCartProductV2({ cartId, cart }) {
  try {
    yield put(patchCartProductV2Request());
    const response = yield call(patchCartProductV2Endpoint, { cartId, cart });
    yield put(setServicesCartV2(response?.data?.result));
    yield put(patchCartProductV2Success());
  } catch (error) {
    yield put(patchCartProductV2Failure(error));
  }
}

function* watchOnPostCartV2() {
  yield takeLatest(POST_CART_V2, onPostCartV2);
}

function* watchOnPatchCartProductV2() {
  yield takeLatest(PATCH_CART_PRODUCT_V2, onPatchCartProductV2);
}

export default function* servicesCartV2Saga() {
  yield all([watchOnPostCartV2(), watchOnPatchCartProductV2()]);
}
