import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { isRestrictedOrFollowUpMed } from "web/medicationName/medicationNameHelpers";
import { getMedicationsByPet } from "../medication/medicationsSelector";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";

export const getMedicationExternalIdById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => get([medicationId, "externalId"], medications),
);

export const getMedicationNameById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => get([medicationId, "name"], medications),
);

export const getMedicationNameByExternalId = createSelector(
  [getMedicationsByPet, selectMedicationsList, (state, props) => props],
  (medications, medicationList, { medicationId }) => {
    const chosenMedication = get(medicationId, medications);

    if (chosenMedication && chosenMedication.externalId) {
      const foundMedication = medicationList?.find(
        medication => medication.MedicationId === chosenMedication.externalId,
      );
      return foundMedication && foundMedication.Description;
    }

    return chosenMedication?.name;
  },
);

export const getMedicationNameExternalIdById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => {
    return get([medicationId, "externalId"], medications);
  },
);

export const getPetMedicationOptions = createSelector([selectMedicationsList], medications => {
  const sortedMedications = medications
    ?.filter(medication => medication.IsActive)
    .sort(
      (a, b) =>
        (a.Description.toUpperCase() > b.Description.toUpperCase()) -
        (a.Description.toUpperCase() < b.Description.toUpperCase()),
    );

  const medicationOptions = sortedMedications?.map(medication => {
    const { label, isDisabled } = isRestrictedOrFollowUpMed(medication);

    return {
      disabled: isDisabled,
      label,
      medName: medication.Description,
      value: medication.MedicationId,
    };
  });
  return medicationOptions || [];
});
