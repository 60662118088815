import { connect } from "react-redux";
import { getSelectedAddOnIds } from "dux/_selectors/addOnsSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getaddOnStandaloneByService } from "./StandaloneServicesAccordionSelectors";
import { setAddons } from "core/actionCreators/ui/web/cartActionCreators";
import { BookingAddonsPriceListComponent } from "web/booking/bookingAddons/BookingAddonsPriceListComponent";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const items = getaddOnStandaloneByService(state, { petId });
  const addOns = [
    { label: "Most Popular", value: items[0] },
    { label: "Other Standalones", value: items[1] },
  ];

  return {
    componentId: "standalone-service-filterable-list",
    properties: { id: "addOnId", name: "addOnName" },
    selectedAddOns: getSelectedAddOnIds(state, { petId }),
    addOns,
    petId,
  };
};

const mapDispatchToProps = dispatch => ({
  handleOnSelectionChange: (addons, petId) => {
    const wrapIds = addons.map(addon => (addon.addOnId ? addon : { addOnId: addon }));
    dispatch(setAddons({ addons: wrapIds, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSelectionChange: addOn => {
    const { selectedAddOns, petId } = propsFromState;
    const { handleOnSelectionChange } = propsFromDispatch;

    // Remove add-on id if already listed in the array.
    // If not, append it to the list of selected add-ons.
    const addOns = selectedAddOns?.includes(addOn)
      ? selectedAddOns?.filter(selectedAddOn => selectedAddOn !== addOn)
      : [...selectedAddOns, addOn];

    handleOnSelectionChange(addOns, petId);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BookingAddonsPriceListComponent);
