import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { selectDdcPackageReservedUnitsById } from "./ddcPurchasedPackagesSelectors";

export const DdcPackageReservedUnitsComponent = props => {
  const { componentId, isHidden, label, units } = props;

  const navigate = useNavigate();

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        <TextPassage>{label}</TextPassage>
        {units?.map(({ id, link, formattedDate }) => (
          <LayoutBox key={id} padding="scale-0">
            <Button variant="link" text={formattedDate} onClick={() => navigate(link)} />
          </LayoutBox>
        ))}
      </LayoutStack>
    </LayoutBox>
  );
};

export const DdcPackageReservedUnits = connect((state, { packageId }) => {
  const units = selectDdcPackageReservedUnitsById(state, { packageId });
  const count = units?.length ?? 0;
  return {
    componentId: `DdcPackageReservedUnits-${packageId}`,
    label: `${count} Play & Save Passes reserved`,
    units,
  };
})(DdcPackageReservedUnitsComponent);
