import { connect } from "react-redux";
import IconsComponent from "web/upcoming_here/icons/iconsComponent";
import { getHotelCardIcons } from "dux/hotelPetCardIcon/hotelIconUtils";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  selectHotelBookingPetCardIconInfo,
  selectHotelProfilePetCardIconInfo,
} from "./hotelPetCardIconSelectors";

const mapStateToProps = (state, { petId }) => {
  const customerKey = getCurrentCustomerKey(state);
  const profileIconInfo = selectHotelProfilePetCardIconInfo(state, { petId, customerKey });
  const bookingIconInfo = selectHotelBookingPetCardIconInfo(state, { petId, customerKey });

  return {
    icons: getHotelCardIcons({ ...profileIconInfo, ...bookingIconInfo }),
  };
};

export default connect(mapStateToProps)(IconsComponent);
