import { CUSTOMER_ID_HEADER_KEY } from "@/core/constants/endpointConstants";
import { associateWebBFFAPI } from "../associateWebBff/_axiosConfigAssociateWebBff";

/**
 * Initial call to create a cart for V2
 * @param {*} { cart }
 * @returns
 */
export function postCartV2Endpoint({ cart }) {
  return associateWebBFFAPI.post(`v2/carts`, cart, {
    headers: { [CUSTOMER_ID_HEADER_KEY]: cart?.customerKey },
  });
}

/**
 * Cart V2 PATCH call to update a product
 * @param {*} { cart }
 * @returns
 */
export function patchCartProductV2Endpoint({ cart, cartId }) {
  return associateWebBFFAPI.patch(`v2/carts/${cartId}/products`, cart, {
    headers: { [CUSTOMER_ID_HEADER_KEY]: cart?.customerKey },
  });
}
