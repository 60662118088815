import { connect } from "react-redux";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { getFoodById } from "web/food/foodsSelector";
import { createLoadingSelector } from "core/selectors/utils";
import CommonButton from "web/common/commonButton";
import { setTimeOfDayTouched } from "web/foodTimeOfDay/actions/foodTimeOfDayActions";
import { clearNewFood } from "web/newFood/actions/newFoodActions";
import {
  addFoodBookingActionTypes,
  addNewBookingFood,
} from "dux/bookingFeeding/addNewFoodBookingActions";
import isRequiredPetFoodFieldMissing from "web/newFood/utils/isRequiredPetFoodFieldMissing";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { getFoodIsShowingDetailsForType } from "web/foodList/foodListSelectors";
import { getNewFoodId } from "web/foodCount/foodCountSelector";
import { putCart } from "../servicesCart/servicesCartActions";
import { setFoodSaveButtonTouched } from "@/web/food/actions/foodsActions";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId, locationType } = ownProps;
  const foodList = selectFoodList(state);
  const food = getFoodById(state, { foodId, petId });
  const isLoading = createLoadingSelector([addFoodBookingActionTypes.ADD_NEW_BOOKING_FOOD])(state);
  return {
    foodId,
    petId,
    foodList,
    newFoodId: getNewFoodId(state, { petId }),
    food,
    isHidden:
      foodId !== NEW_FOOD_ID
        ? true
        : !getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
    componentId: "foodDetails-addNewFoodButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    hasErrors: isRequiredPetFoodFieldMissing({ food, foodList, isInBooking: true, petId }),
    disabled: isLoading,
  };
};

const mapDispatchToProps = (dispatch, { petId }) => ({
  addPetFoodToBooking: foodId => {
    dispatch(addNewBookingFood({ foodId, petId }));
    dispatch(setTimeOfDayTouched({ foodId, petId, timeOfDayTouched: false }));
    dispatch(clearNewFood({ petId }));
    dispatch(putCart());
  },
  setSaveTouched: foodId => dispatch(setFoodSaveButtonTouched({ foodId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,

  onClick: () => {
    const { newFoodId, hasErrors, foodId } = propsFromState;
    const { addPetFoodToBooking, setSaveTouched } = propsFromDispatch;
    setSaveTouched(foodId);
    if (!hasErrors) addPetFoodToBooking(newFoodId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
