import { GET_PURCHASED_DDC_PACKAGES_SUCCESS } from "./ddcPurchasedPackagesActions";

const initialState = {};

const ddcPurchasedPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASED_DDC_PACKAGES_SUCCESS:
      return { ...action?.packages };
    default:
      return state;
  }
};

export default ddcPurchasedPackagesReducer;
