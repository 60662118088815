import React from "react";
import { connect } from "react-redux";

// Components
import { DdcPackagesHistoryCard } from "./DdcPackagesHistoryListCard";

// Components
import { PaginatedStack } from "@prism/psm-ui-components";

// DDC PACKAGES HISTORY LIST PAGINATION ------------------------------------------------------------------
export const DdcPackagesHistoryListPagination = connect(
  (state, ownProps) => {
    const { inActiveDdcPurchasedPackageIds } = ownProps;
    return {
      componentId: "DdcPackagesHistoryListPagination",
      total: 12, // todo
      space: "stack-space-8",
      style: {
        width: "100%",
      },
      children: currentPage => {
        return (
          <>
            {inActiveDdcPurchasedPackageIds?.map(inActiveDdcPurchasedPackageId => (
              <DdcPackagesHistoryCard
                key={inActiveDdcPurchasedPackageId}
                inActiveDdcPurchasedPackageId={inActiveDdcPurchasedPackageId}
              />
            ))}
          </>
        );
      },
    };
  },
  // If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
  // if the component being wrapped uses prop spreading this could cause errors.
  // To prevent this we pass a function returning an empty obj
  () => ({}),
)(PaginatedStack);
