import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { isEmpty } from "lodash";
import getIsDdcPackagesWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ddcPackages/getIsDdcPackagesWorkflowFeatureFlagHidden";
import Section from "@/web/common/Section";
import { Hr } from "@petsmart-ui/sparky";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { GLOBAL_TOKENS } from "@/layout/scaleToken";
import { DdcActivePackage } from "./DdcActivePackage";
import {
  GET_PURCHASED_DDC_PACKAGES,
  getDdcPurchasedPackages,
  getDdcPurchasedPackagesSuccess,
} from "./ddcPurchasedPackagesActions";
import { selectActiveDdcPackageIds } from "./ddcPurchasedPackagesSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import LoadingWrapper from "@/web/common/LoadingWrapper";

export const DdcPurchasedPackagesComponent = props => {
  const {
    componentId,
    isHidden,
    isLoading,
    activePackageIds,
    getPackages = () => {},
    clearPackages = () => {},
  } = props;
  if (isHidden) return null;

  useEffect(() => {
    if (!isHidden) {
      getPackages();
    }

    return () => {
      clearPackages();
    };
  }, [isHidden]);

  return (
    <LayoutBox padding="scale-0" id={componentId}>
      <LoadingWrapper isLoading={isLoading}>
        <Section
          title="DDC Play & Save"
          topLine
          paddingTop={GLOBAL_TOKENS.SCALE["scale-G1"]}
          stackSpace="scale-G1"
        >
          {isEmpty(activePackageIds)
            ? "There is no active package available"
            : activePackageIds?.map((packageId, index) => (
                <React.Fragment key={packageId}>
                  <DdcActivePackage packageId={packageId} />
                  {index < activePackageIds?.length - 1 && (
                    <LayoutBox padding="scale-0">
                      <Hr style={{ margin: 0 }} />
                    </LayoutBox>
                  )}
                </React.Fragment>
              ))}
        </Section>
      </LoadingWrapper>
    </LayoutBox>
  );
};

export const DdcPurchasedPackages = connect(
  state => {
    const isDdcPassFeatureHidden = getIsDdcPackagesWorkflowFeatureFlagHidden(state);
    const activePackageIds = selectActiveDdcPackageIds(state);
    const isHotel = getSystemBookingFlow(state) === systemName.HOTEL;

    return {
      componentId: "DdcPurchasedPackages",
      isHidden: isDdcPassFeatureHidden || !isHotel,
      activePackageIds,
      isLoading: createLoadingSelector([GET_PURCHASED_DDC_PACKAGES])(state),
    };
  },
  dispatch => {
    return {
      getPackages: () => dispatch(getDdcPurchasedPackages()),
      clearPackages: () => dispatch(getDdcPurchasedPackagesSuccess()),
    };
  },
)(DdcPurchasedPackagesComponent);
