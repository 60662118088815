import * as React from 'react';
import { psx } from '../../utils';

import './styles.css';

export const TOKENS = {
  variant: {
    none: '',
    primary: '',
    ghost: '',
    'toggle-pill': '',
    'prism-primary': '',
    'no-outline': '',
    'prism-primary-no-outline': '',
    'prism-primary-inverted': '',
    link: '',
  },
  size: { xs: '', sm: '', md: '', lg: '', xl: '' },
};

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  /** Variants encompass all of the designed intent and the accompanying component states so you don't have to. */
  variant?: keyof typeof TOKENS.variant;
  /** Control the padding and fonts for the button */
  size?: keyof typeof TOKENS.size;
  /** Eventually will be used for tooltips and has accessibility value. */
  title?: string;
  /** Controls the visibility of the button */
  isHidden?: boolean;
  /** Adds default hover border effect */
  withHover?: boolean;
  /** Adds default selected border effect  */
  isSelected?: boolean;
  /** Adds style overrides for button */
  style?: React.CSSProperties;
}

const ButtonBase: React.FunctionComponent<ButtonProps> = ({ children, ...props }) => {
  // Using spread props here should be find as we are have already curated our props.
  return <button {...props}>{children}</button>;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  variant = 'none',
  title = '',
  size = 'md',
  className,
  children,
  isHidden,
  isSelected,

  // Button Specific attributes listed here are to stop "Unknown Prop Warnings" If you need more default button
  // attributes such as autofucus, form, form icon, etc. add them to this list. you can find the list here:
  //    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attributes and
  //    https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes
  disabled,
  name,
  type = 'button',
  value,
  onClick,
  style,
  id,
  ...props
}) => {
  if (isHidden) {
    return null;
  }

  const buttonStyles = { 'psm-button--isSelected': isSelected };
  const classNames = psx('psm', 'psm-button', `psm-button--${variant}`, buttonStyles, className);
  const Component = ButtonBase;
  const ariaPressed = props['aria-pressed'];

  // spread props (...props) should not be used, it wil cause "Unknown Prop Warnings"
  return (
    <Component
      id={id}
      title={title}
      type={type}
      className={classNames}
      data-button-size={`size:${size}`}
      disabled={disabled}
      name={name}
      value={value}
      onClick={onClick}
      style={style}
      aria-pressed={ariaPressed}
    >
      {children}
    </Component>
  );
};

export default Button;
