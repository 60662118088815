import React from "react";
import { connect } from "react-redux";
import { Button } from "@petsmart-ui/sparky";
import { selectIsOvernight } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { OVERNIGHT_BACK_BTN_LABEL, SINGLE_DAY_BACK_BTN_LABEL } from "./hotelCartAddonsConstants";
import { selectCurrentHotelEngagement } from "../hotelEngagements/hotelEngagementSelectors";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { LayoutBox } from "@/layout/box/Box";

export const HotelAddonReturnButton = ({ componentId, label, onReturn }) => {
  return (
    <LayoutBox padding="scale-0">
      <Button id={componentId} onClick={onReturn} variant="link" text={label} />
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonReturnButton = connect((state, props) => {
  const isOvernight = selectIsOvernight(state);
  return {
    componentId: "HotelBookingCartAddonReturnButton",
    label: isOvernight ? OVERNIGHT_BACK_BTN_LABEL : SINGLE_DAY_BACK_BTN_LABEL,
    onReturn: props?.onClose,
  };
})(HotelAddonReturnButton);

export const HotelCheckInOutCartAddonReturnButton = connect((state, { onClose }) => {
  const petId = getCurrentPet(state);
  const engagementType = selectCurrentHotelEngagement(state, { petId })?.engagementType;
  const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;

  return {
    componentId: "HotelCheckInOutCartAddonReturnButton",
    label: isOvernight ? OVERNIGHT_BACK_BTN_LABEL : SINGLE_DAY_BACK_BTN_LABEL,
    onReturn: onClose,
  };
})(HotelAddonReturnButton);
