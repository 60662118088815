import { connect } from "react-redux";
import Arrow from "../common/Arrow";
import { showHideMedicationDetails } from "./actions/medicationListActions";
import {
  getMedicationIsShowingDetailsForType,
  getMedicationIsShowingDetails,
} from "./medicationListSelectors";
import { medicationTypes } from "../newMedication/newMedicationConstants";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId, locationType } = ownProps;
  const type = locationType || medicationTypes.PROFILE;
  const isLocationTypeShowing = getMedicationIsShowingDetailsForType(state, {
    medicationId,
    petId,
    locationType: type,
  });
  return {
    componentID: `medFormToggle-${medicationId}`,
    isLocationTypeShowing,
    locationType: type,
    medicationId,
    petId,
    typesShowing: getMedicationIsShowingDetails(state, { medicationId, petId }),
    direction: isLocationTypeShowing ? "down" : "right",
  };
};

const mapDispatchToProps = dispatch => ({
  showHideDropdown: values =>
    dispatch(
      showHideMedicationDetails({
        medicationId: values.medicationId,
        petId: values.petId,
        locationTypes: values.locationTypes,
      }),
    ),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  clickHandler: () => {
    const {
      locationType,
      typesShowing,
      isLocationTypeShowing,
      medicationId,
      petId,
    } = propsFromState;
    let locationTypes;
    if (!isLocationTypeShowing) {
      locationTypes = [...typesShowing, locationType];
    } else {
      locationTypes = typesShowing.filter(type => type !== locationType);
    }
    propsFromDispatch.showHideDropdown({ locationTypes, medicationId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
