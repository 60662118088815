import React from "react";
import MedicationNameContainer from "../medicationName/MedicationNameContainer";
import AddNewMedicationButtonContainer from "../addMedication/buttons/AddNewMedicationButtonContainer";
import CancelNewMedicationButtonContainer from "../addMedication/buttons/CancelNewMedicationButtonContainer";
import OtherMedicationNameContainer from "../otherMedicationName/OtherMedicationNameContainer";
import MedicationTypeAmountContainer from "../medicationTypeAmount/MedicationTypeAmountContainer";
import MedicationInstructionsContainer from "../medicationInstructions/MedicationInstructionsContainer";
import { MedTimeOfDay } from "@/dux/timeOfDayCheckboxes/TimeOfDayCheckboxes";
import { isNewMedication } from "../medication/medicationUtils";
import withClickOutside from "./hoc/withClickOutside";
import { MedFormLayout } from "@/dux/_components/medication/MedFormLayout";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";

class PetProfileMedicationFormComponent extends React.Component {
  handleClickOutside = this.handleClickOutside.bind(this);

  handleClickOutside(e) {
    const { onSubmit } = this.props;
    onSubmit && onSubmit();
  }

  render() {
    const {
      componentId,
      isHidden,
      medicationId,
      medicationIndex,
      medicationIds,
      clickOutsideRef,
      petId,
    } = this.props;
    const newMedicationIndex = isNewMedication(medicationId)
      ? medicationIds.length + 1
      : medicationIndex;

    return !isHidden ? (
      <div ref={clickOutsideRef} handleClickOutside={this.handleClickOutside}>
        <MedFormLayout componentId={componentId}>
          <MedicationNameContainer
            medicationIndex={newMedicationIndex}
            medicationId={medicationId}
            petId={petId}
          />
          <OtherMedicationNameContainer medicationId={medicationId} petId={petId} />
          <MedicationTypeAmountContainer
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />
          <MedTimeOfDay
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />
          <MedicationInstructionsContainer
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />

          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "flex-end" }}>
              <AddNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
              <CancelNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
            </LayoutCluster>
          </LayoutBox>
        </MedFormLayout>
      </div>
    ) : null;
  }
}

export default withClickOutside(PetProfileMedicationFormComponent);
