import { connect } from "react-redux";
import { getNewMedicationId } from "web/medicationCount/medicationCountSelector";
import { putCart } from "@/dux/servicesCart/servicesCartActions";
import CommonButton from "../../../web/common/commonButton";
import { getMedicationIsShowingDetailsForType } from "../../../web/medicationList/medicationListSelectors";
import { getMedicationById } from "../../../web/medication/medicationsSelector";
import isRequiredPetMedicationFieldMissing from "../../../web/newMedication/utils/isRequiredPetMedicationFieldMissing";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { clearNewMedication } from "../../../web/newMedication/actions/newMedicationActions";
import {
  addNewBookingMedication,
  addMedicationBookingActionTypes,
} from "./actions/addNewMedicationBookingActions";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { NEW_MEDICATION_ID } from "../../../web/newMedication/newMedicationConstants";
import { setTimeOfDayTouched } from "../../../web/medicationTimeOfDay/actions/medicationTimeOfDayActions";
import { setMedSaveButtonTouched } from "@/web/medication/actions/medicationsActions";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId, locationType } = ownProps;
  const medicationList = selectMedicationsList(state);
  const medication = getMedicationById(state, { medicationId, petId });
  const isLoading = createLoadingSelector([
    addMedicationBookingActionTypes.ADD_NEW_BOOKING_MEDICATION,
  ])(state);
  return {
    medicationId,
    petId,
    medicationList,
    nedMedicationId: getNewMedicationId(state, { petId }),
    medication,
    isHidden:
      medicationId !== NEW_MEDICATION_ID
        ? true
        : !getMedicationIsShowingDetailsForType(state, { medicationId, petId, locationType }),
    componentId: "medicationDetails-addNewMedicationButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    hasErrors: isRequiredPetMedicationFieldMissing({
      medication,
      medicationList,
      isInBooking: true,
      petId,
    }),
    disabled: isLoading,
  };
};

const mapDispatchToProps = (dispatch, { petId }) => ({
  addPetMedToBooking: medicationId => {
    dispatch(addNewBookingMedication({ medicationId, petId }));
    dispatch(setTimeOfDayTouched({ medicationId, timeOfDayTouched: false, petId }));
    dispatch(clearNewMedication({ petId }));
    dispatch(putCart());
  },
  setSaveTouched: medicationId => dispatch(setMedSaveButtonTouched({ medicationId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { nedMedicationId, hasErrors, medicationId } = propsFromState;
    propsFromDispatch.setSaveTouched(medicationId);
    if (!hasErrors) propsFromDispatch.addPetMedToBooking(nedMedicationId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
