import React, { Component } from "react";

class EnvironmentDetails extends Component {
  render() {
    // Quick and dirty way to parse the environment
    return (
      <div>
        <ul>
          <li>ENVIRONMENT PROPERTIES</li>
          <li>UI ENV: {window.env.APP_ENV.toUpperCase()}</li>
          <li>SALESFORCE ENV (this is mocked): {window.env.SF_ENV}</li>
          <li>IDLE TIMEOUT: {window.env.IDLE_TIMEOUT}</li>
          <li>OKTA ISSUER: {window.env.OKTA_ISSUER}</li>
          <li>OKTA REDIRECT URI: {window.env.OKTA_REDIRECT_URI}</li>
        </ul>
        <p>Endpoint URIs</p>
        <ul>
          <li>BASE URL: {window.env.BASE_URL}</li>
          <li>ENUMS URL: {window.env.ENUMS_BASE_URL}</li>
          <li>ASSOCIATE WEB: {window.env.ASSOCIATE_WEB_URI}</li>
          <li>ASSOCIATE MOBILE: {window.env.ASSOCIATE_MOBILE_URI}</li>
          <li>ASSOCIATE WEB PROFILE: {window.env.ASSOCIATE_WEB_PROFILE_URI}</li>
          <li>ASSOCIATE MOBILE PROFILE: {window.env.ASSOCIATE_MOBILE_PROFILE_URI}</li>
          <li>CUSTOMER BOOKING: {window.env.CUSTOMER_BOOKING_URI}</li>
          <li>SYSTEM BOOKING URI: {window.env.SYSTEM_BOOKING_URI}</li>
          <li>SYSTEM ADMIN BOOKING: {window.env.SYSTEM_ADMIN_BOOKING_URI}</li>
        </ul>
      </div>
    );
  }
}

export default EnvironmentDetails;
