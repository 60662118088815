import { connect } from "react-redux";
import {
  getFoodFrequencyById,
  getFrequencyIsTouchedById,
  getIsFrequencyMissingField,
} from "dux/bookingFeeding/setFoodFrequency/foodFrequencySelectors";
import CommonErrorMessage from "../../web/common/errorAndMessages/CommonErrorMessage";
import { frequencyConstants, frequencyErrors } from "./FrequencyConstants";
import { selectIsFoodSaveTouchedById } from "@/web/food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const isManual = getFoodFrequencyById(state, { foodId, petId }) === frequencyConstants.MANUAL;
  const touched =
    selectIsFoodSaveTouchedById(state, { foodId, petId }) ||
    getFrequencyIsTouchedById(state, { foodId, petId });
  return {
    message: isManual ? frequencyErrors.MANUAL_MISSING_DATES : frequencyErrors.REQUIRED,
    isError: true,
    isHidden: !touched || !getIsFrequencyMissingField(state, { foodId, petId }),
    componentID: "frequencyErrorMessage-GenericContentWrapper",
  };
};

export default connect(mapStateToProps)(CommonErrorMessage);
