import React, { useEffect, useRef, useState } from "react";
import Section from "web/common/Section";
import { connect } from "react-redux";
import { Form, Text } from "@prism/psm-ui-components";
import { getHotelRoomTypes } from "dux/hotelRoomTypes/hotelRoomTypesActions";
import {
  getPetDefaultRoomType,
  getPetRoomName,
  getRoomTypeSelectorOptions,
} from "dux/hotelRoomTypes/hotelRoomTypesSelectors";
import { getPet } from "core/selectors/entitiesSelector";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import {
  boardingDetailsHotelActionTypes,
  updateBoardingDetailsHotel,
} from "dux/boardingDetails/boardingDetailsHotelActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  getBeddingTypeSelectorOptions,
  getPetBeddingName,
  getPetDefaultBeddingType,
} from "dux/hotelBeddingTypes/hotelBeddingTypesSelectors";
import { walkTypes } from "@/dux/hotelWalkTypes/hotelWalkTypesConstants";
import { getHotelBeddingTypes } from "dux/hotelBeddingTypes/hotelBeddingTypesActions";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import { getDDCPetDetailsFormValues } from "dux/ddcPetDetails/DDCPetDetailsSelectors";
import { getPetSpeciesId } from "core/selectors/ui/vaccinations/vaccinationsSelectors";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { createLoadingSelector } from "@/core/selectors/utils";
import { speciesTypes } from "@/core/constants/speciesContants";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutGrid } from "@/layout/grid/Grid";
import SelectWithWrapper from "@/web/common/SelectWithWrapper";

const CheckboxField = ({ name, label, checked, onClick, isDisabled, isHidden }) => {
  if (isHidden) return null;
  return (
    <LayoutBox padding="scale-0">
      <LayoutCluster>
        <Form.Field.Checkbox name={name} checked={checked} onClick={onClick} />
        <Text color={isDisabled ? "text-color-disabled" : "text-color-black"}>{label}</Text>
      </LayoutCluster>
    </LayoutBox>
  );
};

const DDCPetDetailsComponent = props => {
  const {
    isHidden,
    isCat,
    componentId,
    petId,
    initialFormValues,
    callGetHotelRoomTypes,
    callGetHotelBeddingTypes,
    updatePetProfile,
    disabled,
    individualLabel,
    harnessLabel,
    walkTypeOptions,
    beddingTypeOptions,
    roomTypeOptions,
    isLoading,
  } = props;

  const { isIndividual, harnessOnly } = initialFormValues;
  const roomType = initialFormValues?.roomType || "";
  const beddingType = initialFormValues?.beddingType || "";
  const walkType = initialFormValues?.walkType || "";

  const [values, setFormValues] = useState({
    roomType,
    beddingType,
    walkType,
    isIndividual,
    harnessOnly,
  });

  useEffect(() => {
    if (!isHidden) {
      callGetHotelRoomTypes();
      callGetHotelBeddingTypes();
    }
  }, [isHidden]);

  useEffect(() => {
    setFormValues({ roomType, beddingType, walkType, isIndividual, harnessOnly });
  }, [petId, roomType, beddingType, walkType, isIndividual, harnessOnly]);

  const onClickOutside = () => {
    updatePetProfile(values);
  };

  const ref = useRef(null);

  ClickOutside(ref, onClickOutside);

  if (isHidden) return null;
  return (
    <LoadingWrapper isLoading={isLoading}>
      <Section componentId={componentId} title="PetsHotel DDC Details">
        <div ref={ref}>
          <LayoutStack space="scale-G1">
            <CheckboxField
              name="isIndividual"
              label={individualLabel}
              checked={values.isIndividual}
              onClick={() => {
                setFormValues({
                  ...values,
                  isIndividual: !values.isIndividual,
                  walkType: "Individual",
                });
              }}
              isDisabled={disabled}
              isHidden={isCat}
            />

            <LayoutBox padding="scale-0">
              <LayoutGrid
                space="scale-0"
                style={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1em",
                  alignItems: "center",
                }}
              >
                <SelectWithWrapper
                  name="beddingType"
                  title="Bedding Type"
                  value={values.beddingType}
                  onChange={({ value }) => setFormValues({ ...values, beddingType: value })}
                  options={beddingTypeOptions}
                />
                <SelectWithWrapper
                  name="defaultRoomType"
                  title="Default Room Type"
                  value={values.roomType}
                  onChange={({ value }) => setFormValues({ ...values, roomType: value })}
                  options={roomTypeOptions}
                />
                <SelectWithWrapper
                  name="walkType"
                  title="Walk Type"
                  value={values.isIndividual ? "Individual" : values.walkType}
                  onChange={({ value }) => setFormValues({ ...values, walkType: value })}
                  options={walkTypeOptions}
                  isDisabled={values.isIndividual}
                  isHidden={isCat}
                />
                <CheckboxField
                  name="harnessOnly"
                  label={harnessLabel}
                  checked={values.harnessOnly}
                  onClick={() => setFormValues({ ...values, harnessOnly: !values.harnessOnly })}
                  isDisabled={disabled}
                  isHidden={isCat}
                />
              </LayoutGrid>
            </LayoutBox>
          </LayoutStack>
        </div>
      </Section>
    </LoadingWrapper>
  );
};

export const DDCPetDetailsContainer = connect(
  state => {
    const petId = getCurrentPet(state);
    const speciesId = petId && getPet(state, { petId })?.speciesId;
    const { roomTypes, beddingTypes, petProfile } = getDDCPetDetailsFormValues(state, {
      petId,
      speciesId,
    });
    const initialFormValues = {
      roomType: getPetRoomName({ roomTypes, petProfile }) ?? null,
      beddingType: getPetBeddingName({ beddingTypes, petProfile }) ?? null,
      walkType: petProfile?.walkType ?? null,
      isIndividual: !!petProfile?.isIndividual,
      harnessOnly: !!petProfile?.harnessOnly,
    };

    return {
      componentId: "DDCPetDetailsContainer",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      roomTypes,
      beddingTypes,
      petProfile,
      initialFormValues,
      individualLabel: "Individual (no group play time)",
      harnessLabel: "Harness Only",
      customerKey: getCurrentCustomerKey(state),
      walkTypeOptions: walkTypes,
      beddingTypeOptions: getBeddingTypeSelectorOptions(state, { speciesId }),
      roomTypeOptions: getRoomTypeSelectorOptions(state, { speciesId }),
      isCat: getPetSpeciesId(state, { petId }) === speciesTypes.CAT,
      petId,
      isLoading: createLoadingSelector([
        boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL,
      ])(state),
    };
  },
  dispatch => {
    return {
      callGetHotelRoomTypes: () => {
        dispatch(getHotelRoomTypes());
      },
      callGetHotelBeddingTypes: () => {
        dispatch(getHotelBeddingTypes());
      },
      putPetProfile: ({ customerKey, petId, formValues }) => {
        dispatch(updateBoardingDetailsHotel({ customerKey, petId, formValues }));
      },
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    updatePetProfile: ({ roomType, beddingType, walkType, isIndividual, harnessOnly }) => {
      const { customerKey, petId, roomTypes, beddingTypes, petProfile } = propsFromState;
      const { putPetProfile } = propsFromDispatch;

      const defaultRoomType = getPetDefaultRoomType({ roomTypes, roomType }) ?? "";
      const newBeddingType = getPetDefaultBeddingType({ beddingTypes, beddingType });
      const formValues = {
        ...petProfile,
        isIndividual,
        defaultRoomType,
        beddingType: newBeddingType,
        walkType,
        harnessOnly,
      };

      putPetProfile({ customerKey, petId, formValues });
    },
  }),
)(DDCPetDetailsComponent);
