import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_HOTEL_ELIGIBILITY,
  getHotelEligibilityFailure,
  getHotelEligibilityRequest,
  getHotelEligibilitySuccess,
  POST_HOTEL_ELIGIBILITY,
  postHotelEligibilityFailure,
  postHotelEligibilityRequest,
  postHotelEligibilitySuccess,
} from "dux/hotelEligibility/hotelEligibilityActions";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import fetchPetEligibilityForHotelForSelectedService, {
  postPetEligibilityForHotelForSelectedService,
} from "web/petEligibility/services/petEligibilityForHotelEndPoints";
import { formatMoment } from "core/utils/dateUtils/formatDateTime";
import moment from "moment";
import {
  patchHotelItineraryCheckInStatus,
  saveReservationCartDetails,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import {
  getFirstHotelEngagement,
  getLastHotelEngagementByPet,
  selectPetIdsAndEngagementTypesForEligibility,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getReservationConfirmationPendingCheckInTime } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSelectors";
import { isEmpty } from "lodash/fp";
import { engagementTypes } from "web/setSystemType/constants/setSystemTypeConstants";
import { selectActivePetsByCustomer } from "core/selectors/entitiesSelector";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getShouldShowHotelCheckInAlertsForMultiPets } from "dux/hotelEligibility/hotelEligibilitySelectors";
import { formatAlertsForState } from "./hotelEligibilityUtils";
import { getChangeStatusPayload } from "../reservationCartDetailsConfirmation/reservationCartDetailsUtils";
import {
  selectHotelBookingEndDate,
  selectPetListForBookingEligibility,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { selectPetServiceTypesFromAppointmentServicesForPets } from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { waitFor } from "@/core/sagas/utilsSaga";

/**
 * Saga that Handles the process of retrieving hotel service eligibility and placing that eligibility in state.
 * @memberOf Sagas.hotel.eligibility
 * @function
 * @param {Object} payload Containing the following properties:
 * - {boolean} isBooking: Indicates whether it's a booking or not.
 * - {string} customerKey: Customer key.
 * - {Array<string>} petIds: Array of pet IDs.
 * - {string} toDate: Check-in date.
 * - {string} petServiceType: Pet service type.
 * @returns {Generator} Generator object representing the saga logic.
 */
export function* onGetHotelServiceEligibility({
  isBooking,
  customerKey,
  petIds,
  toDate,
  petServiceType,
}) {
  try {
    yield put(getHotelEligibilityRequest());

    const response = yield call(fetchPetEligibilityForHotelForSelectedService, {
      isBooking,
      customerKey,
      petIds,
      toDate,
      petServiceType,
    });

    const result = response?.data?.result;

    yield put(getHotelEligibilitySuccess(result));
  } catch (error) {
    yield put(getHotelEligibilityFailure({ error }));
  }
}

/**
 * Saga that checks if any check in alerts exist and updates the pets status if none do
 * @memberOf Sagas.hotel.eligibility
 * @function
 * @param {Object} obj
 * @param {String} obj.eligibilityType
 * @param {Array} obj.petIds
 * @param {String} obj.customerKey
 * @param {Object} obj.payload
 * @returns {Generator}
 */
function* checkInIfNoAlerts({ eligibilityType, petIds, customerKey, payload = {} }) {
  const { hotelItineraryId = "", isChangingStatus = "", status = "" } = payload;

  if (eligibilityType !== eligibilityTypes.CHECK_IN) return;

  // If alert modal is going to open then do nothing
  const hasCheckInAlerts = yield select(
    getShouldShowHotelCheckInAlertsForMultiPets,
    customerKey,
    petIds,
  );
  if (hasCheckInAlerts) return;

  // If on check-in page, not checking in/out, and pet and customer both have no warnings then save reservation cart
  if (!isChangingStatus) {
    yield put(saveReservationCartDetails(payload));
    return;
  }

  // If checking in and pet and customer both have no warnings, continue to check-in
  yield put(
    patchHotelItineraryCheckInStatus({
      itineraryId: hotelItineraryId,
      data: getChangeStatusPayload({ petIds, status }),
    }),
  );
}

function* onGetHotelEligibility({ eligibilityType = eligibilityTypes.CHECK_IN, payload }) {
  try {
    const customerKey = yield select(getCurrentCustomerKey);
    // For future use with multi-pet. Right now keeping it simple until that work is more flushed out since API response payload varies a lot for multi-pet
    const customerPets = yield waitFor(selectActivePetsByCustomer, { customerKey });
    const petIds = customerPets?.pets;
    const petId = yield select(getCurrentPet);
    const checkInDateTime = yield select(getReservationConfirmationPendingCheckInTime);
    const serviceDetails = yield select(getFirstHotelEngagement);
    const petServiceType =
      payload?.petServiceType || serviceDetails?.engagementType || engagementTypes.DAY_CARE;

    /*
     * Call external saga that handles the process of retrieving hotel service eligibility and
     * placing that eligibility in state.
     */
    yield call(onGetHotelServiceEligibility, {
      isBooking: eligibilityType === eligibilityTypes.BOOKING,
      customerKey,
      petIds,
      toDate: formatMoment(moment(checkInDateTime)),
      petServiceType,
    });

    // Call external saga that handles checking if the pet & customer have alerts and updates status if not
    yield call(checkInIfNoAlerts, {
      eligibilityType,
      petIds: [petId],
      customerKey,
      payload,
    });
  } catch (error) {
    yield put(getHotelEligibilityFailure({ error }));
  }
}

function* onPostHotelEligibility({ eligibilityType = eligibilityTypes.CHECK_IN, payload = {} }) {
  try {
    yield put(postHotelEligibilityRequest());

    const { petsAndServiceTypes, checkOutDateTime } = payload;
    const customerKey = yield select(getCurrentCustomerKey);

    if (isEmpty(petsAndServiceTypes)) {
      yield put(postHotelEligibilityFailure({}));
      return;
    }

    const response = yield call(postPetEligibilityForHotelForSelectedService, {
      isBooking: eligibilityType === eligibilityTypes.BOOKING,
      customerKey,
      petsAndServiceTypes,
      toDate: formatMoment(moment(checkOutDateTime)),
    });

    const result = response?.data?.result ?? {};
    const alerts = formatAlertsForState(result);

    yield put(postHotelEligibilitySuccess(alerts));

    // Call external saga that handles checking if the pet & customer have alerts and updates status if not
    const petIds = Object.values(petsAndServiceTypes)?.map(({ petId }) => petId);
    yield call(checkInIfNoAlerts, {
      eligibilityType,
      petIds,
      customerKey,
      payload,
    });
  } catch (error) {
    yield put(postHotelEligibilityFailure({ error }));
  }
}

/**
 * Saga to call hotel eligibility after an update to a pet while on the pet parent profile
 * @memberOf Sagas.hotel.eligibility
 * @generator
 * @name getHotelProfileEligibilityAfterChange
 */
export function* getHotelProfileEligibilityAfterChange({ petId }) {
  yield call(onPostHotelEligibility, {
    eligibilityType: eligibilityTypes.PROFILE,
    payload: {
      petsAndServiceTypes: [{ petId, serviceType: engagementTypes.DAY_CARE }],
    },
  });
}

/**
 * Saga to call hotel eligibility after an update to a pet while in the booking flow
 * @memberOf Sagas.hotel.eligibility
 * @generator
 * @name getHotelBookingEligibilityAfterChange
 */
export function* getHotelBookingEligibilityAfterChange({ customerKey, petId }) {
  const checkOutDateTime = yield select(selectHotelBookingEndDate);
  const petList = yield select(selectPetListForBookingEligibility, { customerKey, petId });
  const petsAndServiceTypes = yield select(state =>
    selectPetServiceTypesFromAppointmentServicesForPets({
      state,
      petList,
      defaultServiceType: engagementTypes.DAY_CARE,
    }),
  );

  yield call(onPostHotelEligibility, {
    eligibilityType: eligibilityTypes.BOOKING,
    payload: { checkOutDateTime, petsAndServiceTypes },
  });
}

/**
 * Saga to call hotel eligibility after an update to a pet while on the check in or check out page
 * @memberOf Sagas.hotel.eligibility
 * @generator
 * @name getHotelCheckInOutEligibilityAfterChange
 */
export function* getHotelCheckInOutEligibilityAfterChange({ petId }) {
  const petsAndServiceTypes = yield select(selectPetIdsAndEngagementTypesForEligibility, {
    petIds: [petId],
  });
  const lastEngagement = yield select(getLastHotelEngagementByPet, { petId });

  yield call(onPostHotelEligibility, {
    eligibilityType: eligibilityTypes.CHECK_IN,
    payload: {
      checkOutDateTime: lastEngagement?.endDatetime,
      petsAndServiceTypes,
    },
  });
}

function* watchGetHotelEligibility() {
  yield takeEvery(GET_HOTEL_ELIGIBILITY, onGetHotelEligibility);
}

function* watchPostHotelEligibility() {
  yield takeEvery(POST_HOTEL_ELIGIBILITY, onPostHotelEligibility);
}

export default function* hotelEligibilitySaga() {
  yield all([watchGetHotelEligibility(), watchPostHotelEligibility()]);
}
