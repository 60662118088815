import React from "react";
import { connect } from "react-redux";
import {
  selectCartProductIsMissingDates,
  selectHotelCartPetProductById,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import {
  selectAddonByPetAndAddonId,
  selectAddonTotalQtyByGroupingId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectIsReservationDisabled } from "dux/hotelItinerary/hotelItinerarySelectors";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { color } from "@/web/common/styles/theme";
import { Button } from "@prism/psm-ui-components";
import {
  selectDateRangeForFrequency,
  selectAddonDefaultFrequency,
} from "../frequency/dateRangeForFrequencySelectors";
import {
  clearPendingFrequency,
  frequencyActionTypes,
  setPendingFrequency,
  setPendingFrequencyAddonDates,
  setPendingFrequencyIsFromCart,
  setPendingId,
} from "../frequency/actions/frequencyActions";
import { frequencyConstants, frequencyTypes } from "../frequency/FrequencyConstants";
import { setFrequencyDates } from "../frequency/modal/FrequencySelection";

export const HotelAddonsCartQtyButton = ({
  componentId,
  isHidden,
  quantity,
  onClick,
  disabled,
  color,
}) => {
  if (isHidden) return null;

  return (
    <Button
      id={componentId}
      size="xl"
      variant="link"
      onClick={onClick}
      disabled={disabled}
      style={{ color }}
    >
      Qty: {quantity}
    </Button>
  );
};

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonFrequencyButton = connect(
  (state, { petId, addonId }) => {
    const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
    const defaultFrequency = selectAddonDefaultFrequency(addonId)(state);
    const isMissingDates = selectCartProductIsMissingDates({ petId, productId: addonId })(state);

    return {
      componentId: `HotelBookingCartAddonFrequencyButton-${addonId}`,
      quantity: addon?.pricing?.quantity,
      frequency: addon?.frequency || defaultFrequency,
      frequencyDates: addon?.customFrequencyDates,
      pendingAppointmentDates: selectDateRangeForFrequency(state),
      disabled: !!addon?.isAutoApplyAddOn,
      color: isMissingDates && color.red700,
    };
  },
  (dispatch, { addonId, petId }) => {
    return {
      clearFrequency: () => dispatch(clearPendingFrequency()),
      setFrequency: ({ frequency, dates }) => {
        dispatch(
          setPendingId({
            pendingId: addonId,
            petId,
            frequencyType: frequencyTypes.ADDON,
          }),
        );
        if (frequency) dispatch(setPendingFrequency({ frequency }));
        if (dates?.length) dispatch(setPendingFrequencyAddonDates({ dates }));
        dispatch(setPendingFrequencyIsFromCart({ isFromCart: true }));
      },
      showFrequencyModal: () =>
        dispatch(showBookingModal(frequencyActionTypes.SHOW_FREQUENCY_MODAL)),
    };
  },
  (stateProps, dispatchProps, { addonId }) => {
    const {
      componentId,
      frequency,
      frequencyDates,
      pendingAppointmentDates,
      disabled,
      quantity,
      color,
    } = stateProps;
    const { clearFrequency, setFrequency, showFrequencyModal } = dispatchProps;

    const dates =
      frequency === frequencyConstants.MANUAL
        ? frequencyDates
        : setFrequencyDates(frequency, pendingAppointmentDates);

    return {
      componentId,
      quantity,
      disabled,
      color,
      onClick: () => {
        if (!addonId) return;

        clearFrequency();
        setFrequency({ frequency, dates });
        showFrequencyModal();
      },
    };
  },
)(HotelAddonsCartQtyButton);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCartAddonFrequencyButton = connect(
  (state, { petId, addonId }) => {
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
    const isReservationDisabled = selectIsReservationDisabled(state);

    const defaultFrequency = selectAddonDefaultFrequency(addonId)(state);

    return {
      componentId: `HotelCartAddonFrequencyButton-${addonId}`,
      frequency: addon?.frequency || defaultFrequency,
      frequencyDates: addon?.customFrequencyDates,
      pendingAppointmentDates: selectDateRangeForFrequency(state),
      disabled: !addon?.groupingId || isReservationDisabled || addon?.isAutoApplyAddOn,
      quantity: selectAddonTotalQtyByGroupingId(addon?.groupingId)(state, { petId }),
    };
  },
  (dispatch, { addonId, petId }) => {
    return {
      clearFrequency: () => dispatch(clearPendingFrequency()),
      setFrequency: ({ frequency, dates }) => {
        dispatch(
          setPendingId({
            pendingId: addonId,
            petId,
            frequencyType: frequencyTypes.ADDON,
          }),
        );
        if (frequency) dispatch(setPendingFrequency({ frequency }));
        if (dates?.length) dispatch(setPendingFrequencyAddonDates({ dates }));
        dispatch(setPendingFrequencyIsFromCart({ isFromCart: true }));
      },
      showFrequencyModal: () =>
        dispatch(showCheckInOutModal({ modalType: frequencyActionTypes.SHOW_FREQUENCY_MODAL })),
    };
  },
  (stateProps, dispatchProps, { addonId }) => {
    const {
      componentId,
      frequency,
      frequencyDates,
      pendingAppointmentDates,
      disabled,
      quantity,
    } = stateProps;
    const { clearFrequency, setFrequency, showFrequencyModal } = dispatchProps;

    const dates =
      frequency === frequencyConstants.MANUAL
        ? frequencyDates
        : setFrequencyDates(frequency, pendingAppointmentDates);

    return {
      componentId,
      quantity,
      disabled,
      onClick: () => {
        if (!addonId) return;

        clearFrequency();
        setFrequency({ frequency, dates });
        showFrequencyModal();
      },
    };
  },
)(HotelAddonsCartQtyButton);
