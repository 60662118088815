import {
  WELCOME_PACKAGE,
  ULTIMATE_SAVINGS_PACKAGE,
  DDC_PACKAGE,
} from "dux/bgm/packageOfferingConstants";

export const ELIGIBLE_PROMOTIONS_BGM_MESSAGE = `is a potential ${ULTIMATE_SAVINGS_PACKAGE} customer.`;
export const ELIGIBLE_PROMOTIONS_FTCO_MESSAGE = `is a potential ${WELCOME_PACKAGE} customer.`;

export const ELIGIBLE_PROMOTIONS_FTCO = "FTCO";
export const ELIGIBLE_PROMOTIONS_BGM = "BGM";
export const ELIGIBLE_PROMOTIONS_DDC = "DDC";

export const ELIGIBLE_PROMOTIONS_BGM_DESCRIPTION_MESSAGE = `Sample packages below are based on customer's most recent grooming purchase.`;

export const ELIGIBLE_PROMOTIONS_FTCO_PURCHASE_MESSAGE = `Would they like to purchase the ${WELCOME_PACKAGE}?`;
export const ELIGIBLE_PROMOTIONS_BGM_PURCHASE_MESSAGE = `Would they like to learn more about our ${ULTIMATE_SAVINGS_PACKAGE}?`;

export const ELIGIBLE_PROMOTIONS_FTCO_DECLINE_BUTTON_LABEL = "No thanks";
export const ELIGIBLE_PROMOTIONS_FTCO_ACCEPT_BUTTON_LABEL = "Yes, purchase package";

export const ELIGIBLE_PROMOTIONS_BGM_DECLINE_BUTTON_LABEL = "No thanks";
export const ELIGIBLE_PROMOTIONS_BGM_ACCEPT_BUTTON_LABEL = "Yes, view packages";

export const ELIGIBLE_PROMOTIONS_BGM_LABEL = `Potential ${ULTIMATE_SAVINGS_PACKAGE} Customer -`;
export const ELIGIBLE_PROMOTIONS_DDC_LABEL = `Potential ${DDC_PACKAGE} Customer -`;
