import { connect } from "react-redux";
import { showBookingModal } from "core/actionCreators/bookingActionCreators";
import CommonButton from "web/common/commonButton";
import { getFoodById } from "web/food/foodsSelector";
import {
  clearPendingFrequency,
  frequencyActionTypes,
  setPendingFrequency,
  setPendingFrequencyFoodDates,
  setPendingId,
} from "dux/frequency/actions/frequencyActions";
import { frequencyConstants, frequencyTypes } from "dux/frequency/FrequencyConstants";
import { selectDateRangeForFrequency } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { getPendingPetId } from "@/dux/frequency/frequencySelectors";
import { setFrequencyDates } from "@/dux/frequency/modal/FrequencySelection";
import { NEW_FOOD_ID } from "@/web/newFood/newFoodConstants";
import { getFoodFrequencyById, getFoodFrequencyDatesById } from "./foodFrequencySelectors";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const food = getFoodById(state, { foodId, petId });
  const defaultFrequency = frequencyConstants.DAILY;
  return {
    foodId,
    petId,
    food,
    componentId: `foodDetails-setFrequencyFoodButton-${foodId}`,
    label: "Set Frequency",
    width: "8.5em",
    foodFrequency: getFoodFrequencyById(state, { foodId, petId }) || defaultFrequency,
    foodFrequencyDates: getFoodFrequencyDatesById(state, { foodId, petId }),
    pendingPet: getPendingPetId(state),
    pendingAppointmentDates: selectDateRangeForFrequency(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setFoodId: (foodId, petId) =>
    dispatch(setPendingId({ pendingId: foodId, petId, frequencyType: frequencyTypes.FOOD })),
  setPendingAppointmentData: ({ foodFrequency, foodFrequencyDates }) => {
    if (foodFrequency) dispatch(setPendingFrequency({ frequency: foodFrequency }));
    if (foodFrequencyDates) dispatch(setPendingFrequencyFoodDates({ dates: foodFrequencyDates }));
  },
  showFrequencyModal: () => dispatch(showBookingModal(frequencyActionTypes.SHOW_FREQUENCY_MODAL)),
  clearFrequency: () => dispatch(clearPendingFrequency()),
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const {
    componentId,
    label,
    width,
    foodId,
    petId,
    foodFrequency,
    foodFrequencyDates,
    pendingPet,
    pendingAppointmentDates,
  } = propsFromState;
  const {
    clearFrequency,
    setFoodId,
    setPendingAppointmentData,
    showFrequencyModal,
  } = propsFromDispatch;
  const dates =
    foodFrequency === frequencyConstants.MANUAL
      ? foodFrequencyDates
      : setFrequencyDates(foodFrequency, pendingAppointmentDates);

  return {
    componentId,
    label,
    width,
    onClick: () => {
      if (pendingPet !== petId || foodId === NEW_FOOD_ID) clearFrequency();
      setFoodId(foodId, petId);
      setPendingAppointmentData({ foodFrequency, foodFrequencyDates: dates });
      showFrequencyModal();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
