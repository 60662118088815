import * as React from 'react';
import { psx } from '../../../../utils';
import FormField, { FormFieldInputProps, DEFAULT_FORM_FIELD_VARIANT } from '..';
import { getErrorMessage } from '../error-messages';

import './styles.css';

export interface FormFieldTextAreaProps extends FormFieldInputProps, React.ComponentPropsWithoutRef<'textarea'> {
  // Width of the text Area in relation to its parent, the form-group
  width:
    | '100%'
    | '95%'
    | '90%'
    | '85%'
    | '80%'
    | '75%'
    | '70%'
    | '65%'
    | '60%'
    | '55%'
    | '50%'
    | '45%'
    | '40%'
    | '35%'
    | '30%'
    | '25%'
    | '20%'
    | '15%'
    | '10%';
}

export interface FormFieldTextState {
  errorMessage: string;
}

export class FormFieldTextArea extends React.Component<FormFieldTextAreaProps, FormFieldTextState> {
  constructor(props: FormFieldTextAreaProps) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (this.props.onInput) {
      // Ignoring for now since type error breaks unit tests
      // @ts-ignore
      this.props.onInput(e.target.value, this.props?.name);
    }
    this.setState({ errorMessage: getErrorMessage(e.target, 'text') });
  };

  handleInvalid = (e: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ errorMessage: getErrorMessage(e.target, 'text') });
  };

  render() {
    const {
      children,
      className,
      label,
      labelClassName,
      onInput,
      defaultValue,
      value,
      autoComplete = 'off',
      placeholder,
      variant = DEFAULT_FORM_FIELD_VARIANT,
      style = {},
      width,
      id,
      ...rest
    } = this.props;

    const classNames = psx('form-text-area', `form-text-area'-${variant}`, className);
    const placeholderElement = placeholder || ' ';

    /*  You need to define width of the div containing the textarea and when you declare textarea,
        you can then set textarea to have width: inherit. so if you want the text area to be 80%
        of the area it is contained in, we must set the form-group containing this textArea to 80%
        then set the text area its self to inherit, this wll will allow you to set the width of the
        text area via css.
     */
    const setWidth = width ? { width } : { width: 'inherit' };

    return (
      <FormField
        style={setWidth}
        className={labelClassName}
        label={label}
        error={this.state.errorMessage}
        variant={variant}
      >
        <textarea
          {...rest}
          style={setWidth ? { ...style, width: '100%' } : style}
          value={value}
          id={id ?? label}
          onInput={this.handleInput}
          className={classNames}
          autoComplete={autoComplete}
          placeholder={placeholderElement}
          onInvalid={this.handleInvalid}
        />
      </FormField>
    );
  }
}
