/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const GET_PURCHASED_DDC_PACKAGES = "GET_PURCHASED_DDC_PACKAGES";
export const GET_PURCHASED_DDC_PACKAGES_REQUEST = "GET_PURCHASED_DDC_PACKAGES_REQUEST";
export const GET_PURCHASED_DDC_PACKAGES_SUCCESS = "GET_PURCHASED_DDC_PACKAGES_SUCCESS";
export const GET_PURCHASED_DDC_PACKAGES_FAILURE = "GET_PURCHASED_DDC_PACKAGES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const getDdcPurchasedPackages = () => ({
  type: GET_PURCHASED_DDC_PACKAGES,
});

export const getDdcPurchasedPackagesRequest = () => ({
  type: GET_PURCHASED_DDC_PACKAGES_REQUEST,
});

export const getDdcPurchasedPackagesSuccess = packages => ({
  type: GET_PURCHASED_DDC_PACKAGES_SUCCESS,
  packages,
});

export const getDdcPurchasedPackagesFailure = error => ({
  type: GET_PURCHASED_DDC_PACKAGES_FAILURE,
  error,
});
