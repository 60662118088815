import { call } from "redux-saga/effects";
import { eligibilitySubjectTypes } from "dux/eligibility/constants/eligibilityConstants";
import {
  getPetsEligibilityByCustomerRequest,
  getPetsEligibilityByCustomerSuccess,
} from "dux/eligibility/actions/eligibilityPetsByCustomerActions";
import {
  getPetsEligibilityByItineraryRequest,
  getPetsEligibilityByItinerarySuccess,
} from "dux/eligibility/actions/eligibilityPetsByItineraryActions";
import { getItinerary, selectActivePetsByCustomer } from "core/selectors/entitiesSelector";
import {
  fetchPetsEligibilityByCustomer,
  fetchPetsEligibilityByItinerary,
} from "core/services/systemServicesBooking/petEligibilityEndPoints";
import { onGetPrimaryEngineResultsForAllPets } from "dux/eligibility/sagas/primaryEngineForAllPetsSaga";
import { onGetSecondaryEngineForAllPets } from "dux/eligibility/sagas/secondaryEngineForAllPetsSaga";
import { onNormalizeCombinedEngines } from "dux/eligibility/sagas/normalizeCombinedEnginesSaga";
import { onGetPetIdsForAllPets } from "dux/eligibility/sagas/petIdsForAllPetsSaga";

/**
 * Responsible for determining if itinerary or customer flow should be used to get Pet Eligibility for multiple pets.
 * @param { sting } customerKey
 * @param { string } itineraryId
 * @param { string }startDate
 * @param eligibilityType
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor>, *, Generator<*, *, ?>>}
 */
export function* onGetEligibiltyMultiplePets({
  customerKey,
  itineraryId,
  startDate,
  eligibilityType = eligibilitySubjectTypes.BOOKING,
}) {
  // eslint-disable-next-line no-useless-catch
  try {
    if (itineraryId) {
      // :::: ITINERARY FLOW ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      const petIds = yield call(onGetPetIdsForAllPets, {
        selectorToWaitFor: getItinerary,
        selectorKey: "itineraryId",
        selectorId: itineraryId,
        requestAction: getPetsEligibilityByItineraryRequest,
      });

      const primaryEngineResult = yield call(onGetPrimaryEngineResultsForAllPets, {
        eligibilityType,
        eligibilityAPICall: fetchPetsEligibilityByItinerary,
        eligibilityAPICallKey: "itineraryId",
        eligibilityAPICallArgument: itineraryId,
        customerKey,
        startDate,
      });
      const secondaryEngineResult = yield call(onGetSecondaryEngineForAllPets, {
        petIds,
        eligibilityType,
      });

      const mergedResults = yield call(onNormalizeCombinedEngines, {
        primaryEngineResult,
        secondaryEngineResult,
        successAction: getPetsEligibilityByItinerarySuccess,
      });

      return mergedResults;
    }

    // :::: CUSTOMER FLOW ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    const petIds = yield call(onGetPetIdsForAllPets, {
      selectorToWaitFor: selectActivePetsByCustomer,
      selectorKey: "customerKey",
      selectorId: customerKey,
      requestAction: getPetsEligibilityByCustomerRequest,
    });

    const primaryEngineResult = yield call(onGetPrimaryEngineResultsForAllPets, {
      eligibilityType,
      eligibilityAPICall: fetchPetsEligibilityByCustomer,
      eligibilityAPICallKey: "petIds",
      eligibilityAPICallArgument: petIds,
      customerKey,
      startDate,
    });

    const secondaryEngineResult = yield call(onGetSecondaryEngineForAllPets, {
      petIds,
      eligibilityType,
    });

    const mergedResults = yield call(onNormalizeCombinedEngines, {
      primaryEngineResult,
      secondaryEngineResult,
      successAction: getPetsEligibilityByCustomerSuccess,
    });

    return mergedResults;
  } catch (error) {
    throw error;
  }
}
