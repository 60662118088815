import React, { useState } from "react";
import CommonPriceList from "web/common/modalInsets/selectablePriceLists/commonPriceList";
import { SearchableList } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";

export const BookingAddonsPriceListComponent = props => {
  const {
    componentId,
    addOns,
    properties,
    onSelectionChange,
    selectedAddOns = [],
    disabledOptions = [],
  } = props;

  const [query, setQuery] = useState("");

  const handleQuery = event => {
    setQuery(event?.target?.value);
  };

  return (
    <SearchableList id={componentId} placeholder="Search" value={query} onChange={handleQuery}>
      <LayoutBox style={{ paddingRight: 0, paddingLeft: 0 }}>
        {addOns?.map(addOn => (
          <CommonPriceList
            key={addOn?.label}
            heading={addOn?.label}
            priceList={addOn?.value}
            idProperty={properties?.id}
            nameProperty={properties?.name}
            searchFilter={query}
            selectedOptions={selectedAddOns}
            disabledOptions={disabledOptions}
            onSelectionChange={onSelectionChange}
          />
        ))}
      </LayoutBox>
    </SearchableList>
  );
};
