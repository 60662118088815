import { createSelector } from "reselect";

/**
 * Selector to get services cart V2 data from state
 * @memberOf Selectors.servicesCart
 * @function
 * @name selectServicesCartV2
 * @param {Object} state
 * @returns {object}
 * @example selectServicesCartV2(state)
 */
export const selectServicesCartV2 = state => state?.servicesCartV2 || {};

/**
 * Selector to get total price from services cart V2 data
 * @memberOf Selectors.servicesCart
 * @function
 * @name selectCartTotalPrice
 * @param {Object} state
 * @returns {number}
 * @example selectCartTotalPrice(state)
 */
export const selectCartTotalPrice = createSelector([selectServicesCartV2], cart => {
  return cart?.totalPriceAfterDiscount ?? 0;
});

/**
 * Selector to get services cart V2 data from state
 * @memberOf Selectors.servicesCart
 * @function
 * @name selectServicesCartIdV2
 * @param {Object} state
 * @returns {string}
 * @example selectServicesCartIdV2(state)
 */
export const selectServicesCartIdV2 = createSelector([selectServicesCartV2], cart => {
  return cart?.servicesCartId;
});
