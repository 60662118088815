import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { isEmpty } from "lodash/fp";
import moment from "moment";
import { getPetParentScreenEligibility } from "dux/eligibility/actions/eligibilityByScreenActions";
import { history } from "@/dux/utils/browser/browserHistory";
import { getAvailableBundlesForAllPetsByCustomer } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { AllServiceTypes } from "core/constants/serviceTypesConstants";
import { toggleCustomerProfileIncompleteModal } from "@/dux/customerProfileIncompleteModal/customerProfileIncompleteModalActions";
import { engagementTypes, systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { onGetHotelServiceEligibility } from "dux/hotelEligibility/hotelEligibilitySaga";
import petParentProfileActionTypes from "../actionTypes/ui/petParentProfileActionTypes";
import {
  historyClickFailure,
  showPetParentProfileModal,
} from "../actionCreators/petParentProfileActionCreator";
import {
  loadStoreServicesForPetFailure,
  loadStoreServicesForPetRequest,
  loadStoreServicesForPetSuccess,
} from "../actionCreators/servicesSelectionActionCreator";
import {
  getAssociates,
  getPetById,
  getPetServiceItem,
  getServicesIdsByPet,
  selectActivePetsByCustomer,
} from "../selectors/entitiesSelector";
import { getStoreNumber } from "../selectors/persistentSelectors";
import { loadPetSuccess } from "../actionCreators/petsActionCreators";
import { fetchPetServicesAtStore } from "../services/systemServicesBooking/petServicesEndPoints";
import buildRebookErrorMessages from "../utils/errors/rebookErrorMessage";
import { formatMoment } from "../utils/dateUtils/formatDateTime";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import {
  onSelectAppointment,
  setRebookErrorMessages,
} from "../actionCreators/ui/web/commonActionCreators";
import { modalTypes } from "../constants/modalConstants";
import { onLoadCustomer } from "./customersSaga";
import { loadItinerariesByCustomer } from "../actionCreators/itinerariesActionCreators";
import { waitFor } from "./utilsSaga";

function* onRebookClick({ appointment }) {
  try {
    const storeNumber = yield select(getStoreNumber);
    const {
      addOns,
      enhancedServices,
      pet,
      petServiceId,
      petServiceItemId,
      associate,
      customer,
      lockToAssociate,
    } = appointment;
    const fromDate = formatMoment(moment().startOf("day"));

    yield put(loadStoreServicesForPetRequest());
    const response = yield call(fetchPetServicesAtStore, {
      customerKey: customer,
      storeNumber,
      petId: pet,
      fromDate,
    });
    const petServiceDatas = response.data;
    const petDetails = yield select(getPetById, pet);
    yield put(
      loadPetSuccess({
        pet: {
          petId: pet,
          ...petDetails,
          petServiceIds: petServiceDatas.map(petService => petService.petServiceId),
        },
      }),
    );
    yield put(
      loadStoreServicesForPetSuccess(normalizeArrayByProperty(petServiceDatas, "petServiceId")),
    );

    const petServiceIds = yield select(getServicesIdsByPet, { petId: pet });
    const associates = yield select(getAssociates);
    const petServiceItem = yield select(getPetServiceItem, { petServiceItemId });
    const { status } = petServiceItem;
    const errorMessages = buildRebookErrorMessages({
      associate,
      associates,
      petServiceId,
      petServiceIds,
      status,
    });

    if (errorMessages.length) {
      yield put(onSelectAppointment({ appointmentId: petServiceItemId }));
      yield put(setRebookErrorMessages({ errorMessages }));
      yield put(showPetParentProfileModal(modalTypes.REBOOK_ERROR));
    } else {
      history.push(`/booking/${customer}/select-service`, {
        cart: { addOns, enhancedServices, pet, associate, petServiceId, lockToAssociate },
      });
    }
  } catch (error) {
    yield put(loadStoreServicesForPetFailure({ error }));
  }
}

function* onHistoryClick({ appointment }) {
  try {
    const { petServiceItemId } = appointment;
    yield put(onSelectAppointment({ appointmentId: petServiceItemId }));
    yield put(showPetParentProfileModal(modalTypes.SALON_APPOINTMENT_HISTORY));
  } catch (error) {
    yield put(historyClickFailure({ error }));
  }
}

function* onLoadPetParentAPIs({ customerKey }) {
  // Partial error returned from GET customer endpoint that determines
  // if a customer is enrolled in PRISM or not.
  const response = yield call(onLoadCustomer, { customerKey });
  const { partialError } = response;
  const bookingFlow = yield select(getSystemBookingFlow);
  const statuses = response?.customer?.statuses;
  const isActive = statuses.find(status => {
    return status?.statusId === 2 && status?.isActive;
  })?.isActive;

  /*
   * Since the function is called when the Pet Parent Profile page loads, we  need to Determine what
   * eligibility needs to be called, Salon or hotel.
   */
  if (bookingFlow === systemName.HOTEL) {
    const customerPets = yield waitFor(selectActivePetsByCustomer, { customerKey });
    const petIds = customerPets?.pets;
    /*
     * If a new customer is created, there will be no petId(s) so we can use the that as an indicator if
     * hotel eligibility should or shouldn't be called on Pet Parent Page load.
     */
    const hasPetIds = petIds?.length !== 0;
    if (hasPetIds) {
      /*
       * Call external saga that handles the process of retrieving hotel service eligibility and
       * placing that eligibility in state.
       */
      yield call(onGetHotelServiceEligibility, {
        isBooking: false,
        customerKey,
        petIds,
        toDate: formatMoment(moment()),
        petServiceType: engagementTypes.DAY_CAMP,
      });
    }
  } else {
    // salon eligibility call
    yield put(getPetParentScreenEligibility({ customerKey }));
  }

  const currentDate = moment();
  const fromDate = moment(currentDate).subtract(1, "years");
  const toDate = moment(currentDate).add(1, "years");

  const isBGMFeatureFlagEnabled = yield select(state => !getIsBGMWorkflowFeatureFlagHidden(state));
  const isFTCOFeatureFlagEnabled = yield select(state => !getFTCOWorkflowFeatureFlagHidden(state));

  if (!isEmpty(partialError) && bookingFlow !== systemName.HOTEL) {
    yield put(toggleCustomerProfileIncompleteModal());
  }

  if (isActive) {
    if (isBGMFeatureFlagEnabled || isFTCOFeatureFlagEnabled) {
      yield put(getAvailableBundlesForAllPetsByCustomer({ customerKey }));
    }

    yield put(
      loadItinerariesByCustomer({
        customerKey,
        fromDate: formatMoment(fromDate),
        toDate: formatMoment(toDate),
        serviceTypes: AllServiceTypes,
      }),
    );
  }
}

function* watchRebookClick() {
  yield takeEvery(petParentProfileActionTypes.REBOOK_CLICK, onRebookClick);
}

function* watchHistoryClick() {
  yield takeEvery(petParentProfileActionTypes.HISTORY_CLICK, onHistoryClick);
}

function* watchLoadAPIs() {
  yield takeEvery(petParentProfileActionTypes.LOAD_PET_PARENT_APIS, onLoadPetParentAPIs);
}

export default function* petParentProfileSaga() {
  yield all([watchRebookClick(), watchHistoryClick(), watchLoadAPIs()]);
}
