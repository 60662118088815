import { getProps, getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";
import BGM_STATUS from "../bgm/availableBundlesByPet/availableBundlesByPetConstants";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { formatMonthDateYear } from "@/core/utils/dateUtils/formatDateTime";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { routePaths } from "@/core/constants/routePaths";

/**
 * Selector to get ddc purchased packages state for current customer
 * @function
 * @name selectDdcPurchasedPackages
 * @param {Object} state
 * @returns {Object}
 * @example selectDdcPurchasedPackages(state)
 */
export const selectDdcPurchasedPackages = createSelector(
  [getState],
  state => state?.ddcPurchasedPackages ?? {},
);

/**
 * TODO: fix this
 * Selector to get id's of ddc purchased packages that have reserved or available units
 * @function
 * @name selectActiveDdcPackageIds
 * @param {Object} state
 * @returns {Array} array of packageId's
 * @example selectActiveDdcPackageIds(state)
 */
export const selectActiveDdcPackageIds = createSelector(
  [selectDdcPurchasedPackages],
  (packages = {}) =>
    Object.entries(packages)
      ?.filter(([packageId, ddcPackage]) => {
        return ddcPackage?.units?.some(
          ({ status }) => status === BGM_STATUS.RESERVED || status === BGM_STATUS.AVAILABLE,
        );
      })
      ?.map(([packageId]) => packageId),
);

/**
 * Selector to get id's of ddc purchased packages that have complete, refunded and expired status
 * @function
 * @name selectInActiveDdcPackageIds
 * @param {Object} state
 * @returns {Array} array of packageId's
 * @example selectInActiveDdcPackageIds(state)
 */
export const selectInActiveDdcPackageIds = createSelector(
  [selectDdcPurchasedPackages],
  (packages = {}) =>
    Object.entries(packages)
      ?.filter(([packageId, ddcPackage]) => {
        return (
          ddcPackage.status === BGM_STATUS.COMPLETE ||
          ddcPackage.status === BGM_STATUS.EXPIRED ||
          ddcPackage.status === BGM_STATUS.REFUNDED
        );
      })
      ?.map(([packageId]) => packageId),
);

/**
 * Selector to get a specific purchased package by id
 * @function
 * @name selectDdcPackageById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Object}
 * @example selectDdcPackageById(state, { packageId })
 */
export const selectDdcPackageById = createSelector(
  [selectDdcPurchasedPackages, getProps],
  (packages, { packageId }) => packages?.[packageId] ?? {},
);

/**
 * Selector to get the name of a specific purchased package by id
 * @function
 * @name selectDdcPackageNameById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackageNameById(state, { packageId })
 */
export const selectDdcPackageNameById = createSelector(
  [selectDdcPackageById],
  ddcPackage => ddcPackage?.packageName ?? "",
);

/**
 * Selector to get the status of a specific purchased package by id
 * @function
 * @name selectDdcPackageStatusById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackageStatusById(state, { packageId })
 */
export const selectDdcPackageStatusById = createSelector(
  [selectDdcPackageById],
  ddcPackage => ddcPackage?.status ?? "",
);

/**
 * Selector to get the service type of a specific purchased package by id
 * @function
 * @name selectDdcPackageServiceNameById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackageServiceNameById(state, { packageId })
 */
export const selectDdcPackageServiceNameById = createSelector(
  [selectDdcPackageById],
  ddcPackage => ddcPackage?.serviceName ?? "",
);

/**
 * Selector to get the expiration date of a specific purchased package by id
 * @function
 * @name selectDdcPackageExpirationDateById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackageExpirationDateById(state, { packageId })
 */
export const selectDdcPackageExpirationDateById = createSelector(
  [selectDdcPackageById, selectCurrentStoreTimeZone],
  ({ expirationDate }, timeZone) =>
    expirationDate ? formatMonthDateYear(expirationDate, timeZone) : "",
);

/**
 * Selector to get the purchase date of a specific purchased package by id
 * @function
 * @name selectDdcPackagePurchaseDateById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackagePurchaseDateById(state, { packageId })
 */
export const selectDdcPackagePurchaseDateById = createSelector(
  [selectDdcPackageById, selectCurrentStoreTimeZone],
  ({ purchaseDatetime }, timeZone) =>
    purchaseDatetime ? formatMonthDateYear(purchaseDatetime, timeZone) : "",
);

/**
 * Selector to get the payment origin of a specific purchased package by id
 * @function
 * @name selectDdcPackagePaymentOriginById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackagePaymentOriginById(state, { packageId })
 */
export const selectDdcPackagePaymentOriginById = createSelector(
  [selectDdcPackageById],
  ddcPackage => ddcPackage?.paymentOrigin ?? "",
);

/**
 * Selector to get the associate name of a specific purchased package by id
 * @function
 * @name selectDdcPackageAssociateById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {String}
 * @example selectDdcPackageAssociateById(state, { packageId })
 */
export const selectDdcPackageAssociateById = createSelector(
  [selectDdcPackageById],
  ddcPackage => ddcPackage?.soldBy?.associateName ?? "",
);

/**
 * Selector to get the units of a specific active purchased package by id
 * adds properties for a link to the scheduled appointment page if applicable
 * @function
 * @name selectDdcActivePackageUnitsById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Array}
 * @example selectDdcActivePackageUnitsById(state, { packageId })
 */
export const selectDdcActivePackageUnitsById = createSelector(
  [selectDdcPackageById, getCurrentCustomerKey, selectCurrentStoreTimeZone],
  (ddcPackage, customerKey, timeZone) =>
    ddcPackage?.units?.map(unit => {
      const { itineraryId, scheduledDate } = unit;
      const formattedUnit = { ...unit };

      if (itineraryId) formattedUnit.link = `${routePaths.CHECK_IN}/${customerKey}/${itineraryId}`;
      if (scheduledDate) formattedUnit.formattedDate = formatMonthDateYear(scheduledDate, timeZone);

      return formattedUnit;
    }) ?? [],
);

/**
 * Selector to get the units of a specific in active purchased package by id
 * adds properties for a link to the appointment page if applicable
 * @function
 * @name selectDdcInActivePackageUnitsById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Array}
 * @example selectDdcInActivePackageUnitsById(state, { packageId })
 */
export const selectDdcInActivePackageUnitsById = createSelector(
  [selectDdcPackageById, getCurrentCustomerKey, selectCurrentStoreTimeZone],
  (ddcPackage, customerKey, timeZone) =>
    ddcPackage?.units?.map(unit => {
      const { itineraryId, redemptionDate } = unit;
      const formattedUnit = { ...unit };

      if (itineraryId) formattedUnit.link = `${routePaths.CHECK_OUT}/${customerKey}/${itineraryId}`;
      if (redemptionDate)
        formattedUnit.formattedDate = formatMonthDateYear(redemptionDate, timeZone);

      return formattedUnit;
    }) ?? [],
);

/**
 * Selector to get the remaining units of a specific purchased package by id
 * @function
 * @name selectDdcPackageRemainingUnitsById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Array}
 * @example selectDdcPackageRemainingUnitsById(state, { packageId })
 */
export const selectDdcPackageRemainingUnitsById = createSelector(
  [selectDdcActivePackageUnitsById],
  units => units?.filter(unit => unit?.status === BGM_STATUS.AVAILABLE) ?? [],
);

/**
 * Selector to get the redeemed units of a specific purchased package by id
 * @function
 * @name selectInActiveDdcPackageRedeemedUnitsById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Array}
 * @example selectInActiveDdcPackageRedeemedUnitsById(state, { packageId })
 */
export const selectInActiveDdcPackageRedeemedUnitsById = createSelector(
  [selectDdcInActivePackageUnitsById],
  units => units?.filter(unit => unit?.status === BGM_STATUS.COMPLETE) ?? [],
);

/**
 * Selector to get the reserved units of a specific purchased package by id
 * @function
 * @name selectDdcPackageReservedUnitsById
 * @param {Object} state
 * @param {{ packageId: string|number }} props
 * @returns {Array}
 * @example selectDdcPackageReservedUnitsById(state, { packageId })
 */
export const selectDdcPackageReservedUnitsById = createSelector(
  [selectDdcActivePackageUnitsById],
  units => units?.filter(unit => unit?.status === BGM_STATUS.RESERVED) ?? [],
);
