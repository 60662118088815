import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import StyledPopover from "web/common/StyledPopover";
import {
  HotelAddonsEditQuantityCartFlyout,
  HotelBookingAddonsEditQuantityCartFlyout,
} from "dux/hotelAddonsEditCartQuantityFlyout/HotelAddonsEditCartQuantityFlyout";
import {
  selectAddonByPetAndAddonId,
  selectAddonTotalQtyByGroupingId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import {
  selectCartProductIsMissingDates,
  selectHotelCartPetProductById,
} from "../servicesCartHotel/servicesCartHotelSelectors";
import { HotelAddonsCartQtyButton } from "../HotelAddonsCartQtyButton/HotelAddonsCartQtyButton";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { color } from "@/web/common/styles/theme";

const HotelCartAddonQtyCartButtonComponent = ({
  componentId,
  flyoutContent,
  quantity,
  disabled,
  color,
}) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  return (
    <StyledPopover
      id={componentId}
      body={<Layout.Box padding="box-padding-5">{flyoutContent}</Layout.Box>}
      isOpen={isFlyoutOpen}
      preferPlace="right"
      onOuterAction={() => setIsFlyoutOpen(false)}
      tipSize={7}
    >
      <HotelAddonsCartQtyButton
        componentId={`${componentId}__qtyButton`}
        quantity={quantity}
        disabled={disabled}
        onClick={() => setIsFlyoutOpen(!isFlyoutOpen)}
        color={color}
      />
    </StyledPopover>
  );
};

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCartAddonQtyCartButton = connect((state, { addonId, petId }) => {
  const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
  const isReservationDisabled = selectIsReservationDisabled(state);

  return {
    componentId: "AddAddOn-HotelCart_Button",
    flyoutContent: <HotelAddonsEditQuantityCartFlyout addonId={addonId} />,
    quantity: selectAddonTotalQtyByGroupingId(addon?.groupingId)(state, { petId }),
    disabled: isReservationDisabled || !!addon?.isAutoApplyAddOn,
  };
})(HotelCartAddonQtyCartButtonComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonQtyCartButton = connect((state, { petId, addonId }) => {
  const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
  const isMissingDates = selectCartProductIsMissingDates({ petId, productId: addonId })(state);

  return {
    componentId: "HotelBookingCart_qtyButton",
    flyoutContent: <HotelBookingAddonsEditQuantityCartFlyout petId={petId} addonId={addonId} />,
    quantity: addon?.pricing?.quantity,
    disabled: !!addon?.isAutoApplyAddOn,
    color: isMissingDates && color.red700,
  };
})(HotelCartAddonQtyCartButtonComponent);
