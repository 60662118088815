import React from "react";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutStack } from "@/layout/stack/Stack";

export const MedFormLayout = ({ componentId, children: [name, otherName, amount, ...rest] }) => {
  return (
    <LayoutStack id={componentId} space="scale-G1">
      <LayoutGrid style={{ gridTemplateColumns: "repeat(2, 1fr)", columnGap: "1em" }}>
        {name}
        {otherName}
        {amount}
      </LayoutGrid>
      {rest}
    </LayoutStack>
  );
};
