import { createSelector } from "reselect";
import { selectItineraryPetMedsByPetId } from "dux/hotelEngagements/hotelEngagementSelectors";
import normalizeFormInput from "core/utils/normalizeUtils/normalizeFormInput";
import {
  selectMedicationOptions,
  selectMedicationsList,
} from "@/dux/medications/medicationsSelectors";
import { convertTimeOfDayObjToArr, convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";
import { getReservationConfirmationPendingMeds } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSelectors";
import { isRestrictedOrFollowUpMed } from "@/web/medicationName/medicationNameHelpers";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { selectDefaultFrequency } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { isValidFrequency } from "@/core/utils/validationUtils/validateFrequency";

export const getCheckInOutPetMeds = createSelector([selectItineraryPetMedsByPetId], meds =>
  meds?.filter(med => !med.isDeleted),
);

export const selectCheckInOutMedIds = createSelector([getCheckInOutPetMeds], meds => {
  return meds?.map(med => med?.recordId) ?? [];
});

export const getCheckInOutNewMedNumber = createSelector([getCheckInOutPetMeds], meds => {
  return meds?.length + 1;
});

export const getCheckInMedById = createSelector(
  [getCheckInOutPetMeds, (state, props) => props],
  (meds, { medId }) => {
    return meds?.find(med => med?.recordId === medId);
  },
);

export const getCheckInMedFrequencyById = createSelector([getCheckInMedById], med => {
  return med?.frequency || "";
});

export const getMedFormData = createSelector(
  [selectMedicationsList, getCheckInOutPetMeds, selectDefaultFrequency, (state, props) => props],
  (medList, meds, defaultFrequency, { medId }) => {
    if (!meds.length) {
      return { frequency: defaultFrequency };
    }
    // Get specific med from engagement's meds
    const med = meds?.find(listMed => listMed.recordId === medId);

    // Get needed med details from the general med list
    const medDetails = medList?.find(listMed => listMed.MedicationId === med?.medicationId);
    // If med name doesn't match the med list's name, it's an other name.
    const otherMedName = med?.medicationName === medDetails?.Description ? "" : med?.medicationName;

    // Set the different times of day to a boolean
    const { morning, afternoon, evening } = convertTimeOfDayToObj(med?.timeOfDay);

    // We are using displayLabel instead of medName for med options
    const { label } = isRestrictedOrFollowUpMed(medDetails);
    return {
      medDisplayName: label,
      medName: medDetails?.Description || "",
      otherMedName: otherMedName || "",
      medAmount: normalizeFormInput(med, "amount"),
      morning,
      afternoon,
      evening,
      medInstructions: normalizeFormInput(med, "splInstruction"),
      medId: normalizeFormInput(med, "recordId"),
      frequency: med?.frequency ?? defaultFrequency,
      customFrequencyDates: med?.customFrequencyDates ?? [],
      externalId: med?.medicationId,
    };
  },
);

export const getIsCheckInOutPetMedFormValid = ({ values, dates }) => {
  const { medName, otherMedName, medAmount, morning, afternoon, evening, frequency } = values;
  if (
    !isValidString(medName) ||
    (!isValidString(otherMedName) && medName === "Other") ||
    !isValidString(medAmount) ||
    (!morning && !afternoon && !evening) ||
    !isValidFrequency(frequency, dates)
  ) {
    return false;
  }

  return true;
};

/**
 * Helper function to update a med object with pending changes
 * @param {Object} obj
 * @param {Object} obj.medToUpdate - the pending changes
 * @param {Object?} obj.med - the existing med or undefined if adding new med
 * @param {Object} obj.medOptions - the med list options obj used to get med name by externalId
 * @param {Object} obj.defaultFrequency
 * @returns {Object} updated med with properties needed for itinerary PUT call
 */
export const formatUpdatedMedForItinerary = ({
  medToUpdate,
  med = {},
  medOptions = {},
  defaultFrequency,
}) => {
  const timeOfDay = convertTimeOfDayObjToArr(medToUpdate);
  const medicationId = medToUpdate?.externalId ?? med?.medicationId;
  const medicationName = medOptions?.[medicationId]?.Description;

  return {
    ...med,
    medicationName: medToUpdate?.otherMedName || medicationName,
    amount: medToUpdate?.medAmount,
    timeOfDay,
    splInstruction: medToUpdate?.medInstructions,
    medicationId,
    engagementId: medToUpdate?.engagementId,
    frequency: medToUpdate?.frequency ?? med?.frequency ?? defaultFrequency,
    customFrequencyDates: medToUpdate?.customFrequencyDates ?? med?.customFrequencyDates ?? [],
    isDeleted: medToUpdate?.isDeleted ?? false,
    groupingId: medToUpdate?.groupingId ?? med?.groupingId,
  };
};

export const getMedUpdatesForItinerary = createSelector(
  [
    getReservationConfirmationPendingMeds,
    getCheckInOutPetMeds,
    selectMedicationOptions,
    selectDefaultFrequency,
  ],
  (pendingMeds, petMeds, medOptions, defaultFrequency) => {
    if (!pendingMeds) {
      return petMeds;
    }

    const meds = petMeds?.map(med => {
      const medToUpdate = pendingMeds[med?.recordId];
      if (!medToUpdate) return med;

      return formatUpdatedMedForItinerary({ medToUpdate, med, medOptions, defaultFrequency });
    });

    if (pendingMeds?.NEW_MEDICATION) {
      const medToUpdate = pendingMeds?.NEW_MEDICATION;

      const newMed = formatUpdatedMedForItinerary({ medToUpdate, medOptions, defaultFrequency });
      meds.push(newMed);
    }

    return meds;
  },
);

/**
 * Selector to get all meds for a pet and then filter out med to be removed
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetMedsWithoutMedToBeRemoved
 * @param {string} medToBeRemoved - recordId of med to be removed
 * @returns {Function} Selector
 * @example selectPetMedsWithoutMedToBeRemoved(medToBeRemoved)(state, { petId })
 */
export const selectPetMedsWithoutMedToBeRemoved = medToBeRemoved =>
  createSelector([getMedUpdatesForItinerary], meds =>
    meds?.filter(({ recordId }) => recordId?.toString() !== medToBeRemoved?.toString()),
  );
